import { MathJax, MathJaxContext } from "better-react-mathjax";
import React, { useEffect, useState } from "react";
import CustomVideoPlayer from "../../../../components/video-selectors/video-plays";

interface Props {
    questionsList: any;
    titleName: any;
    questionLoading?: boolean
}

export const ViewTestData: React.FC<Props> = (props: Props) => {
    const [questionsList, setQuestionsList] = useState<any[]>([]);
    const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});


    useEffect(() => {
        if (Array.isArray(props?.questionsList)) {
            setQuestionsList(props.questionsList);
        } else {
            setQuestionsList([]);
        }
    }, [props?.questionsList])

    function onViewQuestion(name: any) {
        return { __html: name };
    }
    const toggleCollapse = (index: number) => {
        setExpanded((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };
    const getAnswerValue = (anwerValue: string, optionList: any) => {
        const preData = optionList?.filter((item: any) => item?.option === anwerValue);
        if (preData) {
            return preData[0]?.value
        } else {
            return " ";
        }
    }
    const getBgcolor = (level: any) => {
        switch (level) {
            case "EASY":
                return "easy";
            case "MEDIUM":
                return "md";
            case "HARD":
                return "hd";
            default:
                return "easy";
        }
    }
    return (
        <div>
            <h5 className="text-center f18">{props?.titleName}</h5>
            <MathJaxContext>
                <MathJax>
                    <div className="border ps-4 pe-3 pt-2 question_view_height">
                        {props?.questionLoading &&
                            <div className="text-center p-5">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!props?.questionLoading && <div>
                            {questionsList?.length > 0 && questionsList?.map((questionItem: any, i: number) => (
                                <div className="row col-md-12 mt-3 " key={i}>
                                    <div className='row'>
                                        <div className='col-1'>
                                            <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                Q{i + 1}:
                                            </p>
                                        </div>
                                        <div className='col-11  img_ck'>
                                            <div className='d-flex'>
                                                {questionItem?.video_url && <CustomVideoPlayer videoSrc={questionItem?.video_url} customClass="w-50" />}
                                                {questionItem?.audio_url && <audio src={questionItem?.audio_url} controls></audio>}
                                            </div>
                                            <span className='fw-bold img_ck video_ck' style={{ fontSize: '22px' }}>
                                                <div dangerouslySetInnerHTML={onViewQuestion(questionItem.question)}></div>
                                            </span>
                                        </div>
                                    </div>

                                    {expanded[i] && (<div id={`collapse${i}  img_ck`}>
                                        {Array.isArray(questionItem?.options) && questionItem?.options?.map((options: any, j: number) => (
                                            <div className='row' key={j}>
                                                <div className='col-md-1 px-0 text-center'>
                                                    <span>{options.option}.</span>
                                                </div>
                                                <div className='col-md-6 text-start '>
                                                    <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                </div>
                                            </div>
                                        ))}
                                        <p className="d-flex mb-0">
                                            <span className="text-primary  f16 me-2">Ans:</span>
                                            {questionItem?.correct_answer}.{" "}
                                            {questionItem?.correct_answer && (
                                                <span
                                                    className="ms-2"
                                                    dangerouslySetInnerHTML={onViewQuestion(
                                                        getAnswerValue(questionItem?.correct_answer, questionItem.options)
                                                    )}
                                                ></span>
                                            )}
                                        </p>
                                        <p className="d-flex mb-1">
                                            <span className="text-primary mb-1 f16 me-2">Explanation:</span>
                                            {questionItem?.explanation && (
                                                <span
                                                    className="ms-2"
                                                    dangerouslySetInnerHTML={onViewQuestion(
                                                        questionItem?.explanation
                                                    )}
                                                ></span>
                                            )}
                                        </p>
                                    </div>
                                    )}
                                    <p
                                        className="mb-1 f14  text-primary cursor-pointer"
                                        role="button"
                                        aria-expanded={expanded[i] || false}
                                        aria-controls={`collapse${i}`}
                                        onClick={() => toggleCollapse(i)}
                                    >
                                        <u><i>{expanded[i] ? "Show less" : "Show more"}</i></u>
                                    </p>
                                    <div className="d-flex ">
                                        <h6 className="text-danger mb-0 f14 fw-bold pt-1">Question Used Count: {questionItem?.question_used_count}</h6>
                                        {questionItem?.difficulty_level && <p className={`mb-0 ms-4 f12 level_custom-btn ${getBgcolor(questionItem?.difficulty_level)}`}>{questionItem?.difficulty_level}</p>}
                                    </div>
                                </div>
                            ))}
                            {questionsList?.length == 0 && <h6 className="text-center mt-3">No Data Found</h6>}
                        </div>
                        }
                    </div>
                </MathJax>
            </MathJaxContext>
        </div>
    )
}