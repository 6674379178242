import React, { useEffect, useState } from "react";
import { GET_KARYAKARTA_QUESTIONS_LIST } from "../../../../app/service/content-module/karyakarta-service";
import { toast } from "react-toastify";

interface Props {
    filterData: any,
    userCode: any
}

export const KaryakartaQuestionsList = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [questionList, setQuestionList] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>(props?.filterData);

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        setLoading(true);
        try {
            const payLoad = {
                created_by: props?.userCode,
                expand: "topic,chapter",
                limit: 100000000,
                ...props?.filterData
            }
            const res: any = await GET_KARYAKARTA_QUESTIONS_LIST(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (res?.data?.length > 0) {
                        setQuestionList(res?.data);
                    }
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);

        }
    }
    const formatDateToDDMMYYYY = (dateInput: string | Date): string => {
        const date = new Date(dateInput);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <div className="row justify-content-center mx-1">
                        <div className="col-12 col-md-11 border-bottom border-2 pb-2 ">
                            <div className="d-flex justify-content-between ">
                                <p className="f14 mb-0 text-start"><span className="fw-bold">Duration:</span>{filterData?.from_date && formatDateToDDMMYYYY(filterData?.from_date)}{filterData?.to_date && <span> to {formatDateToDDMMYYYY(filterData?.to_date)}</span>}</p>
                                <p className="f14 mb-0 text-end"><span className="fw-bold">Total Questions Created:</span>{questionList?.length}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-11  mt-2">
                            <div className="custom_table_scroll">
                                <table className="karyakarta_question_table">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No</th>
                                            <th className="text-center">Question</th>
                                            <th className="text-center"> Topic</th>
                                            <th className="text-center"> Chapter</th>
                                            <th className="text-center"> Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {questionList?.map((data: any, i: number) => (
                                            <tr key={i} className={`${i % 2 != 0 && "odd"}  `}>
                                                <td className="text-center">{i + 1}.</td>
                                                <td className="text-start">{data?.question}</td>
                                                <td className="text-start">{data?._topic?.name}</td>
                                                <td className="text-start">{data?._chapter?.name}</td>
                                                <td className="text-center">{data?.created_dt && formatDateToDDMMYYYY(data?.created_dt)}</td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {questionList?.length == 0 && <p className="f16 text-center mt-2">No data found</p>}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}