import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GET_MLA_QUESTIONS, MLA_ANSWERS_UPDATE, MLA_TIME_UPDATE } from '../../../../app/service/mla.service';
import FormBuilder from '../../../../components/form-builder';
import useUnsavedChangesWarnMessage from '../../../../app/utility/unSavedChangesWarnMsg';


export const MLAQuestionsList = () => {
    const [questionsData, setQuestionsData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [questionsFormData, setQuestionsFormData] = useState<any>({});
    const [seconds, setSeconds] = useState(0);
    const [startTimer, setStartTimer] = useState(false);
    const [canShowHeader, setShowHeader] = useState(false);
    const [testInfo, setTestInfo] = useState<any>({});
    const type = localStorage.getItem("type");
    const [cycleNumber, setCycleNumber] = useState<any>('');
    const [worksheets, setWorksheets] = useState<any[]>([]);
    const [timeRemain, setTimeRemain] = useState('');
    const [startTime, setStartTime] = useState(new Date());
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [testStartDate, setTestStartDate] = useState<any>()
    let timeUp = false;
    let time_remain_storage = '';
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const [hoursValue, setHoursValue] = useState<number>(48);
    const surveyForm = useRef<any>({});
    const history = useHistory();
    let timeRemaining = 0;
    const [isActive, setIsActive] = useState<boolean>(false);
    const backworKey = localStorage.getItem("backword_key");
    const questionRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [canShowAlertPopup, setCanShowAlertPopup] = useState<boolean>(false);
    const testBeginDate: any = localStorage.getItem("test_start_date");
    const warnMassage: string = 'Are you sure you want to leave ?';
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarnMessage(warnMassage);

    useEffect(() => {

        // if (backworKey === "true") {
        //     history.push('/student-dashboard/list')
        // }

        getMLAInfo();
        if (type === 'TESTS') {
            checkTestStartDate();
            setDirty();
        }
    }, []);

    useEffect(() => {
        if (startTimer) {
            const timer = setInterval(() => {
                startTimerTemp();
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [startTimer]);
    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isActive) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isActive]);


    const startTimerTemp = () => {
        const testStartDate: any = localStorage.getItem("test_start_date");
        let timeMillis = moment(testStartDate, 'YYYY-MM-DD hh:mm:ss').toDate().getTime();
        let reqTimeMillis = 2 * 24 * 60 * 60 * 1000;
        let actualTimeToComplete = timeMillis + reqTimeMillis;
        let x = new Date(actualTimeToComplete)
        let currentTime = new Date().getTime();

        let timerResult = x.getTime() - currentTime;
        let tSeconds = Math.floor(timerResult / 1000);
        let hours = Math.floor(tSeconds / 3600); // get hours
        let minutes = Math.floor((tSeconds - (hours * 3600)) / 60); // get minutes
        let seconds = tSeconds - (hours * 3600) - (minutes * 60); //  get seconds
        // setHoursValue(hours);
        setTimeRemain((hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds));
        if (canShowAlertPopup && type === 'TESTS') {
            setTimeRemain("00:00:00");
            setHoursValue(48);
        }
        if (hours === 0 && minutes === 0 && seconds === 0 && type === 'TESTS') {
            setCanShowAlertPopup(true);
        }

    }
    const checkTestStartDate = () => {
        const twoDaysAfterTestStartDate = new Date(testBeginDate);
        twoDaysAfterTestStartDate.setDate(twoDaysAfterTestStartDate.getDate() + 2);
        // Get the current date and time
        const currentDate = new Date();
        // Compare current date with two days after test start date
        if (currentDate.getTime() >= twoDaysAfterTestStartDate.getTime()) {
            setCanShowAlertPopup(true);
            setStartTimer(false);
            setTimeRemain("00:00:00");
        }
    }

    function getMLAInfo() {
        const testInfo: any = localStorage.getItem("test_info");
        const type = localStorage.getItem("type");
        const cycleNumber = localStorage.getItem("cycleNumber");
        const worksheets: any = localStorage.getItem("WORKSHEETS");
        const testStartDate: any = localStorage.getItem("test_start_date");
        setTestInfo(JSON.parse(testInfo));
        setCycleNumber(cycleNumber);

        getMLAQuestionsList(JSON.parse(testInfo), type, testStartDate);
        setWorksheets(JSON.parse(worksheets));
    }


    function getMLAQuestionsList(testInfo: any, type: any, testStartDate: any) {
        let payload = {
            "mode": type,
            "test_no": testInfo?.question_paper_id ? testInfo?.question_paper_id : '',
            "ability": testInfo?.ability ? testInfo?.ability : '',
            "worksheet_no": testInfo?.id ? testInfo?.id : ''
        }

        setShowHeader(false);
        setLoading(true);
        GET_MLA_QUESTIONS(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        setIsActive(true);
                        if (res.questions && res.questions.length > 0) {
                            setQuestionsData(res.questions);
                            setShowHeader(true);
                            setStartTimer(true);
                            setLoading(false);
                            if (type === 'TESTS' && (testStartDate === '' || testStartDate === 'null' || testStartDate === 'undefined' || !testStartDate || testStartDate === null)) {
                                setTimeRemain("48:00:00");
                                setTestStartDate(new Date());
                                storeInitialTimeForTest();
                            }
                        } else {
                            setQuestionsData([]);
                            setShowHeader(true);
                            setStartTimer(true);
                            setLoading(false);
                        }

                    }

                }
            }
        });
    }

    function storeInitialTimeForTest() {
        let payload = {
            "user_code": userCode,
            "school_code": schoolCode,
            "cycle_no": cycleNumber ? Number(cycleNumber) : 1,
            "test_start_date": moment(startTime).format('YYYY-MM-DD HH:mm:ss')
        }

        MLA_TIME_UPDATE(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (res) {
                        localStorage.setItem('test_start_date', payload.test_start_date);
                    }
                }
            }
        });
    }


    const handleOptions = (data: any) => {
        setQuestionsFormData(data);
    }

    function handleQuestionAnswers(e: any, item: any, i: number) {
        let mnsQuestions = questionsData;
        mnsQuestions[i].answer = e.target.value;
        setQuestionsData(mnsQuestions);
    }


    const handleCompleteSubmit = () => {
        let payload = {
            "user_code": userCode,
            "school_code": schoolCode,
            "cycle_no": cycleNumber ? Number(cycleNumber) : 1,
            "mode": type,
            "test_no": testInfo.test_no ? testInfo.test_no : '',
            "question_paper_id": testInfo.question_paper_id,
            "ability": testInfo.ability ? testInfo.ability : '',
            "priority_no": testInfo.priority ? testInfo.priority : '',
            "time_taken": getTimeTaken(seconds) ? getTimeTaken(seconds) : 10,
            "test_start_date": moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            "questions": questionsData
        }
        MLA_ANSWERS_UPDATE(payload).then((res: any) => {
            localStorage.setItem("backword_key", "true");
            if (res) {
                setPristine();
                const data = res;
                if (type === 'WORKSHEETS') {
                    setCanShowPopup(true);
                    toast.success('You have successfully completed worksheet');
                    return;
                }
                localStorage.setItem('time_remain', timeRemain);
                history.push(`/student-dashboard/list/mla/success-page/${moduleUUID}/${userCode}/${schoolCode}/${testInfo.test_no}`);

            }
        });
    }

    const getCircleColor = (item: any) => {
        if (item.question_paper_id === testInfo.question_paper_id) {
            return '#FCEE21';
        } else if (item.status === 'completed') {
            return '#8CC63F';
        } else {
            return '#FFFFFF';
        }
    }

    const handleBack = () => {
        history.push(`/student-dashboard/module/mns/view/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    const handleclose = () => {
        setCanShowPopup(false);
        history.push(`/student-dashboard/list/mns/view/${moduleUUID}/${userCode}/${schoolCode}`);
    }

    const handleSubmit = () => {
        setCanShowPopup(false);
        history.push(`/student-dashboard/list/mns/view/${moduleUUID}/${userCode}/${schoolCode}`);
    }
    const getTimeTaken = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        let totalTime = `${minutes.toString().padStart(2, '0')}.${seconds.toString().padStart(2, '0')}`
        let finalTime = totalTime?.replace('00', '0')
        return Number(finalTime);
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, currentIndex: number) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent page reload
            const nextIndex = currentIndex + 1;
            if (nextIndex < questionRefs.current.length) {
                questionRefs.current[nextIndex]?.focus();
            }
        }
    };
    return (
        <div className="row">
            {Prompt}
            <div
                className={`modal fade ${canShowAlertPopup ? "show" : "false"}`}
                style={{ display: canShowAlertPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Times up!!!
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleCompleteSubmit}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <p>Attention!</p>
                            <p className='mb-0'> You have exceeded the allotted time to take the MLA test.</p>
                            <p >Please note that you can only access the test within the designated <span className='fw-bold'>48-hours</span> window from the time of initiation  <br /> <span className='fw-bold'>  {testBeginDate}</span>.
                                If you believe this is an error or if you have any questions, please contact support for assistance.</p>
                            <p>Thank you for your understanding.</p>
                            {/* Your inputs submitted successfully */}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary cursor-pointer"
                                onClick={handleCompleteSubmit}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">You have successfully completed worksheet</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary cursor-pointer" onClick={handleSubmit}>OK</button>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><span>MLA-1</span></li>
                    </ol>
                </nav>
                <div
                    className={`p-3 time-block sticky-block mt-2 d-md-flex justify-content-between align-items-end ${!canShowPopup && "z_index_ni"}`}
                >
                    {type === 'TESTS' && <div>
                        <h2 className="m-0"> Test - 0{testInfo.test_no ? testInfo.test_no : ''}</h2>
                        <h3 className="m-0"> Time Limit - {hoursValue}  hours for all 3 tests</h3>
                    </div>}
                    {type === 'WORKSHEETS' && <div>
                        <h2 className="m-0">Priority - {testInfo.priority}</h2>
                        <h3 className="m-0"> {testInfo.name}</h3>
                        <h3 className="m-0"> Worksheets </h3>
                        {worksheets.map((item: any, i: number) => (
                            <div key={i} className="circle cursor-pointer" style={{ backgroundColor: getCircleColor(item) }}>
                                <p>{i + 1}</p>
                            </div>
                        ))}
                    </div>}
                    {type !== 'WORKSHEETS' && <div>
                        <p className='mt-3 mb-0 m-md-0 f12'>Remaining Time</p>
                        <h1 className='m-0'>{timeRemain} <small>hrs</small></h1>
                    </div>}
                </div>
                <div className="row question-block mt-3 justify-content-between">
                    <div className="col-md-6">
                        {questionsData.map((question: any, i: number) => (
                            <div className='row mb-3' key={i}>
                                <div className='col-md-8 pt-3'>
                                    <span className='q-number'>Q{question.question_number}:</span>
                                    <span className='q-name'>
                                        {question.question}
                                    </span>
                                </div>
                                <div className='col-md-4 pt-2'>
                                    <FormBuilder onUpdate={handleOptions}>
                                        <form ref={surveyForm}>
                                            {question.question_type === 'input_text' && <input type="text" className="form-control" name="option"
                                                value={question.answer ? question.answer + '' : ''}
                                                // value={question?.list.map((data: any) => ( data.correct_answer )}
                                                // value={question.answer ? question?.map((data: any) => (data.correct_answer)) + '' : ''}
                                                // value={question.answer = question.correct_answer}
                                                disabled={canShowAlertPopup}
                                                onChange={(e) => { handleQuestionAnswers(e, question, i) }}
                                                onKeyDown={(e) => handleKeyDown(e, i)}
                                                ref={(inputRef) => (questionRefs.current[i] = inputRef)}

                                            />}
                                        </form>
                                    </FormBuilder>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="col-md-3">
                        {/* <MNSProgressView /> */}
                    </div>
                </div>
                {questionsData.length > 0 && <div className="py-3 text-center mt-3 border-top">
                    <button className="btn btn-primary px-5 rounded-5 cursor-pointer" onClick={handleCompleteSubmit}>Submit</button>
                    <Link to={`/student-dashboard/list/mns/view/${moduleUUID}/${userCode}/${schoolCode}`}><button className='btn btn-link ms-2'>Cancel</button></Link>
                </div>}
            </div>}
        </div >
    )
}
