import '../../assets/css/org.css';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { FormControlError, FormField, FormValidators } from '../../components/form-builder/model/form-field';
import { LOOK_UP_DATA } from '../../app/service/lookup.service';
import { CREATE_INDIVIDUAL_STUDENT, GET_USER_BY_ID, VALIDATE_USER_NAME } from '../../app/service/users.service';
import { FormValidator, GetControlIsValid } from '../../components/form-builder/validations';
import FormBuilder from '../../components/form-builder';
import errorMessages from '../../components/error-messages';
import { DateComponent } from '../../components/date';
import Logos from "../../assets/images/eliments.svg";
import Logo from "../../assets/images/iamsamarth-Logo-Final.svg";
import rocketLogo from "../../assets/images/rocket.png";
import { GET_REQUEST_OTP, GET_RESEND_OTP, GET_VERIFY_OTP } from '../../app/service/organisation.signup.service';
let intervalTimer: NodeJS.Timeout | null = null;

export const IndividualStudentSignupPage = () => {
    const [studentsData, setStudentsData] = useState<any>({});
    const [formData, setFormData] = useState<any>({});
    const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [currentStudentData, setCurrentStudentData] = useState<any>({});
    const [statesData, setStatesData] = useState<any>([]);
    const [districtData, setDistrictData] = useState<any>([]);
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [cityData, setCityData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [canShowUserPopup, setCanShowUserPopup] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(false);
    const [confirmMobileNumber, setConfirmMobileNumber] = useState(false);
    const [motherMobileNumber, setMotherMobileNumber] = useState(false);
    const [fathermobileNumber, setFatherMobileNumber] = useState(false);
    const [motherwhatsappNumber, setMotherWhatsappNumber] = useState(false);
    const [fatherwhatsappNumber, setFatherWhatsappNumber] = useState(false);
    const [pincodeNumber, setPincodeNumber] = useState(false);
    const [schoolPincodeNumber, setSchoolPincodeNumber] = useState(false);
    const [min, setMin] = useState<any>(moment().subtract(60, "years").format("YYYY-MM-DD"));
    const [max, setMax] = useState<any>(moment().subtract(10, "years").format("YYYY-MM-DD"));
    const [canCheckMotherMobileNumber, setCanCheckMotherMobileNumber] = useState(false);
    const [canCheckFatherMobileNumber, setCanCheckFatherMobileNumber] = useState(false);
    const [canShowMotherWhatsAppError, setCanShowMotherWhatsAppError] = useState(false);
    const [canShowFatherWhatsAppError, setCanShowFatherWhatsAppError] = useState(false);
    const [email, setEmail] = useState(false);
    const [confirmEmail, confirmSetEmail] = useState(false);
    const [motherEmail, setMotherEmail] = useState(false);
    const [fatherEmail, setFatherEmail] = useState(false);
    const [canSelectedDateofBirth, setCanSelectedDateofBirth] = useState(false);
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const [startDate, setStartDate] = useState(null);
    const [createDate, setCreateDate] = useState(new Date());
    const dates = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];
    const month = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    const [years, setYears] = useState<any>([]);
    const [canShowDate, setCanShowDate] = useState<any>();
    const [canShowMonth, setCanShowMonth] = useState<any>();
    const [canShowYear, setCanShowYear] = useState<any>();
    const [isRegistrationDateError, setIsRegistrationDateError] = useState<any>({});
    let { id } = useParams<{ id: string }>();
    const usersId: number = parseInt(id);
    const [isDateError, setIsDateError] = useState<any>({});
    const [nameError, setNameError] = useState('');
    const [addressOne, setAddressOneError] = useState('');
    const [addressTwo, setAddressTwoError] = useState('');
    const [motherNameError, setMotherNameError] = useState('');
    const [motherOccupationError, setMotherOccupationError] = useState('');
    const [fatherNameError, setFatherNameError] = useState('');
    const [fatherOccupationError, setFatherOccupationError] = useState('');
    const [schoolNameError, setSchoolError] = useState('');
    const [schoolAddressError, setSchoolAddressError] = useState('');
    const [schoolAddressTwoError, setSchoolAddressTwoError] = useState('');
    const [userNameError, setUserNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [avalableUserNames, setAvalableUserNames] = useState([]);
    const [verifyOTP, setVerifyOTP] = useState(false);
    const [checkOTPType, setCheckOTPType] = useState(true);
    const [OTPTypeData, setOTPTypeData] = useState<any>({});
    const [OTPValue, setOTPValue] = useState('');
    const [sendOtpSuccess, setSendOtpSuccess] = useState(true);
    const [checkAvailability, setCheckAvailability] = useState(false);
    const [checkUserExist, setCheckUserExist] = useState(false);
    const [emcfError, setEmcfError] = useState<string>("");
    const [cfMobileError, setCfMobileError] = useState<string>("");
    const history = useHistory();
    const [minutes, setMinutes] = useState<number>(5);
    const [seconds, setSeconds] = useState<number>(0);
    const [isActiveOTP, setIsActiveOTP] = useState<boolean>(false);
    const [showTimer, setShowTimer] = useState<boolean>(false);
    const formValidations = [
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('gender', [FormValidators.REQUIRED]),
        new FormField('date_of_birth', []),
        new FormField('standard', [FormValidators.REQUIRED]),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('confirm_student_email_id', [FormValidators.REQUIRED]),
        new FormField('mobile_number', [FormValidators.REQUIRED]),
        new FormField('confirm_student_mobile_number', [FormValidators.REQUIRED]),
        new FormField('address', [FormValidators.REQUIRED]),
        new FormField('address2', []),
        new FormField('state', [FormValidators.REQUIRED]),
        new FormField('district', [FormValidators.REQUIRED]),
        new FormField('pin_code', [FormValidators.REQUIRED]),
        new FormField('mother_name', [FormValidators.REQUIRED]),
        new FormField('mother_occupation', [FormValidators.REQUIRED]),
        new FormField('mother_mobile', [FormValidators.REQUIRED]),
        new FormField('mother_whatsapp', []),
        new FormField('mother_email_id', [FormValidators.REQUIRED]),
        new FormField('father_name', [FormValidators.REQUIRED]),
        new FormField('father_occupation', [FormValidators.REQUIRED]),
        new FormField('father_mobile', [FormValidators.REQUIRED]),
        new FormField('father_whatsapp', []),
        new FormField('father_email_id', [FormValidators.REQUIRED]),
        new FormField('institute_school_code', [FormValidators.REQUIRED]),
        new FormField('school_board', [FormValidators.REQUIRED]),
        new FormField('school_state', [FormValidators.REQUIRED]),
        new FormField('school_district', [FormValidators.REQUIRED]),
        new FormField('school_pincode', [FormValidators.REQUIRED]),
        new FormField('school_address', [FormValidators.REQUIRED]),
        new FormField('school_address2', []),
        new FormField('blood_group', []),
        new FormField('medium', [FormValidators.REQUIRED]),

    ];
    const mediums = [{
        "name": "English",
        "code": "ENGLISH"
    }, {
        "name": "Hindi",
        "code": "HINDI"
    }, {
        "name": "Marathi",
        "code": "MARATHI"
    },
    {
        "name": "Gujarati",
        "code": "GUJARATI"
    }
    ];

    useEffect(() => {
        const now = new Date().getUTCFullYear();
        let yearStart = now - 10;
        const years = Array(yearStart - (yearStart - 60)).fill('').map((v, idx) => yearStart - idx);
        setYears(years);

        setLoading(true);
        LOOK_UP_DATA().then((data1: any) => {
            if (data1.status === 'fail') {
                toast.error(data1.message);
                setLoading(false);
            } else {
                setLoading(false);
                setStatesData(data1.states);
            }
        });
    }, []);

    const handleInput = (data: any) => {
        data.value = { ...currentStudentData, ...data.value };
        setStudentsData(data);
        const errors: any = FormValidator(formValidations, data.value);
        setValidationErrors(errors);
    };

    const checkAvalableNames = () => {
        const data = studentsData.value ? { ...studentsData.value } : { ...currentStudentData };
        data.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;

        const validUser = {
            user_name: data.user_name,
            name: data.name,
            dob: data.date_of_birth
        }
        if (onChangeName(data.name) && data.date_of_birth && onChangeUserName(data.user_name)) {
            VALIDATE_USER_NAME(validUser).then((userValidData: any) => {
                if (userValidData?.available) {
                    setCheckAvailability(true)
                }

                if (!userValidData?.available) {
                    setAvalableUserNames(userValidData.ref_user_name)
                    setCheckUserExist(true)
                }
            })
        } else {
            if (!nameError && !onChangeName(data.name)) {
                setNameError('Please enter student name');
            }
            if (!userNameError && !onChangeUserName(data.user_name)) {
                setUserNameError('Please enter user name');
            }
            if (!data.date_of_birth) {
                setIsDateError({ error: "please select date of birth" });
            }
        }

    }

    const handleOTPChange = (e: any) => {
        const { name, value } = e.target;
        const numericRegex = /^[0-9]+$/;
        if (e.target.value === '' || numericRegex.test(e.target.value)) {
            setOTPValue(e.target.value);
        }
    }

    const handleGenerateOTP = () => {
        const data = { ...studentsData.value };
        if (validForm()) {
            const payload = {
                user_code: "",
                otp_type: "INDIVIDUAL_STUDENT_REGISTRATION_OTP",
                template_name: "individual_student_registration_otp",
                mobile_number: data.mobile_number,
                email_id: '',
                data: {
                    org_name: '',
                    name: data.name
                }
            }
            GET_REQUEST_OTP(payload).then((res: any) => {
                if (res.status === 'fail' || res.errorCode === 400) {
                    toast.error(res.message || res.errorMsg);
                    setLoading(false);
                } else {
                    if (res) {
                        handleStartTimer(5, 0);
                        setOTPTypeData(res);
                        setCheckOTPType(false);
                        setVerifyOTP(true);
                        toast.success("OTP sent successfully");
                        setSendOtpSuccess(false);
                    }
                    setLoading(false);
                }
            });
        } else {
            toast.error("Please fill in all mandatory fields.");
        }
    }

    const handleResendOTP = () => {
        const data = { ...studentsData.value };
        const payload = {
            otp_type: "INDIVIDUAL_STUDENT_REGISTRATION_OTP",
            ref_number: OTPTypeData.ref_number,
            template_name: "individual_student_registration_otp",
            mobile_number: data.mobile_number,
            email_id: '',
            data: {
                org_name: '',
                name: data.name
            }
        }
        GET_RESEND_OTP(payload).then((res: any) => {
            if (res.status === 'fail' || res.errorCode === 400) {
                toast.error(res.message || res.errorMsg);
                setLoading(false);
            } else {
                if (res) {
                    setOTPTypeData(res);
                    toast.success("OTP sent successfully");
                    clearTimerInterval();
                    setMinutes(5);
                    setSeconds(0);
                    handleStartTimer(5, 0);
                }
                setLoading(false);
            }
        });
    }



    const handleSubmit = () => {
        setIsFormSubmitted(true);
        const data = studentsData.value ? { ...studentsData.value } : { ...currentStudentData };
        data.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
        setCanSelectedDateofBirth(false);
        const errors: FormControlError[] = FormValidator(formValidations, data);
        setValidationErrors(errors);
        data.date_of_registration = todayDate;
        data.user_role = "STUDENT";
        data.indv_school_details = {
            school_name: data.institute_school_code,
            school_board: data.school_board,
            school_address: data.school_address,
            school_address2: data.school_address2 ? data.school_address2 : '',
            state: data.school_state,
            district: data.school_district,
            pin_code: data.school_pincode
        }
        delete data.sameMobileNumber;
        delete data.checkedMother;
        delete data.checkedFather;
        delete data.checked;
        delete data.school_board;
        delete data.school_address;
        delete data.school_state;
        delete data.school_district;
        delete data.school_pincode;
        delete data.day;
        delete data.month;
        delete data.year;
        delete data.school_address2;

        if (data.date_of_birth) {
            setCanSelectedDateofBirth(false);
        } else {
            setCanSelectedDateofBirth(true);
        }

        if ((data.email_id == data.confirm_student_email_id) && (data.mobile_number == data.confirm_student_mobile_number) && (data.password == data.confirm_password)) {

            if (errors.length < 1 && !canSelectedDateofBirth && !email && !mobileNumber && !motherMobileNumber && !fathermobileNumber && !pincodeNumber
                && onChangeName(data.name)
                && onChangeAddressOne(data.address)
                && onChangeMotherName(data.mother_name)
                && onChangeMotherOccupation(data.mother_occupation)
                && onChangeFatherName(data.father_name)
                && onChangeFatherOccupation(data.father_occupation)
                && onChangeSchoolName(data.institute_school_code)
                && onChangeSchoolAddress(data?.indv_school_details?.school_address)
                && onChangeUserName(data.user_name)
                && onChangePassword(data.password)
                && handleConfirmPassword(data.confirm_password)

            ) {
                data.mother_whatsapp = data.mother_whatsapp ? data.mother_whatsapp : data.mother_mobile;
                data.father_whatsapp = data.father_whatsapp ? data.father_whatsapp : data.father_mobile;
                setLoading(true);
                delete data.confirm_student_email_id;
                delete data.confirm_student_mobile_number;
                delete data.confirm_password;
                handleVerifyOTP(data);
            } else {

                if (!nameError && !onChangeName(data.name)) {
                    setNameError('Please enter student name');
                }
                if (!addressOne && !onChangeAddressOne(data.address)) {
                    setAddressOneError('Please enter address line 1');
                }

                if (!motherNameError && !onChangeMotherName(data.mother_name)) {
                    setMotherNameError('Please enter mother\'s Name');
                }
                if (!motherOccupationError && !onChangeMotherOccupation(data.mother_occupation)) {
                    setMotherOccupationError('Please enter mother\'s occupation');
                }
                if (!fatherNameError && !onChangeFatherName(data.father_name)) {
                    setFatherNameError('Please enter father\'s name');
                }
                if (!fatherOccupationError && !onChangeFatherOccupation(data.father_occupation)) {
                    setFatherOccupationError('Please enter father\'s occupation');
                }
                if (!schoolNameError && !onChangeSchoolName(data.institute_school_code)) {
                    setSchoolError('Please enter school name');
                }
                if (!schoolAddressError && !onChangeSchoolAddress(data?.indv_school_details?.school_address)) {
                    setSchoolAddressError('Please enter school address');
                }
                if (!userNameError && !onChangeUserName(data.user_name)) {
                    setUserNameError('Please enter user name');
                }
                if (!passwordError && !onChangePassword(data.password)) {
                    setPasswordError('Please enter password');
                }
                if (!confirmPasswordError && !handleConfirmPassword(data.confirm_password)) {
                    setConfirmPasswordError('Please enter password');
                }
            }

        } else {
            setCurrentStudentData(data);
            toast.error("confirm email and mobile number as well as passwords not matching");
        }
    }

    const handleVerifyOTP = (data: any) => {
        if (OTPValue) {
            const payload = {
                otp_type: "INDIVIDUAL_STUDENT_REGISTRATION_OTP",
                ref_number: OTPTypeData.ref_number,
                // otp_value: "0981"
                otp_value: OTPValue
            }

            GET_VERIFY_OTP(payload).then((res: any) => {
                if (res.status === 'fail' || res.errorCode === 400) {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    // toast.error(res.message || res.errorMsg);
                    setLoading(false);
                } else {
                    if (res) {
                        createIndividualStudent(data);
                    }
                    setLoading(false);
                }
            });
        }
    }


    const createIndividualStudent = (data: any) => {
        CREATE_INDIVIDUAL_STUDENT(data).then((res: any) => {
            if (res.status === 'fail' || res.errorCode === 400) {
                toast.error(res.message || res.errorMsg);
                setLoading(false);
                data.school_board = data.indv_school_details.school_board;
                data.school_address = data.indv_school_details.school_address;
                data.school_state = data.indv_school_details.state;
                data.school_district = data.indv_school_details.district;
                data.school_pincode = data.indv_school_details.pin_code;
                data.confirm_student_email_id = data.email_id;
                data.confirm_student_mobile_number = data.mobile_number;
                setCurrentStudentData(data);
            } else {
                toast.success("Student Created Successfully");
                setLoading(false);
                history.push('/individual-student-signup-success');

            }
        });
    }

    const getInputValid = (control: string) => {
        const value = GetControlIsValid(validationErrors, control);
        return value;
    }

    const handleStateChange = (e: any) => {
        const data = { ...studentsData.value };
        data.state = e.target.value;
        const preparedData = statesData.filter((el: any) => el.state === e.target.value);
        setDistrictData(preparedData[0].districts);
        setCityData(preparedData[0].cities);
        if (currentStudentData) {
            currentStudentData.state = e.target.value;
        }
    }

    const handleSchoolStateChange = (e: any) => {
        const data = { ...studentsData.value };
        data.school_state = e.target.value;
        const preparedData = statesData.filter((el: any) => el.state === e.target.value);
        setDistrictData(preparedData[0].districts);
        setCityData(preparedData[0].cities);
        if (currentStudentData) {
            currentStudentData.school_state = e.target.value;
        }
    }

    const handleDistrictChange = (e: any) => {
        const data = { ...studentsData.value };
        data.district = e.target.value;
        if (currentStudentData) {
            currentStudentData.district = e.target.value;
        }
    }

    const handleSchoolDistrictChange = (e: any) => {
        const data = { ...studentsData.value };
        data.school_district = e.target.value;
        if (currentStudentData) {
            currentStudentData.school_district = e.target.value;
        }
    }

    const handleSchoolBoardChange = (e: any) => {
        const data = { ...studentsData.value };
        data.school_board = e.target.value;
        if (currentStudentData) {
            currentStudentData.school_board = e.target.value;
        }
    }
    const handleMediumChange = (e: any) => {
        const data = { ...studentsData.value };
        data.medium = e.target.value;
        if (currentStudentData) {
            currentStudentData.medium = e.target.value;
        }
    }

    const handleGender = (e: any) => {
        const data = { ...studentsData.value };
        data.gender = e.target.value;
        if (currentStudentData) {
            currentStudentData.gender = e.target.value;
        }
    }

    const handleUserName = (e: any) => {
        const data = { ...studentsData.value };
        data.user_name = e.target.value;
        if (currentStudentData) {
            currentStudentData.user_name = e.target.value;
        }
    }


    const handleUserclose = () => {
        setCanShowUserPopup(false);
        history.push(`/`);
    }

    const handleStandardChange = (e: any) => {
        const data = { ...studentsData.value };
        data.standard = e.target.value;
        if (currentStudentData) {
            currentStudentData.standard = e.target.value;
        }
    }

    const handleMobileChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.mobile_number = e.target.value;
            setMobileNumber(false);
            if (e.target.value !== currentStudentData?.confirm_student_mobile_number && currentStudentData?.confirm_student_mobile_number) {
                setCfMobileError("Number not matched");
            } else {

            }
        } else {
            data.mobile_number = e.target.value;
            setMobileNumber(true);
            setCfMobileError("");
        }

        if (currentStudentData) {
            currentStudentData.mobile_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleConfirmMobileChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /(6|7|8|9)\d{9}/;
        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.confirm_student_mobile_number = e.target.value;
            setConfirmMobileNumber(false);
            if ((currentStudentData?.mobile_number !== e.target.value) && currentStudentData.mobile_number) {
                setCfMobileError("Number not matched");
            } else {
                setCfMobileError("");
            }
        } else {
            data.confirm_student_mobile_number = e.target.value;
            setConfirmMobileNumber(true);
        }

        if (currentStudentData) {
            currentStudentData.confirm_student_mobile_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleMothesMobileChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.mother_mobile = e.target.value;
            setMotherMobileNumber(false);
        } else {
            data.mother_mobile = e.target.value;
            setMotherMobileNumber(true);
        }

        if (currentStudentData) {
            currentStudentData.mother_mobile = e.target.value.replace(/\D+/g, '');
        }

        if (currentStudentData.checkedMother) {
            currentStudentData.mother_whatsapp = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleFatherMobileChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.father_mobile = e.target.value;
            setFatherMobileNumber(false);
        } else {
            data.father_mobile = e.target.value;
            setFatherMobileNumber(true);
        }

        if (currentStudentData) {
            currentStudentData.father_mobile = e.target.value.replace(/\D+/g, '');
        }

        if (currentStudentData.checkedFather) {
            currentStudentData.father_whatsapp = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleMotherWhatsappChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /(6|7|8|9)\d{9}/;
        let preValue = e.target.value.replace(/\D+/g, '');

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.mother_whatsapp = e.target.value;
            setMotherWhatsappNumber(false);
            setCanShowMotherWhatsAppError(false);
        } else {
            data.mother_whatsapp = e.target.value;
            if (preValue.length > 0) {
                setMotherWhatsappNumber(true);
            } else {
                setMotherWhatsappNumber(false);
            }
        }

        if (currentStudentData) {
            currentStudentData.mother_whatsapp = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleFatherWhatsappChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /(6|7|8|9)\d{9}/;
        let preValue = e.target.value.replace(/\D+/g, '');

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.father_whatsapp = e.target.value;
            setFatherWhatsappNumber(false);
            setCanShowFatherWhatsAppError(false);
        } else {
            data.father_whatsapp = e.target.value;
            if (preValue.length > 0) {
                setFatherWhatsappNumber(true);
            } else {
                setFatherWhatsappNumber(false);
            }

        }

        if (currentStudentData) {
            currentStudentData.father_whatsapp = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleEmailChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            data.email_id = e.target.value.toLowerCase();
            setEmail(false);
            if (e.target.value !== currentStudentData?.confirm_student_email_id && currentStudentData?.confirm_student_email_id) {
                setEmcfError("Email not matched");
            }
            else {
                setEmcfError("");
            }
        } else {
            data.email_id = e.target.value.toLowerCase();
            setEmail(true);
            setEmcfError("");
        }

        if (currentStudentData) {
            currentStudentData.email_id = e.target.value.toLowerCase();
        }
    }

    const handleConfirmEmailChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.confirm_student_email_id = e.target.value;
            confirmSetEmail(false);
            if (data?.email_id !== e.target.value && currentStudentData.email_id) {
                setEmcfError("Email not matched");
            } else {
                setEmcfError("");
            }
        } else {
            data.confirm_student_email_id = e.target.value.toLowerCase();
            confirmSetEmail(true);
        }

        if (currentStudentData) {
            currentStudentData.confirm_student_email_id = e.target.value.toLowerCase();
        }
    }

    const handleMotherEmailChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;


        if (e.target.value === '' || re.test(e.target.value)) {
            data.mother_email_id = e.target.value.toLowerCase();
            setMotherEmail(false);
        } else {
            data.mother_email_id = e.target.value.toLowerCase();
            setMotherEmail(true);
        }

        if (currentStudentData) {
            currentStudentData.mother_email_id = e.target.value.toLowerCase();
        }
    }

    const handleFatherEmailChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;


        if (e.target.value === '' || re.test(e.target.value)) {
            data.father_email_id = e.target.value.toLowerCase();
            setFatherEmail(false);
        } else {
            data.father_email_id = e.target.value.toLowerCase();
            setFatherEmail(true);
        }

        if (currentStudentData) {
            currentStudentData.father_email_id = e.target.value.toLowerCase();
        }
    }

    const handlePinCodeChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^[0-9\b]+$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 6) {
            data.pin_code = e.target.value;
            setPincodeNumber(false);
        } else {
            data.pin_code = e.target.value;
            setPincodeNumber(true);
        }

        if (currentStudentData) {
            currentStudentData.pin_code = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleSchoolPinCodeChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^[0-9\b]+$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 6) {
            data.school_pincode = e.target.value;
            setSchoolPincodeNumber(false);
        } else {
            data.school_pincode = e.target.value;
            setSchoolPincodeNumber(true);
        }

        if (currentStudentData) {
            currentStudentData.school_pincode = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleMotherMobileNumberChange = (e: any) => {
        const data = { ...studentsData.value };
        if (e.target.checked) {
            if (data.mother_whatsapp) {
                data.mother_whatsapp = data.mother_mobile;
                currentStudentData.mother_whatsapp = data.mother_mobile;
                setCanCheckMotherMobileNumber(true);
                setCanShowMotherWhatsAppError(false);
            }
            if (data.mother_mobile || currentStudentData.mother_mobile) {
                setCanCheckMotherMobileNumber(true);
                setCanShowMotherWhatsAppError(false);
                currentStudentData.mother_whatsapp = currentStudentData.mother_mobile;
                setCurrentStudentData(currentStudentData);
            }
            currentStudentData.checkedMother = true;
        } else {
            setCanCheckMotherMobileNumber(false);
            currentStudentData.checkedMother = false;
        }
    }

    const handleFatherMobileNumberChange = (e: any) => {
        const data = { ...studentsData.value };
        if (e.target.checked) {
            if (data.father_whatsapp) {
                data.father_whatsapp = data.father_mobile;
                currentStudentData.father_whatsapp = data.father_whatsapp;
                setCanCheckFatherMobileNumber(true);
                setCanShowFatherWhatsAppError(false);
            }
            if (data.father_mobile || currentStudentData.father_mobile) {
                setCanCheckFatherMobileNumber(true);
                setCanShowFatherWhatsAppError(false);
                currentStudentData.father_whatsapp = currentStudentData.father_mobile;
                setCurrentStudentData(currentStudentData);
            }
            currentStudentData.checkedFather = true;
        } else {
            setCanCheckFatherMobileNumber(false);
            currentStudentData.checkedFather = false;
        }
    }

    const onChange = (dates: any) => {
        setStartDate(null);
        if (dates.error) {
            setIsDateError(dates);
        } else {
            setStartDate(dates.date);
        }
    };

    const onDateChange = (dates: any) => {
        setTodayDate(todayDate);
        if (dates.error) {
            setIsRegistrationDateError(dates);
        } else {
            setTodayDate(dates.date);
        }
    };

    function addMonths(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    function subMonths(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    function onTodayDate(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    const handleDateofBirthChange = (e: any, type: any) => {
        const data = { ...studentsData.value };
        if (type === 'day') {
            setCanShowDate(e.target.value);
        } else if (type === 'month') {
            setCanShowMonth(e.target.value);
        } else if (type === 'year') {
            setCanShowYear(e.target.value);
        }
    }

    const onDateSelection = (value: any) => {
        setStartDate(null);
        if (value.error) {
            setIsDateError(value);
        } else {
            setStartDate(value.date);
        }

    }

    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Enter only alphabets');
            } else {
                if (currentStudentData) {
                    currentStudentData.name = event;
                }
                return true;
            }
        } else {
            setNameError('Please enter name');
        }

        if (currentStudentData) {
            currentStudentData.name = event;
        }
    }

    const onChangeAddressOne = (event: any) => {
        setAddressOneError('');
        if (event) {
            if (event.trim().length <= 2) {
                setAddressOneError('Address line 1 minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setAddressOneError('Address line 1 maximum 256 characters');
            } else {
                if (currentStudentData) {
                    currentStudentData.address = event;
                }
                return true;
            }

        } else {
            setAddressOneError('Please enter address line 1');
        }

        if (currentStudentData) {
            currentStudentData.address = event;
        }
    }

    const onChangeAddressTwo = (event: any) => {
        setAddressTwoError('');
        if (event) {
            if (event.trim().length <= 2) {
                setAddressTwoError('Address line 2 minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setAddressTwoError('Address line 2 maximum 256 characters');
            } else {
                if (currentStudentData) {
                    currentStudentData.address2 = event;
                }
                return true;
            }
        } else {
            setAddressTwoError('Please enter address line 2');
        }

        if (currentStudentData) {
            currentStudentData.address2 = event;
        }
    }

    const onChangeMotherName = (event: any) => {
        setMotherNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setMotherNameError('Enter only alphabets');
            } else {
                if (currentStudentData) {
                    currentStudentData.mother_name = event;
                }
                return true;
            }
        } else {
            setMotherNameError('Please enter Mother\'s name');
        }

        if (currentStudentData) {
            currentStudentData.mother_name = event;
        }
    }

    const onChangeUserName = (event: any) => {
        setCheckAvailability(false);
        setCheckUserExist(false);
        const data = { ...studentsData.value };
        data.user_name = event;
        if (currentStudentData) {
            currentStudentData.user_name = event;
        }
        setUserNameError('');
        const reg = /^(?=.*[a-zA-Z])[a-zA-Z0-9._-]{6,15}$/;
        if (event) {
            if (!reg.test(event.trim())) {
                // setUserNameError('User name allow only a-z A-Z 0-9 ._-');
                setUserNameError('User name between 6 to 15 characters, at least one letter, special characters ( allow only  ._- ),  numbers ');
            } else {
                return true;
            }
        } else {
            setUserNameError('Please enter user name');
        }
    }

    const onChangePassword = (event: any) => {
        setPasswordError('');
        const reg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setPasswordError('Password between 6 to 16 characters, at least one capital letter, at least one special character, at least one number');
            } else {
                if (currentStudentData) {
                    currentStudentData.password = event;
                }
                if (event !== currentStudentData?.confirm_password && currentStudentData?.confirm_password) {
                    setConfirmPasswordError("Password not matched");
                }
                return true;
            }
        } else {
            setPasswordError('Please enter password');

        }
        if (currentStudentData?.confirm_password && confirmPasswordError === "Password not matched") {
            setConfirmPasswordError("");

        }

        if (currentStudentData) {
            currentStudentData.password = event;
        }
    }

    const handleConfirmPassword = (event: any) => {
        setConfirmPasswordError('');
        const reg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setConfirmPasswordError('Password between 6 to 16 characters, at least one capital letter, at least one special character, at least one number');
            } else {
                if (currentStudentData) {
                    currentStudentData.confirm_password = event;
                }
                if (currentStudentData?.password !== event && currentStudentData?.password) {
                    setConfirmPasswordError('Password not matched');
                } else {
                    setConfirmPasswordError('');
                }
                return true;
            }
        } else {
            setConfirmPasswordError('Please enter Confirm password');
        }

        if (currentStudentData) {
            currentStudentData.confirm_password = event;
        }
    }

    const onChangeMotherOccupation = (event: any) => {
        setMotherOccupationError('');
        const reg = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setMotherOccupationError('Mother\'s occupation can`t contain spaces and special characters');
            } else {
                if (currentStudentData) {
                    currentStudentData.mother_occupation = event;
                }
                return true;
            }
        } else {
            setMotherOccupationError('Please enter Mother\'s occupation');
        }

        if (currentStudentData) {
            currentStudentData.mother_occupation = event;
        }
    }

    const onChangeFatherName = (event: any) => {
        setFatherNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setFatherNameError('Enter only alphabets');
            } else {
                if (currentStudentData) {
                    currentStudentData.father_name = event;
                }
                return true;
            }
        } else {
            setFatherNameError('Please enter Father\'s name');
        }

        if (currentStudentData) {
            currentStudentData.father_name = event;
        }
    }

    const onChangeFatherOccupation = (event: any) => {
        setFatherOccupationError('');
        const reg = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setFatherOccupationError('Father\'s occupation can`t contain spaces and special characters');
            } else {
                if (currentStudentData) {
                    currentStudentData.father_occupation = event;
                }
                return true;
            }
        } else {
            setFatherOccupationError('Please enter Father\'s occupation');
        }

        if (currentStudentData) {
            currentStudentData.father_occupation = event;
        }
    }

    const onChangeSchoolName = (event: any) => {
        setSchoolError('');
        if (event) {
            if (event.trim().length <= 2) {
                setSchoolError('School name minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setSchoolError('School name maximum 256 characters');
            } else {
                if (currentStudentData) {
                    currentStudentData.institute_school_code = event;
                }
                return true;
            }
        } else {
            setSchoolError('Please enter school name');
        }

        if (currentStudentData) {
            currentStudentData.institute_school_code = event;
        }
    }

    const onChangeSchoolAddress = (event: any) => {
        setSchoolAddressError('');
        if (event) {
            if (event.trim().length <= 2) {
                setSchoolAddressError('school address minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setSchoolAddressError('school address maximum 256 characters');
            } else {
                if (currentStudentData) {
                    currentStudentData.school_address = event;
                }
                return true;
            }
        } else {
            setSchoolAddressError('Please enter school address');
        }

        if (currentStudentData) {
            currentStudentData.school_address = event;
        }
    }

    const onChangeSchoolAddressTwo = (event: any) => {
        setSchoolAddressTwoError('');
        if (event) {
            if (event.trim().length <= 2) {
                setSchoolAddressTwoError('school address 2 minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setSchoolAddressTwoError('school address 2 maximum 256 characters');
            } else {
                return true;
            }
        } else {
            setSchoolAddressTwoError('Please enter school address 2');
        }
    }

    const handleBloodGroupChange = (e: any) => {
        const data = { ...currentStudentData.value };
        data.blood_group = e.target.value;
        if (currentStudentData) {
            currentStudentData.blood_group = e.target.value;
        }
    }
    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        // Prevent pasting
    };
    const validForm = () => {
        if (
            currentStudentData.name && !nameError && currentStudentData?.gender && startDate
            && currentStudentData.standard && currentStudentData.email_id && !email &&
            currentStudentData.email_id === currentStudentData.confirm_student_email_id && !confirmEmail && currentStudentData.mobile_number && !mobileNumber &&
            currentStudentData.confirm_student_mobile_number && !confirmMobileNumber && currentStudentData.mobile_number === currentStudentData.confirm_student_mobile_number &&
            currentStudentData.user_name && !userNameError && !checkUserExist &&
            currentStudentData.password && !passwordError && currentStudentData.password === currentStudentData.confirm_password && !confirmPasswordError &&
            currentStudentData?.address && !addressOne && currentStudentData?.state && currentStudentData?.district && currentStudentData?.pin_code && !pincodeNumber &&
            currentStudentData?.institute_school_code && !schoolNameError && currentStudentData?.school_board && currentStudentData.medium &&
            currentStudentData?.school_address && !schoolAddressError && currentStudentData?.school_state &&
            currentStudentData?.school_district && currentStudentData?.school_pincode && !schoolPincodeNumber &&
            currentStudentData.mother_name && !motherNameError && currentStudentData.mother_occupation && !motherOccupationError &&
            currentStudentData.mother_mobile && !motherMobileNumber && currentStudentData.mother_email_id && !motherEmail &&
            currentStudentData.father_name && !fatherNameError && currentStudentData.father_occupation && !fatherOccupationError &&
            currentStudentData.father_mobile && !fathermobileNumber && currentStudentData.father_email_id && !fatherEmail && !fatherwhatsappNumber && !motherwhatsappNumber

        ) {
            return true;
        } else {
            return false;
        }
    }
    const clearTimerInterval = () => {
        if (intervalTimer) {
            clearInterval(intervalTimer);
            intervalTimer = null;
        }
    }
    const handleStartTimer = (minutes: number, seconds: number) => {
        setIsActiveOTP(true);
        setShowTimer(false);
        let totalSeconds = minutes * 60 + seconds;
        intervalTimer = setInterval(() => {
            if (totalSeconds > 0) {
                totalSeconds--;
                let updatedMinutes = Math.floor(totalSeconds / 60);
                let updatedSeconds = totalSeconds % 60;
                if (updatedMinutes == (minutes - 2)) {
                    setIsActiveOTP(false);
                    setShowTimer(true);
                }
                setMinutes(updatedMinutes);
                setSeconds(updatedSeconds);
            } else {
                setSeconds(seconds);
                setMinutes(minutes);
                clearTimerInterval();
                setIsActiveOTP(false);
                setShowTimer(false);
            }
        }, 1000);
    }

    return (
        <div style={{ overflowY: "auto", height: "100vh" }}>
            <section className="mb-4 mt-4 px-3 px-md-0">
                <div className={`modal fade ${canShowUserPopup ? 'show' : 'false'}`} style={{ display: (canShowUserPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Student Login Details</h5>
                                <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleUserclose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div>Login ID: {studentInfo.user_name}</div>
                                <div>Password: {studentInfo.password}</div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleUserclose}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white border border-gray rounded-16 p-3 p-lg-5 container signup-block">
                    <div className="mb-4 d-flex align-items-center">
                        <div> <img src={Logo} alt="" className="login-logo" /></div>
                        <h2 className="login-hd mb-0 fw-light ms-3 ps-3 border-start border-dark">Individual Student <br />
                            Sign up </h2>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-4 mb-md-0">
                            <div className="signup-quotblock  pe-lg-5">
                                <h3 className="signup-quot fw-lighter"> Education is<br />
                                    not the learning of<br />
                                    facts but the<br />
                                    training of the<br />
                                    mind to think</h3>
                                <img src={rocketLogo} className="rocket-img" alt="" /> </div>
                        </div>
                        <div className="col-md-8 ps-lg-5">
                            <FormBuilder onUpdate={handleInput}>
                                <form>
                                    <h5 className="mb-2 fw-normal">Personal Details </h5>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Name <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter name" name="name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentStudentData.name} />
                                            {/* <p className="error-msg">Please fill the name field</p> */}
                                            {nameError && <p className="error-msg">{nameError}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Student Gender <i className="required-star">*</i></label>
                                            <div className="form-check-inline-custom">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input cursor-pointer" type="radio" name="gender" value="male" checked={currentStudentData?.gender === "male"} onChange={(e) => { handleGender(e) }} />
                                                    <label className="form-check-label fw-light" htmlFor="inlineRadio1">Male</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input cursor-pointer" type="radio" name="gender" value="female" checked={currentStudentData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                                                    <label className="form-check-label fw-light" htmlFor="inlineRadio2">Female</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input cursor-pointer" type="radio" value="transgender" name="gender" checked={currentStudentData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                                                    <label className="form-check-label fw-light" htmlFor="inlineRadio3">Other</label>
                                                </div>
                                            </div>
                                            {isFormSubmitted && !getInputValid('gender') && <p className="error-msg">Please select gender</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Student Date of Birth <i className="required-star">*</i></label>
                                            {currentStudentData.date_of_birth &&
                                                <DateComponent onDate={onDateSelection} yearDiff={10} defaultData={currentStudentData.date_of_birth} margin={1}></DateComponent>}
                                            {!currentStudentData.date_of_birth && <DateComponent onDate={onDateSelection} yearDiff={10} margin={1}></DateComponent>}
                                            {isFormSubmitted && !startDate && !isDateError?.error && <p className="error-msg">Please select date</p>}
                                            {isDateError?.error && !startDate && <p className="error-msg">{isDateError?.error}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Blood Group</label>
                                            <select name="blood_group" value={currentStudentData.blood_group} onChange={(e) => { handleBloodGroupChange(e) }} className="form-select form-select-lg mb-3">
                                                <option value="">Select blood group</option>
                                                <option value="O+">O+</option>
                                                <option value="O-">O-</option>
                                                <option value="A-">A-</option>
                                                <option value="A+">A+</option>
                                                <option value="B-">B-</option>
                                                <option value="B+">B+</option>
                                                <option value="AB-">AB-</option>
                                                <option value="AB+">AB+</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Student Class <i className="required-star">*</i></label>
                                            <select className="form-select form-select-lg" name="standard" value={currentStudentData.standard} onChange={(e) => { handleStandardChange(e) }}>
                                                <option value="">Select class</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                            {isFormSubmitted && !getInputValid('standard') && <p className="error-msg">Please select class</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Student Email ID <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter email" name="email_id" value={currentStudentData.email_id} onChange={(e) => { handleEmailChange(e) }} />
                                            {email && <p className="error-msg">{errorMessages.email}</p>}
                                            {isFormSubmitted && !getInputValid('email_id') && <p className="error-msg">Please enter email id</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Confirm Student Email ID <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter email" name="confirm_student_email_id" value={currentStudentData.confirm_student_email_id} onChange={(e) => { handleConfirmEmailChange(e) }} onPaste={handlePaste} />
                                            {confirmEmail && <p className="error-msg">{errorMessages.email}</p>}
                                            {isFormSubmitted && !getInputValid('confirm_student_email_id') && <p className="error-msg">Please enter confirm email id</p>}
                                            {(!confirmEmail && !isFormSubmitted && emcfError) && <p className="error-msg">{emcfError}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Student Mobile Number <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter number" name="mobile_number" maxLength={10} onChange={(e) => { handleMobileChange(e) }} value={currentStudentData.mobile_number} />
                                            {mobileNumber && <p className="error-msg">{errorMessages.mobile_number}</p>}
                                            {isFormSubmitted && !getInputValid('mobile_number') && <p className="error-msg">Please enter mobile number</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Confirm Student Mobile Number <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter number" name="confirm_student_mobile_number" maxLength={10} onChange={(e) => { handleConfirmMobileChange(e) }} value={currentStudentData.confirm_student_mobile_number} onPaste={handlePaste} autoComplete="off" />
                                            {confirmMobileNumber && <p className="error-msg">{errorMessages.mobile_number}</p>}
                                            {isFormSubmitted && !getInputValid('confirm_student_mobile_number') && <p className="error-msg">Please enter confirm mobile number</p>}
                                            {(!confirmMobileNumber && !isFormSubmitted && cfMobileError) && <p className="error-msg">{cfMobileError}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    <label className="form-label mb-0 fw-light">User Name <i className="required-star">*</i> </label>
                                                </div>
                                                <div>
                                                    {checkAvailability &&
                                                        <p className='text-completed mb-0 ps-1 f14'>User Name Available <i className='fa fa-check-circle ps-1'></i></p>
                                                    }
                                                    {checkUserExist && <p className='text-inprogess mb-0 ps-1 f14'> User Name Not Available <i className='fa fa-times-circle-o ps-1'></i></p>}
                                                </div>
                                            </div>
                                            <input className="form-control" type="text" placeholder="Enter name" name="user_name" onChange={(e) => onChangeUserName(e.target.value)} value={currentStudentData.user_name} />
                                            {userNameError && <p className="error-msg">{userNameError}</p>}
                                            <a className='active' style={{ cursor: 'pointer' }} onClick={checkAvalableNames}>Check availability</a>

                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Choose Password <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter password" name="password" maxLength={16} onChange={(e) => onChangePassword(e.target.value)} value={currentStudentData.password} />
                                            {passwordError && <p className="error-msg">{passwordError}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Confirm Password <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter password" name="confirm_password" maxLength={16} onChange={(e) => handleConfirmPassword(e.target.value)} value={currentStudentData.confirm_password} onPaste={handlePaste} />
                                            {confirmPasswordError && <p className="error-msg">{confirmPasswordError}</p>}
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                    <h5 className="mb-2 fw-normal">Address</h5>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Address 1 <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter address" onChange={(e) => onChangeAddressOne(e.target.value)} value={currentStudentData?.address} name="address" />
                                            {addressOne && <p className="error-msg">{addressOne}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Address 2</label>
                                            <input className="form-control" type="text" placeholder="Enter address" onChange={(e) => onChangeAddressTwo(e.target.value)} value={currentStudentData?.address2} name="address2" />
                                            {addressTwo && <p className="error-msg">{addressTwo}</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">State <i className="required-star">*</i></label>
                                            <select name="state" className="form-select form-select-lg" value={currentStudentData?.state} onChange={(e) => { handleStateChange(e) }}>
                                                <option value="">Select state</option>
                                                {statesData.map((name: any, i: number) => (
                                                    <option key={i} value={name.state}>
                                                        {name.state}
                                                    </option>
                                                ))}
                                            </select>
                                            {isFormSubmitted && !getInputValid('state') && <p className="error-msg">Please select state</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">District <i className="required-star">*</i></label>
                                            <select name="district" className="form-select form-select-lg" value={currentStudentData?.district} onChange={(e) => { handleDistrictChange(e) }}>
                                                <option value="">Select district</option>
                                                {districtData.map((name: any, i: number) => (
                                                    <option key={i} value={name}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </select>
                                            {isFormSubmitted && !getInputValid('district') && <p className="error-msg">Please select district</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">PIN Code <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter PIN code" name="pin_code" maxLength={6} onChange={(e) => { handlePinCodeChange(e) }} value={currentStudentData?.pin_code} />
                                            {pincodeNumber && <p className="error-msg">{errorMessages.pincode}</p>}
                                            {isFormSubmitted && !getInputValid('pin_code') && <p className="error-msg">Please enter pin code</p>}
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                    <h5 className="mb-2 fw-normal">School Details</h5>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">School Name <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter name" value={currentStudentData?.institute_school_code} onChange={(e) => onChangeSchoolName(e.target.value)} name="institute_school_code" />
                                            {schoolNameError && <p className="error-msg">{schoolNameError}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">School Board <i className="required-star">*</i></label>
                                            <select className="form-select form-select-lg" name="school_board" value={currentStudentData?.school_board} onChange={(e) => { handleSchoolBoardChange(e) }}>
                                                <option value="">Select school board</option>
                                                <option value="SSC">SSC</option>
                                                <option value="ICSE">ICSE</option>
                                                <option value="CBSE">CBSE</option>
                                            </select>
                                            {isFormSubmitted && !getInputValid('school_board') && <p className="error-msg">Please select school board</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Medium <i className="required-star">*</i></label>
                                            <select className="form-select form-select-lg mb-3" name="medium" value={currentStudentData.medium} onChange={(e) => { handleMediumChange(e) }}>
                                                <option value="">Select medium</option>
                                                {mediums?.map((medium: any, i: number) => (
                                                    <option value={medium?.code} key={i}>{medium?.name}</option>
                                                ))}
                                            </select>
                                            {isFormSubmitted && !getInputValid('medium') && <p className="text-danger">Please select medium</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Address 1 <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter address" value={currentStudentData?.school_address} onChange={(e) => onChangeSchoolAddress(e.target.value)} name="school_address" />
                                            {schoolAddressError && <p className="error-msg">{schoolAddressError}</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Address 2</label>
                                            <input className="form-control" type="text" placeholder="Enter address" value={currentStudentData?.school_address2} name="school_address2" />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">State <i className="required-star">*</i></label>
                                            <select name="school_state" className="form-select form-select-lg" value={currentStudentData?.school_state} onChange={(e) => { handleSchoolStateChange(e) }}>
                                                <option value="">Select state</option>
                                                {statesData.map((name: any, i: number) => (
                                                    <option key={i} value={name.state}>
                                                        {name.state}
                                                    </option>
                                                ))}
                                            </select>
                                            {isFormSubmitted && !getInputValid('school_state') && <p className="error-msg">Please select state</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">

                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">District <i className="required-star">*</i></label>
                                            <select name="school_district" className="form-select form-select-lg" value={currentStudentData?.school_district} onChange={(e) => { handleSchoolDistrictChange(e) }}>
                                                <option value="">Select district</option>
                                                {districtData.map((name: any, i: number) => (
                                                    <option key={i} value={name}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </select>
                                            {isFormSubmitted && !getInputValid('school_district') && <p className="error-msg">Please select district</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">PIN Code <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter PIN code" name="school_pincode" maxLength={6} onChange={(e) => { handleSchoolPinCodeChange(e) }} value={currentStudentData?.school_pincode} />
                                            {schoolPincodeNumber && <p className="error-msg">{errorMessages.pincode}</p>}
                                            {isFormSubmitted && !getInputValid('school_pincode') && <p className="error-msg">Please enter pin code</p>}
                                        </div>
                                    </div>
                                    {/* <div className="row custom-form org-custom-form">
                                        
                                    </div> */}
                                    <hr className="my-4" />
                                    <h5 className="mb-2 fw-normal">Parent Details </h5>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Mother's Name <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter name" name="mother_name" onChange={(e) => onChangeMotherName(e.target.value)} value={currentStudentData.mother_name} />
                                            {motherNameError && <p className="error-msg">{motherNameError}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Mother's Occupation <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter occupation" name="mother_occupation" onChange={(e) => onChangeMotherOccupation(e.target.value)} value={currentStudentData.mother_occupation} />
                                            {motherOccupationError && <p className="error-msg">{motherOccupationError}</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Mother's Mobile Number <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter number " name="mother_mobile" maxLength={10} value={currentStudentData.mother_mobile} onChange={(e) => { handleMothesMobileChange(e) }} />
                                            {motherMobileNumber && <p className="error-msg">{errorMessages.mobile_number}</p>}
                                            {isFormSubmitted && !getInputValid('mother_mobile') && <p className="error-msg">Please enter mobile number</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Mother's WhatsApp Number </label>
                                            <input className="form-control form-control-lg" disabled={currentStudentData.checkedMother} type="text" name="mother_whatsapp" maxLength={10} value={currentStudentData.mother_whatsapp} onChange={(e) => { handleMotherWhatsappChange(e) }} placeholder="Enter number" />
                                            <div className="form-check form-check-inline mx-3">
                                                <input className="form-check-input" type="checkbox" name="sameMobileNumber" checked={currentStudentData.checkedMother} onChange={(e) => { handleMotherMobileNumberChange(e) }} />
                                                <label className="form-check-label fw-light f12" >Same as Mobile Number</label>
                                            </div>
                                            {(motherwhatsappNumber) && <p className="error-msg">{errorMessages.whatsapp_number}</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Mother's Email ID <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter email" name="mother_email_id" value={currentStudentData.mother_email_id} onChange={(e) => { handleMotherEmailChange(e) }} />
                                            {motherEmail && <p className="error-msg">{errorMessages.email}</p>}
                                            {isFormSubmitted && !getInputValid('mother_email_id') && <p className="error-msg">Please enter email id</p>}

                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Father's Name <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter name" name="father_name" onChange={(e) => onChangeFatherName(e.target.value)} value={currentStudentData.father_name} />
                                            {fatherNameError && <p className="error-msg">{fatherNameError}</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Father's Occupation <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter occupation" name="father_occupation" onChange={(e) => onChangeFatherOccupation(e.target.value)} value={currentStudentData.father_occupation} />
                                            {fatherOccupationError && <p className="error-msg">{fatherOccupationError}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Father's Mobile Number <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter number" name="father_mobile" maxLength={10} value={currentStudentData.father_mobile} onChange={(e) => { handleFatherMobileChange(e) }} />
                                            {fathermobileNumber && <p className="error-msg">{errorMessages.mobile_number}</p>}
                                            {isFormSubmitted && !getInputValid('father_mobile') && <p className="error-msg">Please enter mobile number</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Father's WhatsApp Number</label>
                                            <input className="form-control form-control-lg" disabled={currentStudentData.checkedFather} type="text" name="father_whatsapp" maxLength={10} value={currentStudentData.father_whatsapp} onChange={(e) => { handleFatherWhatsappChange(e) }} placeholder="Please enter whatsapp number" />
                                            <div className="form-check form-check-inline mx-3">
                                                <input className="form-check-input" type="checkbox" name="sameMobileNumber" checked={currentStudentData.checkedFather} onChange={(e) => { handleFatherMobileNumberChange(e) }} />
                                                <label className="form-check-label fw-light f12" >Same as Mobile Number</label>
                                            </div>
                                            {(fatherwhatsappNumber) && <p className="error-msg">{errorMessages.whatsapp_number}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Father's Email ID <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter email" name="father_email_id" value={currentStudentData.father_email_id} onChange={(e) => { handleFatherEmailChange(e) }} />
                                            {fatherEmail && <p className="error-msg">{errorMessages.email}</p>}
                                            {isFormSubmitted && !getInputValid('father_email_id') && <p className="error-msg">Please enter email id</p>}
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                </form>
                            </FormBuilder>
                            <h5 className="mb-2 fw-normal mt-3">Verification</h5>
                            <div className="mb-4 position-relative">
                                {sendOtpSuccess && <button type="submit" className="btn btn-primary btn-sm px-3 rounded-12 rounded-org-12" onClick={handleGenerateOTP}>Send OTP</button>}
                                {!sendOtpSuccess && <button type="submit" disabled={!sendOtpSuccess} className="btn btn-primary btn-sm px-3 rounded-12 rounded-org-12" onClick={handleGenerateOTP}>Send OTP</button>}&nbsp;
                                <a className={`${(checkOTPType || isActiveOTP) ? 'text-decoration-none ms-2 rounded-org-12 disabled-link text-secondary f14' : 'text-primary text-decoration-none ms-2 rounded-org-12'}`} onClick={handleResendOTP} style={{ cursor: 'pointer' }} >
                                    Resend OTP
                                </a>
                                {(isActiveOTP || showTimer) && <p className='mb-0 text-inprogess f12 position-absolute mt-1'>OTP is valid for {minutes < 10 ? `0${minutes}` : minutes} : {seconds < 10 ? `0${seconds}` : seconds}</p>}
                            </div>
                            <div className="row custom-form org-custom-form ">
                                <div className="col-md-6 mb-3 mt-1">
                                    <label className="form-label mb-0 fw-light">Please Enter OTP <i className="required-star">*</i></label>
                                    <input className="form-control" type="text" placeholder="_ _ _ _" name="otp_value" maxLength={4} value={OTPValue} onChange={(e) => { handleOTPChange(e) }} />
                                </div>
                            </div>
                            <button type="submit" disabled={!verifyOTP} className="btn btn-primary btn-sm px-3 rounded-12" onClick={handleSubmit}>Verify OTP and Submit</button>
                        </div>
                    </div>
                </div>
                <div className="login-elements text-center"><img src={Logos} alt="" className="login-elements-icons" /></div>
            </section>
            <footer className="text-center f12 text-dgray mb-3">
                <div className="container">
                    &copy; 2023-24  <a href="http://iamsamarth.com" className="text-dgray text-decoration-none">iamsamarth.com</a>. All Rights Reserved | <a href="http://iamsamarth.com/privacy-policy/" className="text-dgray text-decoration-none">Terms and Conditions</a> |
                    <a href="http://iamsamarth.com/privacy-policy/" className="text-dgray text-decoration-none ms-1">Privacy Policy</a>
                </div>
            </footer>
        </div>
    )
}