import React, { useEffect, useState } from "react";
import { CONTENT_ASSESSMENT_TEST_CREATE } from "../../../../../app/service/content-module/test-service";
import { toast } from "react-toastify";
import { GET_CONTENT_QUESTIONS } from "../../../../../app/service/content-module/question-service";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import CustomVideoPlayer from "../../../../../components/video-selectors/video-plays";
import { ViewTestData } from "../preview-test";
import { CREATE_ABHYAS_QUESTION } from "../../../../../app/service/abhyas-question.service";
import TESTICON from "../../../../../institute-dashboard-assets/images/Success_test.png"
import { GET_SUBJECT_FILTERS_LIST } from "../../../../../app/service/abhyas-worksheet-filter.service";

interface Props {
    filterData: any;
    standardName: any;
    subjectName: any;
    standardsList: any;
    handledBack: () => void;
}

export const AssessmentAddQuestions: React.FC<Props> = (props: Props) => {
    const [filterData, setFilterData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [addedToAT1, setAddedToAT1] = useState<any>([]);
    const [addedToAT2, setAddedToAT2] = useState<any>([]);
    const [testInfo, setTestInfo] = useState<any>({});
    const [questionsList, setQuestionsList] = useState<any>([]);
    const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [testSubmitLoading, setTestSubmitLoading] = useState<boolean>(false);
    const [questionLoading, setQuestionLoading] = useState<boolean>(false);
    const [showPopUop, setShowPopUp] = useState<boolean>(false);
    const [standardUuid, setStandardUuid] = useState<any>("");
    const [subjectName, setSubjectName] = useState<any>("");
    const [standardName, setStandardName] = useState<any>("");

    useEffect(() => {
        setStandardName(props?.standardName);
        setSubjectName(props?.subjectName);
        setFilterData(props.filterData);
        fetchAllData(props.filterData);
    }, [props.filterData]);

    const fetchAllData = async (filterData: any) => {
        setLoading(true);
        try {
            await getTestList(filterData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const getTestList = async (filterData: any) => {
        const payLoad = {
            board_uuid: filterData?.board_uuid,
            medium_uuid: filterData?.medium_uuid,
            standard_uuid: filterData?.standard_uuid,
            subject_uuid: filterData?.subject_uuid,
        }
        const res: any = await CONTENT_ASSESSMENT_TEST_CREATE(payLoad);
        if (res?.test1_uuid || res?.test2_uuid) {
            setTestInfo(res);
        }
        return res;

    }
    const getQuestionsList = async (standardUuid: any, subjectUuid: any) => {
        setQuestionLoading(true);
        try {
            const payLoad = {
                board_uuid: filterData?.board_uuid,
                medium_uuid: filterData?.medium_uuid,
                standard_uuid: standardUuid,
                subject_uuid: subjectUuid,
                sorting_type: "ASC",
                content_type: "QUESTION"
            }
            const res: any = await GET_CONTENT_QUESTIONS(payLoad, 0, 1000000);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setLoading(false);
                } else {
                    const data = res?.data;
                    if (data && data?.length > 0) {
                        setQuestionsList(data);
                    }
                }
            }
            setQuestionLoading(false);
        } catch (error) {
            setQuestionLoading(false);
        } finally {
            setQuestionLoading(false);

        }

    }

    const handleSubmitTests = async () => {
        if (addedToAT1?.length !== 60 || addedToAT2?.length !== 60) {
            toast.error("Please ensure both AT-1 and AT-2 have exactly 60 questions.");
            return;
        }
        setTestSubmitLoading(true); // Start loading
        try {
            let testOneres: any = {};
            let testTwores: any = {};

            // Create AT-1 if the length is exactly 60
            if (addedToAT1?.length === 60) {
                testOneres = await CREATE_ABHYAS_QUESTION(addedToAT1, testInfo?.test1_uuid);
            }

            // Create AT-2 if the length is exactly 60
            if (addedToAT2?.length === 60) {
                testTwores = await CREATE_ABHYAS_QUESTION(addedToAT2, testInfo?.test2_uuid);
            }

            // Check if both API responses are successful before showing the popup
            if (
                testOneres && Object.keys(testOneres)?.length > 0 &&
                testTwores && Object.keys(testTwores)?.length > 0
            ) {
                setShowPopUp(true);
            }
        } catch (error) {
            console.error("Error creating tests:", error);
            toast.error("Error while creating AT-1 and AT-2. Please try again.");
        } finally {
            setTestSubmitLoading(false); // Stop loading after completion
        }
    };
    function onViewQuestion(name: any) {
        return { __html: name };
    }
    const toggleCollapse = (index: number) => {
        setExpanded((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };
    const getAnswerValue = (anwerValue: string, optionList: any) => {
        const preData = optionList?.filter((item: any) => item?.option === anwerValue);
        if (preData) {
            return preData[0]?.value
        } else {
            return " ";
        }

    }
    // Function to toggle add/remove for AT-1
    const handleAddToAT1 = (item: any) => {
        if (isAddedToAT1(item)) {
            // Remove item if it already exists in AT-1
            const updatedAT1 = addedToAT1.filter((q: any) => q.id !== item.id);
            setAddedToAT1(updatedAT1);
        } else {
            // Add item if it does not exist
            const preData = { ...item }
            preData.mode = "add";
            setAddedToAT1([...addedToAT1, preData]);
        }
    };

    // Function to toggle add/remove for AT-2
    const handleAddToAT2 = (item: any) => {
        if (isAddedToAT2(item)) {
            // Remove item if it already exists in AT-2
            const updatedAT2 = addedToAT2.filter((q: any) => q.id !== item.id);
            setAddedToAT2(updatedAT2);
        } else {
            // Add item if it does not exist
            const preData = { ...item }
            preData.mode = "add";
            setAddedToAT2([...addedToAT2, preData]);
        }
    };
    const isAddedToAT1 = (item: any) => addedToAT1.some((q: any) => q.id === item.id);

    // Check if an item is added to AT-2
    const isAddedToAT2 = (item: any) => addedToAT2.some((q: any) => q.id === item.id);
    const getBgcolor = (level: any) => {
        switch (level) {
            case "EASY":
                return "easy";
            case "MEDIUM":
                return "md";
            case "HARD":
                return "hd";
            default:
                return "easy";
        }
    }
    const handleClosePopUp = () => {
        setShowPopUp(false);
        props?.handledBack();

    }
    const getQuestionByStandard = async (uuid: any) => {
        setStandardUuid(uuid);
        try {
            const res: any = await GET_SUBJECT_FILTERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, uuid);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res['rows'];
                    const subjectData = data?.find((item: any) => item.name === subjectName);
                    if (subjectData?.uuid) {
                        getQuestionsList(uuid, subjectData?.uuid);
                    } else {
                        setQuestionsList([]);
                    }
                }
            }
        } catch (error) {

        } finally {

        }

    }
    return (
        <div>
            <div
                className={`modal fade ${showPopUop ? "show" : "false"}`}
                style={{ display: showPopUop ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
            >
                <div className="test_create_modal-dialog_popup" role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body  payment_user_popup_info position-relative">
                            <button type="button" className="close popup-close position-absolute" data-dismiss="modal" aria-label="Close" onClick={handleClosePopUp} style={{ right: "20px" }}>
                                <span>&times;</span>
                            </button>
                            <div className='payment_user_popup_block '>
                                <div className='mt-1 d-flex justify-content-center'>
                                    <div className="text-center ">
                                        <img src={TESTICON} style={{ height: "50%" }} />
                                        <h5 className="test_cls_blue f22">Assessment Test-1 <span className="fw-normal">and</span> Assessment Test-2  <br />of<span className=" test_std_text"> Class {standardName}th</span> <br /><span className="fw-normal">Successfully Added!</span></h5>
                                        <p className="mb-1 mt-4 f16">For both the tests </p>
                                        <p className="mb-1 mt-1 f16">Questions: 60 | Time 60 mins</p>
                                    </div>
                                </div>
                                <div className='mt-5 pt-3 text-center'>
                                    <button className='btn payment_user_btn  px-5 rounded-12 payment_mem_text py-1 f16 fw-bold' onClick={handleClosePopUp}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {(!loading && testInfo?.test1_uuid && testInfo?.test2_uuid) && <div>
                <div className="border-top border-bottom border-1 mt-2">
                    <div className="d-flex my-2">
                        <div>
                            {props?.standardsList?.map((data: any, i: number) => (
                                <button className={`btn ${data?.uuid === standardUuid ? "stnd_bg" : "btn-primary"} f14 px-3 py-1 me-3`} key={i} onClick={() => getQuestionByStandard(data?.uuid)}>{data?.standard}th std</button>
                            ))}
                        </div>
                        <p className="ms-4 f16 mb-0">Selected Questions in <span className="fw-bold me-3">Assessment Test - 1: <span className="text-danger ms-2">{addedToAT1?.length}/60</span></span> & <span className="ms-2 fw-bold">Assessment Test - 2: <span className="text-danger ms-2">{addedToAT2?.length}/60</span></span></p>
                    </div>
                </div>
                {showPreview && <div className="row justify-content-between mt-3">
                    <div className="col-12 col-md-6 pe-4">
                        <ViewTestData questionsList={addedToAT1} titleName="Assessment Test-1 Preview" />
                    </div>
                    <div className="col-12 col-md-6 ps-4">
                        <ViewTestData questionsList={addedToAT2} titleName="Assessment Test-2 Preview" />
                    </div>
                    <div className="text-end mt-3"><button className="btn btn-primary f16 px-3 py-1" onClick={() => setShowPreview(false)}>Back</button></div>
                </div>}
                {questionLoading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {(!showPreview && !questionLoading && questionsList?.length > 0) &&
                    <>
                        <div className=" ps-4 pe-3 pt-2 question_view_height">
                            <MathJaxContext>
                                <MathJax>
                                    {questionsList?.map((questionItem: any, i: number) => (
                                        <div className="row mt-3 ">
                                            <div className="col-8">
                                                <div key={i}>
                                                    <div className='row col-md-12 '>
                                                        <div className='row'>
                                                            <div className='col-1'>
                                                                <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                                    Q{i + 1}:
                                                                </p>
                                                            </div>
                                                            <div className='col-11  img_ck'>
                                                                <div className='d-flex'>
                                                                    {questionItem?.video_url && <CustomVideoPlayer videoSrc={questionItem?.video_url} customClass="w-50" />}
                                                                    {questionItem?.audio_url && <audio src={questionItem?.audio_url} controls></audio>}
                                                                </div>
                                                                <span className='fw-bold img_ck video_ck' style={{ fontSize: '22px' }}>
                                                                    <div dangerouslySetInnerHTML={onViewQuestion(questionItem.question)}></div>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        {expanded[i] && (<div id={`collapse${i}  img_ck`}>
                                                            {Array.isArray(questionItem?.options) && questionItem?.options?.map((options: any, j: number) => (
                                                                <div className='row' key={j}>
                                                                    <div className='col-md-1 px-0 text-center'>
                                                                        <span>{options.option})</span>
                                                                    </div>
                                                                    <div className='col-md-6 text-start '>
                                                                        <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <p className="d-flex mb-0">
                                                                <span className="text-primary  f16 me-2">Ans:</span>
                                                                {questionItem?.correct_answer}){" "}
                                                                {questionItem?.correct_answer && (
                                                                    <span
                                                                        className="ms-2"
                                                                        dangerouslySetInnerHTML={onViewQuestion(
                                                                            getAnswerValue(questionItem?.correct_answer, questionItem.options)
                                                                        )}
                                                                    ></span>
                                                                )}
                                                            </p>
                                                            <p className="d-flex mb-1">
                                                                <span className="text-primary mb-1 f16 me-2">Explanation:</span>
                                                                {questionItem?.explanation && (
                                                                    <span
                                                                        className="ms-2"
                                                                        dangerouslySetInnerHTML={onViewQuestion(
                                                                            questionItem?.explanation
                                                                        )}
                                                                    ></span>
                                                                )}
                                                            </p>
                                                        </div>
                                                        )}
                                                    </div>

                                                    <div className="d-flex ">
                                                        <p
                                                            className="mb-1 f14  text-primary cursor-pointer"
                                                            role="button"
                                                            aria-expanded={expanded[i] || false}
                                                            aria-controls={`collapse${i}`}
                                                            onClick={() => toggleCollapse(i)}
                                                        >
                                                            <u><i>{expanded[i] ? "Show less" : "Show more"}</i></u>
                                                        </p>
                                                        {questionItem?.difficulty_level && <p className={`mb-0 ms-4 f12 level_custom-btn ${getBgcolor(questionItem?.difficulty_level)}`}>{questionItem?.difficulty_level}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="text-end me-3">
                                                    <button
                                                        className={`btn ${isAddedToAT1(questionItem) ? "added_test_bg" : "add_test_bg"} f14 px-3 py-1 me-3`}
                                                        onClick={() => handleAddToAT1(questionItem)}
                                                        disabled={isAddedToAT2(questionItem)}
                                                    >
                                                        {isAddedToAT1(questionItem) ? "Added to AT-1" : "Add to AT-1"}
                                                    </button>
                                                    <button
                                                        className={`btn ${isAddedToAT2(questionItem) ? "added_test_bg" : "add_test_bg"} f14 px-3 py-1 me-3`}
                                                        onClick={() => handleAddToAT2(questionItem)}
                                                        disabled={isAddedToAT1(questionItem)}
                                                    >
                                                        {isAddedToAT2(questionItem) ? "Added to AT-2" : "Add to AT-2"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </MathJax>
                            </MathJaxContext>
                        </div>
                        <div className="text-end pe-4 pt-3 border-top position-relative">
                            {testSubmitLoading &&
                                <div className="text-center mt-1 position-absolute" style={{ right: "280px", bottom: "-4px" }}>
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only ">Loading...</span>
                                    </div>
                                </div>
                            }
                            <button className="btn btn-warning text-white f14 px-3  me-3 py-1" onClick={() => setShowPreview(true)}>Preview</button>
                            <button className="btn btn-primary f14  px-3 py-1" onClick={handleSubmitTests}>Create AT-1 & AT-2</button>
                        </div>
                    </>}
                {questionsList?.length == 0 && <h6 className="text-center mt-3">No Data Found</h6>}
            </div>
            }
        </div>
    )

}