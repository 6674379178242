import React, { useEffect, useState } from "react";
import { Breadcum } from "../../../../components/breadcum";
import { useHistory } from "react-router-dom";
import { GET_KARYAKARTA_LIST } from "../../../../app/service/content-module/karyakarta-service";
import { toast } from "react-toastify";

export const KaryakartaList = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [karyakartaList, setKarayakartaList] = useState<any>([]);
    const history = useHistory();

    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Content',
            value: 'Content',
            routerLink: '/dashboard/content/view',
            isActive: false
        },
        {
            label: 'karyakarta Analysis',
            value: 'karyakarta Analysis',
            routerLink: '',
            isActive: true
        }
    ]);

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        setLoading(true);
        try {
            const payLoad = {
                user_role: "TEACHER,PARENT,DATA_ENTRY_OPERATOR,SUBJECT_COORDINATOR,CONTENT_VALIDATOR,CONTENT_HEAD,SUBJECT_TEAM_LEAD,SUBJECT_HEAD,IAS_ADMIN,MANAGEMENT,SUPER_ADMIN"
            }
            const res: any = await GET_KARYAKARTA_LIST(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (res?.rows?.length > 0) {
                        setKarayakartaList(res?.rows);
                    }
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);

        }
    }

    const onClickList = (data: any) => {
        history.push(`/dashboard/content/karyakarta/info/${data?.user_code}/${capitalizeFirstLetter(data?.name)}/${data?.user_role}`);
    }
    const capitalizeFirstLetterOnly = (input: string): string => {
        return input
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    const capitalizeFirstLetter = (input: string): string => {
        return input
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    return (
        <div className=" border-top border-primary py-3">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <Breadcum breadcumList={breadcumInfo} textLowerCase={true} />
                    <h5 className="mt-1 text-center">Karyakarta List</h5>
                    <div className="row justify-content-center mt-4">
                        <div className="col-12 col-md-11">
                            <div className="custom_table_scroll">
                                <table className="content_karyakarta_list_table">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Sr. No</th>
                                            <th>Karyakarta Name</th>
                                            <th>Role</th>
                                            <th> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {karyakartaList?.map((data: any, i: number) => (
                                            <tr key={i}>
                                                <td className="text-center">{i + 1}.</td>
                                                <td className="f18 fw-bold"><u className="cursor-pointer" onClick={() => onClickList(data)}>{data?.name}</u></td>
                                                <td className="f18">{data?.user_role && capitalizeFirstLetterOnly(data?.user_role)}</td>
                                                <td className="f18">
                                                    {data?.actions?.length > 0 && <span>
                                                        {data?.actions?.map((item: any, j: number) => (
                                                            <span className={`text-white me-3 rounded-3   ${item === "content_upload" && "bg_btn_up"} ${item === "test_creation" && "cn_btn_test"} ${item === "worksheet_creation" && "cn_btn_wrk"} ${item === "content_validation" && "cn_btn_vald"} py-1 f12 px-3`} key={j}>
                                                                {item && capitalizeFirstLetterOnly(item)}</span>
                                                        ))}
                                                    </span>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}