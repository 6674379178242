import React, { useState } from "react";
import NestedContentWorksheetList from "../../../../routes/content-uploads/worksheet-nested-list";
import { NavLink, useLocation } from "react-router-dom";
import { Breadcum } from "../../../../components/breadcum";
export const ContentAbhyaasInfo = () => {
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Content',
            value: 'Content',
            routerLink: '/dashboard/content/view',
            isActive: false
        },
        {
            label: 'Worksheet Creation (ABHYAAS)',
            value: 'Worksheet Creation (ABHYAAS)',
            routerLink: '',
            isActive: true
        }
    ]);
    return (
        <div className="row border-top border-primary py-3">
            <Breadcum breadcumList={breadcumInfo} textLowerCase={true} />
            <ul className="nav nav-tabs custom-tabs scrolling_nav">
                <li className="nav-item">
                    <NavLink className="nav-link active" to={{ pathname: `/dashboard/content/Abhyaas/worksheet`, state: { data: "" } }}>Add Worksheet</NavLink>
                </li>
            </ul>
            <div>
                <NestedContentWorksheetList />
            </div>
        </div>
    )
}