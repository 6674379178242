import API from "../../utility/axios";
const apiUrl = "/api/v2";
export const GET_TEST_DATA = (data: any): Promise<any[]> => {
  return API.get(`${apiUrl}/abhyaas/pariksha`, { params: data });
};

export const CONTENT_TEST_UPDATE = (
  data: any,
  sheetUuid: string
): Promise<any> => {
  return API.put(`${apiUrl}/abhyaas/pariksha/test/${sheetUuid}`, data);
};
export const CONTENT_ASSESSMENT_TEST_CREATE = (data: any): Promise<any[]> => {
  return API.post(`${apiUrl}/assessment/tests`, data);
};
export const FETCH_CONTENT_ASSESSMENT_TESTS = (data: any): Promise<any[]> => {
  return API.get(`${apiUrl}/assessment/tests`, { params: data });
};
