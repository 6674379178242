import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormBuilder from '../../../../components/form-builder';
import { GET_SUBJECT_FILTER_LIST } from '../../../../app/service/common-module.service';
import { GET_CHAPTER_LIST_BY_SUBJECT } from '../../../../app/service/abhyas-chapter.service';
import { FETCH_PARIKSHA_USER_QUESTION_INFO, FETCH_PARIKSHA_USER_QUESTION_PAPER } from '../../../../app/service/pariksha-module-questions';
import STUDENTTAKETEST from "../../../../institute-dashboard-assets/images/students-taking-the-test.gif"

export const ParikshaTestLevelStatusView = () => {
    const [loading, setLoading] = useState(false);
    const [filterData, setFilterData] = useState<any>({});
    const [subjectList, setSubjectList] = useState<any>([]);
    const [subjectError, setSubjectError] = useState<boolean>(false);
    const history = useHistory();
    const [chapterError, setChapterError] = useState<Boolean>(false);
    const [chapterList, setChapterList] = useState<any>([]);
    const [testName, setTestName] = useState<string>("");
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [questionsInfo, setQuestionsInfo] = useState<any>({});
    const [testDataList, setTestDataList] = useState<any>([]);
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;
    const filterForm = useRef<any>({});
    const [chapterUuid, setChapterUuid] = useState<string>("");
    const [testTypeList, setTestTypelist] = useState<any>([]);
    let selectUuids = JSON.parse(localStorage.getItem('user_uuids_Info') || '{}');
    const [viewChapter, setVeiwChapter] = useState<boolean>(false);
    const [testError, setTestError] = useState<boolean>(false);
    const [testTypeInfo, setTestTypeInfo] = useState<any>(
        [{
            value: "CHAPTER_WISE_TEST",
            name: "CHAPTER WISE TEST"
        },
        {
            value: "UNIT_TEST_1",
            name: "UNIT TEST 1"
        },
        {
            value: "UNIT_TEST_2",
            name: "UNIT TEST 2"
        },
        {
            value: "SEMESTER_TEST",
            name: "SEMESTER TEST"
        },
        {
            value: "PRE_BOARD_TEST",
            name: "PRE BOARD TEST"
        }, {
            value: "FINAL_TEST",
            name: "FINAL TEST"
        },
        {
            value: "GROUP_TEST",
            name: "GROUP TEST"
        },


        ]
    )

    useEffect(() => {
        // window.scrollTo(0, 0);
        // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        localStorage.setItem("backword_key", "false");
        // getBoardData();
        getSubjectList(selectUuids?.standard_uuid);

    }, []);



    const getSubjectList = (standardUuid: any) => {
        setLoading(true);
        GET_SUBJECT_FILTER_LIST(standardUuid).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    setSubjectList(data);
                    setLoading(false);
                }
            }
        });
    }

    const onChangeSubject = (e: any) => {
        setVeiwChapter(false);
        setTestError(false);
        setChapterUuid("");
        setTestTypelist([]);
        filterData.exam_type = "";
        filterData.chapter_uuid = "";
        filterData.subject_uuid = ""
        if (e) {
            setSubjectError(false);
            filterData.subject_uuid = e
            getTestStatusList();
        }
    }
    const getTestStatusList = () => {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code,
            board_uuid: selectUuids?.board_uuid,
            medium_uuid: selectUuids?.medium_uuid,
            standard_uuid: selectUuids?.standard_uuid,
            subject_uuid: filterData.subject_uuid
        }
        // return
        FETCH_PARIKSHA_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
            } else {
                if (res) {
                    const data = res;
                    if (data && data?.tests) {
                        setTestDataList(data?.tests);
                        testTypeInfo?.map((item: any) => {
                            data?.tests?.forEach((element: any) => {
                                if (item.value === element.exam_type) {
                                    if (element.status === "pending") {
                                        item.status = "pending"
                                    } else {
                                        item.status = "completed"
                                    }
                                }
                            });
                        })
                        setTestTypelist(testTypeInfo);
                    } else {
                        setTestTypelist(testTypeInfo);

                    }

                }
            }
        });
    }

    const handleInput = (data: any) => {
        let preData = { ...data.value }
        setFilterData(preData);
    };
    const onSelectExamType = (e: any) => {
        setVeiwChapter(false);
        setTestError(false);
        setChapterUuid("");
        filterData.chapter_uuid = "";
        filterData.exam_type = "";
        if (e) {
            filterData.exam_type = e;
        }
        filterData.chapter_uuid = "";
        localStorage.setItem("exam_type", e);
        setTestName(e)
        if (e === "CHAPTER_WISE_TEST") {
            setVeiwChapter(true);
            setChapterError(false);
            const payLoad = {
                board_uuid: selectUuids?.board_uuid,
                medium_uuid: selectUuids?.medium_uuid,
                standard_uuid: selectUuids?.standard_uuid,
                subject_uuid: filterData.subject_uuid
            }
            GET_CHAPTER_LIST_BY_SUBJECT(payLoad).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.err);
                        setLoading(false);

                    } else {
                        const data = res['rows'];
                        data?.map((item: any) => {
                            testDataList?.filter((element: any) => {
                                if (element?.exam_type === "CHAPTER_WISE_TEST" && element?.status === "pending") {
                                    if (item?.uuid === element?.chapter_uuid) {

                                        item.status = "pending"
                                    }
                                    // else {
                                    //     item.status = "completed"
                                    // }
                                }
                            });
                        })

                        data.sort((a: any, b: any) => a.name.localeCompare(b.name));
                        let sortedArray = [...data].sort((item1, item2) => {
                            const num1 = extractNumericValue(item1.name);
                            const num2 = extractNumericValue(item2.name);
                            return num1 - num2;
                        });
                        setChapterList(sortedArray);
                    }
                }
            });
        }
    }

    const extractNumericValue = (name: string) => {
        const matches = name.match(/\d+/);
        return matches ? parseInt(matches[0], 10) : Infinity;
    };
    const onSelectChapter = (e: any) => {
        setChapterError(false);
        setChapterUuid("");
        filterData.chapter_uuid = ""
        if (e) {
            filterData.chapter_uuid = e
            setChapterUuid(e);

        }
    }
    function onClickEntryTest() {

        localStorage.setItem('pariksha_type', 'TESTS');
        const transformedString = testName
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
        if ((filterData?.exam_type === "CHAPTER_WISE_TEST" && chapterUuid) || (filterData?.exam_type !== "CHAPTER_WISE_TEST" && filterData?.exam_type)) {
            const testInfo = {
                exam_type: filterData?.exam_type,
                chapter_uuid: filterData?.exam_type === "CHAPTER_WISE_TEST" ? chapterUuid : "",
                test_name: transformedString,
                board_uuid: selectUuids?.board_uuid,
                medium_uuid: selectUuids?.medium_uuid,
                standard_uuid: selectUuids?.standard_uuid,
                subject_uuid: filterData?.subject_uuid
            }
            localStorage.setItem('userSelectedSubjectInfo', JSON.stringify(testInfo));
            localStorage.setItem('pariksha_test_info', JSON.stringify(testInfo));
            // return
            questionsDetails(testInfo);
            // history.push(`/student-dashboard/list/pariksha/questions/${modules_uuid}/${user_code}/${school_code}/${filterData?.subject_uuid}`);
        }
        else {
            if (!filterData?.exam_type) {
                setTestError(true);
            }
            if (!filterData?.exam_type) {
                setTestError(true);
            }
            if (!filterData.subject_uuid) {
                setSubjectError(true);

            }
            if (filterData?.exam_type === "CHAPTER_WISE_TEST" && !chapterUuid) {
                setChapterError(true);

            }
        }
    }

    const onClickSave = () => {
        history.push(`/student-dashboard/list/pariksha/questions/${modules_uuid}/${user_code}/${school_code}/${filterData?.subject_uuid}`);
    }

    const onClickCancel = () => {
        setCanShowPopup(false);
    }
    const questionsDetails = (data: any) => {
        let payload = {
            "exam_type": data?.exam_type,
            "board_uuid": data?.board_uuid,
            "medium_uuid": data?.medium_uuid,
            "standard_uuid": data?.standard_uuid,
            "subject_uuid": data?.subject_uuid,
            "chapter_uuid": data?.chapter_uuid ? data?.chapter_uuid : "",
            "sorting_type": "ASC",

        }

        FETCH_PARIKSHA_USER_QUESTION_PAPER(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setLoading(false);
                } else {
                    if (res?.data?.length > 0) {
                        localStorage.setItem("pariksha_question_response", JSON.stringify(res))
                        setQuestionsInfo(res);
                        setCanShowPopup(true);
                    } else {
                        toast.error(`There are no questions in this  ${testName
                            .split('_')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ')} `);
                    }
                }
            }
        })
    }
    const getSubjectName = (sujectUuid: any) => {
        if (sujectUuid) {
            let preData = subjectList?.find((ele: any) => ele.uuid === sujectUuid)
            return preData?.name;
        }
    }


    return (
        <div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog pariksha-model-dialog" role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body  pariksha-model-info">
                            <div className='pariksha-model-border ba bas-dashed '>
                                <div className='test-header '>
                                    <h5>Test Instructions</h5>
                                </div>
                                <ol className='ab_custom_list '>
                                    <li>Number of questions : {questionsInfo?.data?.length}</li>
                                    <li>Time limit to solve test : {questionsInfo?.time} Minutes</li>
                                    <li>Result will be genarated immediately after submission of the test.
                                    </li>
                                    <li> 50% Score compulsory to pass the test</li>
                                </ol>

                                <div className='start-test mt-4 justify-content-center'>
                                    <h6>Do you want to start {getSubjectName(filterData?.subject_uuid)?.toUpperCase()} {testName
                                        .split('_')
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                        .join(' ')} ? </h6>
                                </div>
                                <div className='mt-4'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        <button className='btn test-btn' onClick={onClickSave}>YES</button>
                                        <button className='btn test-btn' onClick={onClickCancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/student-dashboard/list/pariksha/test-initiative/${moduleUUID}/${userCode}/${schoolCode}`}><span className='pe-2'>Pariksha</span></Link></li> /

                            <li className="breadcrumb-item "><span>Start Pariksha</span></li>
                        </ol>
                    </nav>
                    {/* <h5 className='text-center'>Name : <u>{studentInfo?.name?.charAt(0).toUpperCase() + studentInfo?.name?.slice(1)}</u></h5> */}
                    <h5 className='text-center'>Do your best. All the best!</h5>

                    <div className='row mt-4  justify-content-center '>
                        <div className='col-lg-5 col-12 text-lg-start text-center'>
                            <div className=''>
                                <img src={STUDENTTAKETEST} className="task_list_img" alt="task list" ></img>
                            </div>
                        </div>
                        <div className='col-lg-5  col-12   p-3 mb-5 bg-white rounded'>
                            <FormBuilder onUpdate={handleInput}>
                                <form ref={filterForm}>
                                    <div className='row pariksha-multi-info mt-4'>
                                        <div className="col-12">
                                            <div className=' position-relative'>
                                                <select name="subject_uuid" id="subject_uuid" className="form-select p-form-select-border f18 mt-4 cursor-pointer" onChange={(e) => { onChangeSubject(e.target.value) }}>
                                                    <option value="" className='bg-white '>Select Subject</option>
                                                    {subjectList?.map((name: any, i: number) => (
                                                        <option key={i} value={name?.uuid} className='bg-white '>
                                                            {name?.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {subjectError && <p className='f14 text-danger mb-1 text-center'>Please Select Subject</p>}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="my-4">
                                                <select name="exam_type" id="exam_type" className="form-select p-form-select-border f18 cursor-pointer" onChange={(e) => { onSelectExamType(e.target.value) }}  >
                                                    <option value="">Select Test Type</option>
                                                    {testTypeList?.map((name: any, i: number) => (
                                                        <option key={i} value={name?.value} className='bg-white' disabled={(name?.status === "pending" && name?.value !== "CHAPTER_WISE_TEST") ? true : false}>
                                                            {name?.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {testError && <p className='f14 text-danger mb-1 text-center'>Please Select Test</p>}

                                            </div>
                                        </div>
                                        {viewChapter && <div className="col-12">
                                            <div className="mb-4">
                                                <select name="chapter_uuid" id="chapter_uuid" className="form-select p-form-select-border f18 cursor-pointer" onChange={(e) => { onSelectChapter(e.target.value) }}>
                                                    <option value="">Select Chapter</option>
                                                    {chapterList?.map((name: any, i: number) => (
                                                        <option key={i} value={name?.uuid} disabled={name?.status === "pending" ? true : false}>
                                                            {name?.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {chapterError && <p className='f14 text-danger mb-1 text-center'>Please select chapter</p>}
                                            </div>
                                        </div>}
                                        <div className="col-12 text-center mt-1">
                                            <button type="button" className="btn btn-primary px-5  border-dark" onClick={onClickEntryTest}>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </FormBuilder>
                        </div>

                    </div>
                </div>
            }
        </div>
    )
}