import React, { useEffect, useRef, useState } from 'react'
import EYEICONS from '../../../assets/images/eye-icons.svg'
import { ToastContainer, toast } from 'react-toastify';
import { GET_SCHOOL_DASHBOARD } from '../../../app/service/school-dashboard.service';
import { Link, useHistory, useParams } from 'react-router-dom';
import VIEWICONS from '../../../institute-dashboard-assets/images/view-icons.svg'

function SchoolDashboard() {
    const userSchoolName = localStorage.getItem('school_name')
    const userRole = localStorage.getItem("user_role");
    const SchoolNames = localStorage.getItem("name_of_school");
    const [loading, setLoading] = useState(false);
    const [showSection, setShowSection] = useState(false);
    const [activePage, setActivePage] = React.useState(1);
    const [activeTab, setActiveTab] = React.useState(0);
    const [schoolData, setSchoolData] = useState<any>([]);
    const [mathsData, setMathsData] = useState<any>([]);
    const [casAspetctData, setCasAspectData] = useState<any>([]);
    const [sectionWise, setSectionWise] = useState<any>([]);
    const [classWise, setClassWise] = useState<any>([]);
    const [casInitiatives, setCasInitiatives] = useState<any>([]);
    const naviGation: any = localStorage.getItem("navigation");
    const history = useHistory();
    const [activeStudentValues, setActiveStudentValues] = useState(false);
    const [registerdValues, setRegisterdValues] = useState(false);
    const registerednotificationref = useRef<any>(null);
    const [casStudentValues, setCasStudentValues] = useState(false);
    const [mathsStudentValues, setMathsStudentValues] = useState(false);

    useEffect(() => {
        schoolDashboardDetails();
    }, []);
    const schoolDashboardDetails = () => {
        const data: any = localStorage.getItem("institute_code");
        setLoading(true);
        GET_SCHOOL_DASHBOARD(data).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    if (res) {
                        const data = res;
                        let classWiseCasData: any[] = [];
                        if (data?.cas_data?.average_results) {
                            Object.keys(data?.cas_data?.average_results).map((x: any, i: number) => {
                                let aspectData: any[] = [];
                                for (let j = 0; j < data?.cas_data?.class_wise_data?.length; j++) {
                                    let element = data?.cas_data?.class_wise_data[j]?.average_results;
                                    aspectData.push(element[x] ? element[x] : 0);
                                }
                                classWiseCasData.push({
                                    name: x,
                                    class_data: aspectData
                                })
                            });
                        }
                        setCasAspectData(classWiseCasData);
                        setSchoolData(data);
                        setMathsData(data.maths_data);
                        setCasInitiatives(data.cas_data.section_wise_data);
                        setClassWise(data.cas_data.class_wise_data);

                    }
                    setLoading(false);
                }
            }
        });
    }

    const onSelectTab = (type: number) => {
        setActivePage(type)
        if (type === 2) {
            setActiveTab(1);
        }

    }

    const subMathsTab = (type: number) => {
        let tab = type
        setActiveTab(tab)
    }


    function sectionData(data: any, index: number) {

        const preparedData = casInitiatives.filter((ele: any) => ele.class === data.class);
        const classWiseData = classWise;
        classWiseData.map((x: any, i: number) => {
            if (i === index) {
                x.checked = !x.checked;
            } else {
                x.checked = false;
            }
            return x;
        })

        setClassWise(classWiseData);
        preparedData.sort((a: any, b: any) => a.section.localeCompare(b.section));
        setSectionWise(preparedData);
        setShowSection(!showSection);
    }
    const openClassWiseCas = (data: any) => {
        if (userRole === "SUPER_ADMIN") {
            history.push(`/dashboard/view/cas-analysys/classwise/${data}`)
        } else {
            history.push(`/school-dashboard/view/cas-analysys/classwise/${data}`)
        }
    }
    const openClassWiseMaths = (data: any) => {
        if (userRole === "SUPER_ADMIN") {
            history.push(`/dashboard/view/maths-analysys/classwise/${data}`)
        } else {
            history.push(`/school-dashboard/view/maths-analysys/classwise/${data}`)
        }
    }

    const mathsDatas = (data: any, user_key: any, compare_key: any, type_search: any) => {

        if (mathsData && data) {
            if (type_search === 'class_wise') {
                const data1 = mathsData?.class_wise_data?.find((ele: any) => ele[compare_key] === data[compare_key]);
                if (data1) {
                    return data1[user_key];
                }
                return 0;

            } else if (type_search === 'section_wise') {
                const data1 = mathsData?.section_wise_data?.find((ele: any) => ele[compare_key] === data[compare_key]);
                if (data1) {
                    return data1[user_key];
                }
                return 0;
            }
        }
    }
    const naviGations = (urls: string) => {
        if (urls === "registered-students") {
            if (userRole === "SUPER_ADMIN") {
                history.push('/dashboard/view/registered-studentslist/list')
            } else {
                history.push('/school-dashboard/view/registered-studentslist/list')
            }
        } else if (urls === "active-students") {
            if (userRole === "SUPER_ADMIN") {
                history.push('/dashboard/view/active-studentslist/list')
            } else {
                history.push('/school-dashboard/view/active-studentslist/list')
            }
        } else if (urls === "cas-analysys") {
            if (userRole === "SUPER_ADMIN") {
                history.push('/dashboard/view/cas-analysys/view')
            } else {
                history.push('/school-dashboard/view/cas-analysys/view')
            }
        } else if (urls === "maths-analysys") {
            if (userRole === "SUPER_ADMIN") {
                history.push('/dashboard/view/maths-analysys/view')
            } else {
                history.push('/school-dashboard/view/maths-analysys/view')
            }
        }
    }

    function kFormatter(num: any) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function (item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(0).replace(rx, "$1") + item.symbol : "0";

    }
    const showRegisteredValues = () => {
        setRegisterdValues(true)

    }
    const showActiveStudentValues = () => {
        setActiveStudentValues(true)

    }
    const showCasStudentValues = () => {
        setCasStudentValues(true);
    }
    const showMathStudentValues = () => {
        setMathsStudentValues(true);
    }
    return (
        <>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb border-bottom ps-0 pt-0 pb-2" style={{ letterSpacing: "0" }}>
                            {(userRole === "SUPER_ADMIN" && naviGation === "institutes") && <li className="breadcrumb-item mt-3"> <Link to='/dashboard/view/registered-schools' style={{ color: '#0d6efd' }}>{SchoolNames} </Link> / <span>Dashboard</span></li>}
                            {(userRole === "SUPER_ADMIN" && naviGation === "schools") && <li className="breadcrumb-item mt-3"> <Link to='/dashboard/view/schoolslist' style={{ color: '#0d6efd' }}>{SchoolNames} </Link> / <span>Dashboard</span></li>}
                            {userRole !== "SUPER_ADMIN" && userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <li className="breadcrumb-item"><span className='active'>{userSchoolName}</span> / <span>Dashboard</span></li>}
                            {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <li className="breadcrumb-item"> <Link to='/institute-dashboard/dashboard/registered-schools' style={{ color: '#0d6efd' }} ><span>{SchoolNames}</span> </Link> / <span>Dashboard</span></li>}
                        </ol>
                    </nav>
                    <div className='row'>
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block h-100" onClick={() => naviGations("registered-students")}>
                            <span className="dkpi-block-content dkpi-blue-bg cursor_pointer institute-registered-schools-bg-one " data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <h3 className="dkpi-hd mb-0">Registered Students <small>Registered Students / Number of students</small><small>&nbsp;</small></h3>
                                <p className="mt-1 mb-0 dkpi-num" onMouseLeave={() => setRegisterdValues(false)} onMouseEnter={showRegisteredValues}>{kFormatter(schoolData?.total_students)}/{kFormatter(schoolData?.total_students)}</p>
                                <div className='position-absolute' style={{
                                    bottom: "3px"
                                }}>
                                    {(registerdValues && schoolData?.total_students > 999) &&
                                        <p className='m-0 f14' onMouseEnter={showRegisteredValues} ref={registerednotificationref} onMouseLeave={() => setRegisterdValues(false)} > {schoolData?.total_students}/{schoolData?.total_students}</p>
                                    }
                                </div>
                            </span> </div>
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block" onClick={() => naviGations("active-students")}>
                            <span className="dkpi-block-content cursor_pointer institute-registered-schools-bg-three h-100">
                                <h3 className="dkpi-hd">Active/Inactive Students<small>&nbsp;</small></h3>
                                <p className="mt-1 mb-0 dkpi-num mt-3" onMouseLeave={() => setActiveStudentValues(false)} onMouseEnter={showActiveStudentValues}>{kFormatter(schoolData?.active_students)}/{kFormatter(schoolData?.inactive_students)}</p>
                                <div className='position-absolute' style={{
                                    bottom: "3px"
                                }}>
                                    {(activeStudentValues && (schoolData?.active_students > 999 || schoolData?.inactive_students > 999)) &&
                                        <p className='m-0 f14' onMouseEnter={showActiveStudentValues} ref={registerednotificationref} onMouseLeave={() => setActiveStudentValues(false)} > {schoolData?.active_students}/{schoolData?.inactive_students}</p>
                                    }
                                </div>
                            </span> </div>
                        {/* <div className="col-md-6 col-lg-3 mb-4 dkpi-block " onClick={() => naviGations("cas-analysys")}>
                            <span className="dkpi-block-content cursor_pointer institute-registered-schools-bg-two h-100" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <h3 className="dkpi-hd mb-0">CAS Analysis <small> Registered Students</small><small>&nbsp;</small></h3>

                                <p className="mt-1 mb-0 dkpi-num" onMouseLeave={() => setCasStudentValues(false)} onMouseEnter={showCasStudentValues}>{kFormatter(schoolData?.cas_registered_students)}

                                </p>
                                <div className='position-absolute' style={{
                                    bottom: "3px"
                                }}>
                                    {(casStudentValues && schoolData?.cas_registered_students > 999) &&
                                        <p className='m-0 f14' onMouseEnter={showCasStudentValues} ref={registerednotificationref} onMouseLeave={() => setCasStudentValues(false)} > {schoolData?.cas_registered_students}</p>
                                    }
                                </div>
                            </span> </div> */}
                        <div className="col-md-6 col-lg-3 mb-4 dkpi-block " onClick={() => naviGations("maths-analysys")}>
                            <span className="dkpi-block-content cursor_pointer institute-registered-schools-bg-four h-100" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <h3 className="dkpi-hd mb-0">Maths Analysis <small>Registered Students</small><small>&nbsp;</small></h3>

                                <p className="mt-1 mb-0 dkpi-num" onMouseLeave={() => setMathsStudentValues(false)} onMouseEnter={showMathStudentValues}>{kFormatter(schoolData?.maths_registered_students)}</p>
                                <div className='position-absolute' style={{
                                    bottom: "3px"
                                }}>
                                    {(mathsStudentValues && schoolData?.maths_registered_students > 999) &&
                                        <p className='m-0 f14' onMouseEnter={showMathStudentValues} ref={registerednotificationref} onMouseLeave={() => setMathsStudentValues(false)} > {schoolData?.maths_registered_students}</p>
                                    }
                                </div>
                            </span></div>
                    </div>
                    <h3 className='mt-4 mb-0 section-hd'>Students Registered in each class</h3>
                    <p className='text-gray f12 mb-2'>Class Wise/Section wise</p>
                    <div className='table-responsive'>
                        <table className='table table_new_ui'>
                            <thead>
                                <tr>
                                    <th style={{ width: "120px" }}>Class/Section</th>
                                    <th style={{ width: '120px' }}>Registered No/Total No</th>
                                    {/* <th style={{ width: '100px' }}> CAS Registered </th> */}
                                    <th style={{ width: '100px' }}> Maths Registered </th>
                                    {/* <th style={{ width: "100px" }}>CAS Attempted</th> */}
                                    {/* <th style={{ width: '100px' }}>CAS</th> */}
                                    <th style={{ width: '100px' }}>Maths</th>
                                </tr>
                            </thead>
                            <tbody >
                                {schoolData?.cas_data && classWise.map((data: any, index: number) => {
                                    return (
                                        <>

                                            <tr key={index} >

                                                <td><span className='text-primary cursor-pointer' onClick={() => sectionData(data, index)}>{data.class}<sup>th</sup> <small className={`ms-2 cursor-pointer  ${data.checked ? "arrow_ups" : "arrow_downs"}`}></small></span> </td>

                                                <td>{data.total_students} / {data.total_students}</td>

                                                {/* <td>{data.registered_students}</td> */}

                                                <td>{mathsDatas(data, 'registered_students', 'class', 'class_wise')}</td>

                                                {/* <td>{data.attempted_students}</td> */}

                                                {/* <td>
                                                    <img className='eye-icons ms-1 cursor-pointer' src={VIEWICONS} onClick={() => openClassWiseCas(data.class)} />
                                                </td> */}

                                                <td><img className='eye-icons ms-2 cursor-pointer' src={VIEWICONS} onClick={() => openClassWiseMaths(data.class)} /></td>

                                            </tr>

                                            {data.checked && sectionWise.map((item: any, i: number) => {
                                                return (
                                                    <tr key={i} style={{ background: "#f4f4f5" }}>
                                                        <td>{item.section}</td>

                                                        <td>{item.total_students} / {item.total_students}</td>

                                                        {/* <td>{item.registered_students}</td> */}

                                                        <td>{mathsDatas(item, 'registered_students', 'class_section', 'section_wise')}</td>

                                                        {/* <td>{item.attempted_students}</td> */}

                                                        {/* <td> */}

                                                        {/* </td> */}

                                                        <td>

                                                        </td>

                                                    </tr>)
                                            })}
                                        </>)
                                })}
                            </tbody>
                        </table>
                    </div>
                    {(schoolData?.class_wise_data && schoolData?.class_wise_data.length === 0) && <div className="text-center mb-3 f14">No Data Available</div>}

                </div>
            }
        </>
    )
}
export default SchoolDashboard;
