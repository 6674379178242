import API from "../../utility/axios";
const apiUrl = "/api/v1";
const apiNewUrl = "/api/v2";

export const GET_KARYAKARTA_LIST = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/msb/user`, { params: data });
};
export const GET_KARYAKARTA_QUESTIONS_LIST = (data: any): Promise<any> => {
  return API.get(`${apiNewUrl}/questions`, { params: data });
};
export const GET_KARYAKARTA_TEST_LIST = (data: any): Promise<any> => {
  return API.get(`${apiNewUrl}/abhyaas/pariksha`, { params: data });
};
