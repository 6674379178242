import React, { useEffect, useState } from "react";

interface VideoSelectorProps {
    videoList: { question: string; question_info: string }[];
    onSelect: (video: string) => void;
    videoSrc: string;
}

const VideoSelector: React.FC<VideoSelectorProps> = ({ videoList, onSelect, videoSrc }) => {
    const [selectVideo, setSelectVideo] = useState<string>(videoSrc ? videoSrc : "");
    const [customVideo, setCustomVideo] = useState<boolean>(false);

    // Effect to update `selectVideo` when `videoSrc` changes
    useEffect(() => {
        setSelectVideo(videoSrc ? videoSrc : "");
        setCustomVideo(!videoList?.some((video) => video?.question_info === videoSrc));
    }, [videoSrc, videoList]);

    // Handles selection change (Dropdown & Input)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        if (value === "custom") {
            setCustomVideo(true);
            setSelectVideo("");
            onSelect("");
        } else {
            setCustomVideo(false);
            setSelectVideo(value);
            onSelect(value);
        }
    };

    const handleCustomInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectVideo(e.target.value);
        onSelect(e.target.value);
    };

    const handleLinkToggle = () => {
        setCustomVideo(false);
        setSelectVideo("");
        onSelect("");
    };

    return (
        <div className="form-group position-relative">
            {/* Toggle button when in custom input mode */}
            {customVideo && (
                <i className="bi bi-chevron-down drop_video px-1 cursor-pointer" onClick={handleLinkToggle}></i>
            )}

            {/* Video Input or Dropdown */}
            {customVideo ? (
                <input
                    type="text"
                    value={selectVideo}
                    onChange={handleCustomInputChange}
                    className="form-control"
                    placeholder="Enter custom link"
                />
            ) : (
                <select
                    name="video"
                    id="video"
                    className="form-select ft-14"
                    value={selectVideo}
                    onChange={handleChange}
                >
                    <option value="" disabled>
                        Select or enter link
                    </option>
                    {videoList?.map((video, i) => (
                        <option key={i} value={video.question_info}>
                            {video.question}
                        </option>
                    ))}
                    <option value="custom">Enter custom link</option>
                </select>
            )}
        </div>
    );
};

export default VideoSelector;
