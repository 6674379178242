import React from "react";

interface VideoPlayerProps {
    videoSrc: string;
    customClass?: string;
}

const CustomVideoPlayer: React.FC<VideoPlayerProps> = ({ videoSrc, customClass }) => {
    // Convert YouTube link to an embed URL
    const getEmbedUrl = (url: string): string => {
        if (!url) return "";
        if (url.includes("youtube.com/watch")) {
            return url.replace("watch?v=", "embed/");
        } else if (url.includes("youtu.be/")) {
            return url.replace("youtu.be/", "youtube.com/embed/");
        }
        return url; // For normal video links
    };

    const embedUrl = getEmbedUrl(videoSrc);

    return (
        <>
            {embedUrl.includes("youtube.com/embed") ? (
                // Use corrected embed URL
                <iframe
                    src={embedUrl} // ✅ Fixed: Use embedUrl
                    className={`${customClass ? customClass : "w-25 "} rounded-3`}
                    allow="encrypted-media"
                    allowFullScreen
                ></iframe>
            ) : (
                // Handle normal video file
                <video
                    src={embedUrl}
                    className={`${customClass ? customClass : "w-25 "} rounded-3`}
                    controls controlsList="nodownload"
                ></video>
            )}
        </>
    );
};

export default CustomVideoPlayer;
