import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "../../../../components/data-table";
import { FormControlError, FormField, FormValidators } from "../../../../components/form-builder/model/form-field";
import { toast } from "react-toastify";
import { FormValidator } from "../../../../components/form-builder/validations";
import FormBuilder from "../../../../components/form-builder";
import moment from "moment";
import { DELETE_WORKSHEET, GET_WORKSHEET_BY_UUID, UPDATE_WORKSHEET } from "../../../../app/service/abhyas-worksheet.service";
import { GET_PARIKSHA_TEST_LIST } from "../../../../app/service/pariksha-test.service";
import { ContentParikshaTestDataGridCols } from "./data-grid-cols";
import ContentPreviewQuestions from "../preview-popup";
import { CREATE_PARIKSHA_TESTS } from "../../../../app/service/anhyas-test.service";
import { GET_CONTENT_RANDOM_QUESTIONS } from "../../../../app/service/content-module/question-service";
import { GET_TEST_DATA } from "../../../../app/service/content-module/test-service";

interface Props {
    isLinked?: string;
    updateList?: (e: any) => void;
    selectTest?: ((e: string) => void);
    filterData?: any;
    isLoading?: boolean;
    chapterList?: any
    setTestType: any;
    setCanShowTestQuestions: any;
    setCreateTestData: any;
}
export const ContentParikshaTestDataList: React.FC<Props> = (props: Props) => {
    const [filterData, setFilterData] = useState<any>(props.filterData);
    const [testList, setTestList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [testUuid, setTestUuid] = useState<string>("0");
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [currentFormData, setCurrentFormData] = useState<any>({});
    const [formData, setFormData] = useState<any>({});
    const [formValidationErrors, setFormValidationErrors] = useState<FormControlError[]>([]);
    const medimForm = useRef<any>({});
    const [showQuestionPopUp, setShowQuestionPopUp] = useState<boolean>(false);
    const [questionsList, setQuestionsList] = useState<any>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [numberError, setNumberError] = useState<string>("");
    const [testSetNumberError, setTestSetNumberError] = useState<string>("");
    const [timeError, setTimeError] = useState<string>("");
    const [questionError, setQuestionError] = useState<string>("");
    const [testResponseOfAI, setTestResponseOfAI] = useState<any>({});
    const [testOptions, setTestOptions] = useState<boolean>(false);
    const [testByAI, setTestByAI] = useState<boolean>(false);
    const [canShowAIPopup, setCanShowAIPopup] = useState<boolean>(false);
    const userCode = localStorage.getItem("user_code");
    const [noQuestions, setNoQuestions] = useState<any>("");
    const [testTime, setTestTime] = useState<any>("");
    const FormValidations = [
        new FormField('type', [FormValidators.REQUIRED]),
        new FormField('level', [FormValidators.REQUIRED]),
    ];


    useEffect(() => {
        getParikshaTestData();

    }, [])


    const getParikshaTestData = async () => {
        const payLoad = {
            "board_uuid": filterData?.board_uuid,
            "medium_uuid": filterData?.medium_uuid,
            "standard_uuid": filterData?.standard_uuid,
            "subject_uuid": filterData?.subject_uuid,
            "chapter_uuid": filterData?.exam_type === "CHAPTER_WISE_TEST" ? filterData?.chapter_uuid : "",
            "topic_uuid": filterData?.topic_uuid,
            "exam_type": filterData?.exam_type,
        }
        try {
            setLoading(true);
            const res: any = await GET_PARIKSHA_TEST_LIST(activePage == 0 ? activePage : activePage - 1, "TESTS", payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res?.rows;
                    data?.forEach((element: any) => {
                        element.exam_type = element?.exam_type?.replace(/_/g, ' ');
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setTestList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        } catch (error) {

        }
    }


    const onPageChange = (data: any) => {
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_PARIKSHA_TEST_LIST(pageNumber, "TESTS", props.filterData).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.exam_type = element?.exam_type?.replace(/_/g, ' ');
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setTestList(data);
                    setLoading(false);
                }
            }
        });
    }
    const onDelete = (data: any) => {
        setLoading(true);
        const id = data.uuid;
        DELETE_WORKSHEET(id).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success(res.message);
                getParikshaTestData();
                // getWorksheetData();
            }
        });

    }
    const onEdit = async (data: any) => {
        setTestByAI(false);
        setTestUuid(data?.uuid);
        setFormLoading(false);
        setNumberError("");
        setTestSetNumberError("");
        setQuestionError("");
        setTimeError("");
        setCanShowPopup(true);
        if (data?.uuid) {
            try {
                setFormLoading(true);
                const res: any = await GET_WORKSHEET_BY_UUID(data?.uuid);
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    if (res?.rows?.length > 0) {
                        setNoQuestions(res?.rows[0]?.questions);
                        setTestTime(res?.rows[0]?.time);
                        setCurrentFormData(res?.rows[0])
                    }
                }
                setFormLoading(false);
            } catch (error) {
                setFormLoading(false);

            }

        }
    }
    const handleClose = () => {
        setCanShowPopup(false);
        setShowQuestionPopUp(false);
        getParikshaTestData();
        if (props.filterData && props.updateList) {
            props.updateList(props.filterData);
        }
    }

    const addTest = (data: any) => {
        setTestByAI(false);
        setNumberError("");
        setTestSetNumberError("");
        setQuestionError("");
        setTimeError("");
        setFormLoading(false);
        setCanShowPopup(true);
        setTestUuid(data);
        const datafrom = {
            name: '',
            time: "",
            questions: "",
            number: ''
        };
        setFormData({ ...datafrom });
        setCurrentFormData({ ...datafrom });
        medimForm?.current.reset();

    }


    const handleFormInput = (data: any) => {
        data.value = { ...currentFormData, ...data.value };
        setFormData(data);
        const errors: any = FormValidator(FormValidations, data.value);
        setFormValidationErrors(errors);
    };

    const createTest = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        setFormValidationErrors(errors);
        Object.keys(formsData).forEach(function (key) {
            if (typeof formsData[key] === 'string') {
                formsData[key] = formsData[key].trim();
            }
        });
        const payLoad = {
            board_uuid: filterData?.board_uuid,
            medium_uuid: filterData?.medium_uuid,
            standard_uuid: filterData?.standard_uuid,
            subject_uuid: filterData?.subject_uuid,
            chapter_uuid: filterData?.exam_type === "CHAPTER_WISE_TEST" ? filterData?.chapter_uuid : '',
            exam_type: filterData?.exam_type,
            topic_uuid: "",
            uuid: "",
            mode: "PARIKSHA",
            type: "TESTS",
            level: "",
            number: formsData?.number ? formsData?.number : '',
            name: formsData?.name ? formsData?.name : '',
            time: Number(testTime),
            questions: Number(noQuestions),
            set_number: formsData?.set_number,
            created_by: userCode
        }
        if (filterData?.board_uuid && filterData?.medium_uuid && filterData?.standard_uuid && filterData?.subject_uuid
            && filterData?.exam_type && formsData?.questions && formsData?.time
            && (!numberError && formsData?.number) && !questionError && !timeError) {
            if ((filterData?.exam_type === "CHAPTER_WISE_TEST" && filterData?.chapter_uuid) || filterData?.exam_type !== "CHAPTER_WISE_TEST") {

                setFormLoading(true);
                props?.setTestType("create");
                props?.setCreateTestData(payLoad);
                setTimeout(() => {
                    props.setCanShowTestQuestions(true);
                }, 600);
                setFormLoading(false);
            }
        } else {
            if (!formsData?.questions) {
                setQuestionError("Please enter no of questions in test");
            }
            if (!formsData?.time) {
                setTimeError("Please enter test time");
            }
        }
    }
    const updateTest = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        const payLoad = {
            uuid: testUuid,
            board_uuid: formsData.board_uuid,
            medium_uuid: formsData.medium_uuid,
            standard_uuid: formsData.standard_uuid,
            subject_uuid: formsData?.subject_uuid,
            chapter_uuid: formsData.exam_type === "CHAPTER_WISE_TEST" ? formsData?.chapter_uuid : '',
            topic_uuid: "",
            mode: "PARIKSHA",
            type: "TESTS",
            exam_type: formsData.exam_type,
            level: " ",
            number: formsData?.number ? formsData?.number : '',
            name: formsData?.name ? formsData?.name : '',
            time: formsData?.time ? formsData?.time : 60,
            questions: formsData?.questions ? formsData?.questions : 30,
            set_number: formsData?.set_number
        }
        setFormValidationErrors(errors);
        if (formsData.board_uuid && formsData.medium_uuid && formsData.standard_uuid && formsData.subject_uuid && formsData?.questions && formsData?.time
            && formsData.exam_type && (!numberError && formsData?.number) && !questionError && !timeError) {
            if ((formsData.exam_type === "CHAPTER_WISE_TEST" && formsData.chapter_uuid) || formsData.exam_type !== "CHAPTER_WISE_TEST") {
                setFormLoading(true);
                UPDATE_WORKSHEET(payLoad).then((res: any) => {
                    if (res.status === 'fail') {
                        const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                        toast.error(errorValue);
                        setFormLoading(false);
                    } else {
                        toast.success(res.message);
                        // getWorksheetData();
                        getParikshaTestData();
                        setFormLoading(false);
                        setCanShowPopup(false);
                        setFormData({});
                        setCurrentFormData({});
                        if (payLoad && props.updateList) {
                            props.updateList(props.filterData);
                        }
                    }
                });
            }

        } else {
            if (!formsData?.questions) {
                setQuestionError("Please enter no of questions in test");
            }
            if (!formsData?.time) {
                setTimeError("Please enter test time");
            }
            if (!formsData?.number) {
                setNumberError("Please enter test number");

            } else {
                setNumberError(numberError);
            }
        }
    }

    const onActions = (data: any) => {
        if (data.type === "link") {
            if (data.data.uuid && props.selectTest) {
                props.selectTest(data.data.uuid);
            }
        }
    };

    const viewQuestion = (data: any) => {
        setQuestionsList(data?.questions_data);
        setTestByAI(false);
        setShowQuestionPopUp(true);
    }

    const handleTestTime = (value: any) => {
        const data = { ...formData.value };
        if (value) {
            data.time = value;
            const re = /^[0-9\b]+$/;
            if (re.test(value)) {
                if (value.length <= 7) {
                    setTimeError("");
                    setTestTime(value);

                } else {
                    setTimeError("Upto 7 digits only")
                }
            }
            else {
                setTimeError("Please enter only numbers ")
            }
            if (currentFormData) {
                currentFormData.time = value;
            }
        } else {
            setTimeError("");
            if (currentFormData) {
                currentFormData.time = ""
            }
            data.time = "";

        }
    }
    const handleTestQuestion = (value: any) => {
        const data = { ...formData.value };
        if (value) {
            data.questions = value;
            const re = /^[0-9\b]+$/;
            if (re.test(value)) {
                if (value.length <= 7) {
                    setQuestionError("");
                    setNoQuestions(value);
                } else {
                    setQuestionError("Upto 7 digits only");
                }
            }
            else {
                setQuestionError("Please enter only numbers ");
            }
            if (currentFormData) {
                currentFormData.questions = value;
            }

        } else {
            setQuestionError("");
            if (currentFormData) {
                currentFormData.questions = ""
            }
            data.questions = "";

        }
    }
    const createTestManually = () => {
        setNoQuestions("");
        setTestTime("");
        addTest("0");
        getTestNumbersData(false);
    }
    const getTestNumbersData = async (aI: boolean) => {
        try {
            const payLoad = {
                "board_uuid": filterData?.board_uuid,
                "medium_uuid": filterData?.medium_uuid,
                "standard_uuid": filterData?.standard_uuid,
                "subject_uuid": filterData?.subject_uuid,
                "chapter_uuid": filterData?.exam_type === "CHAPTER_WISE_TEST" ? filterData?.chapter_uuid : "",
                "topic_uuid": filterData?.topic_uuid,
                "exam_type": filterData?.exam_type,
                "type": "TESTS",
            }
            setFormLoading(true);
            const res: any = await GET_TEST_DATA(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const preData = currentFormData;
                    preData.number = res?.next_test_number;
                    preData.set_number = res?.next_set_number;
                    setCurrentFormData({ ...preData });
                    if (aI) {
                        setTestByAI(true);
                        setCanShowPopup(true);
                        setQuestionsList([]);
                    }
                }
            }
            setFormLoading(false);
        } catch (error) {
            setFormLoading(false);
        }
    }
    const handleByAI = () => {
        setNoQuestions("");
        setTestTime("");
        setQuestionError("");
        setTimeError("");
        getTestNumbersData(true);

    }
    const handleBackAI = () => {
        setCanShowAIPopup(false);
        setCanShowPopup(true);
    }
    const handleAINext = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        if (formsData?.questions && formsData?.time
            && !questionError && !timeError) {
            setQuestionsList([]);
            getQuestionByAI();
        } else {
            if (!formsData?.questions) {
                setQuestionError("Please enter no of questions in test");
            }
            if (!formsData?.time) {
                setTimeError("Please enter test time");
            }
        }
    }
    const getQuestionByAI = async () => {
        try {
            const payLoad = {
                board_uuid: filterData?.board_uuid,
                medium_uuid: filterData?.medium_uuid,
                standard_uuid: filterData?.standard_uuid,
                subject_uuid: filterData?.subject_uuid,
                categorizeByTopic: true,
                random: true,
                chapter_uuid: filterData?.exam_type === "CHAPTER_WISE_TEST" ? filterData?.chapter_uuid : "",
                validation_status: "VERIFIED",
                mode_type: "2,3"
            }
            const res: any = await GET_CONTENT_RANDOM_QUESTIONS(payLoad, 0, Number(noQuestions));
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setLoading(false);
                } else {
                    const data = res;
                    setTestResponseOfAI(data);
                    if (data?.all_questions && data?.all_questions?.length > 0) {
                        const preQuestions: any = data?.all_questions?.map((item: any) => ({
                            ...item,
                            mode: "add"
                        }));
                        setQuestionsList(preQuestions);
                        setCanShowPopup(false);
                        setCanShowAIPopup(true);
                    } else {
                        toast.error(res?.message);
                        setQuestionsList([]);
                    }
                }
            }
        } catch (error) {

        }
    }
    const handleAIPreviewQuestion = () => {
        setShowQuestionPopUp(true);
        setCanShowAIPopup(false);
    }
    const handleSubmitByAITest = async () => {
        const payLoad = {
            "board_uuid": filterData?.board_uuid,
            "medium_uuid": filterData?.medium_uuid,
            "standard_uuid": filterData?.standard_uuid,
            "subject_uuid": filterData?.subject_uuid,
            "chapter_uuid": filterData?.exam_type === "CHAPTER_WISE_TEST" ? filterData?.chapter_uuid : "",
            "topic_uuid": filterData?.topic_uuid,
            "mode": "PARIKSHA",
            "type": "TESTS",
            "exam_type": filterData?.exam_type,
            "number": currentFormData?.number,
            "time": Number(testTime),
            "questions": Number(noQuestions),
            "name": currentFormData?.name ? currentFormData?.name : "",
            "set_number": parseInt(currentFormData?.set_number),
            "questions_data": questionsList,
            "created_by": userCode,
            "question_count_update": true,

        }
        const res: any = await CREATE_PARIKSHA_TESTS(payLoad);
        if (res.status === 'fail') {
            toast.error(res?.err);
        } else {
            toast.success(res.message);
            setCanShowPopup(false);
            setCanShowAIPopup(false);
            getParikshaTestData();

        }
    }
    const closePopup = () => {
        if (testByAI) {
            setShowQuestionPopUp(false);
            setCanShowAIPopup(true);
        } else {
            setShowQuestionPopUp(false);
        }
    }
    const getTotalValue = (easy: number, medium: number, hard: number) => {
        return easy + medium + hard;
    }
    const capitalizeFirstLetterOnly = (input: string): string => {
        return input
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    return (
        <div>
            {showQuestionPopUp &&
                <div className={`${!showQuestionPopUp && "d_none"}`}>
                    <ContentPreviewQuestions questionsList={questionsList} setShowPopUp={setShowQuestionPopUp} topic_questions={false} handleClose={closePopup} />
                </div>
            }
            <div
                className={`modal fade ${canShowAIPopup ? "show" : "false"}`}
                style={{ display: canShowAIPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h5 className="f18 fw-bold" id="exampleModalLabel">
                                Create Test by AI
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleBackAI}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body ">
                            <div className="row mx-2">
                                <div className="col-12 col-md-4">
                                    <div className="me-2">
                                        <p className="f14 mb-1">Number of questions in test: <span className="fw-bold">{currentFormData?.questions}</span></p>
                                        <p className="f14 mb-1">Number of topics in selected chapters: <span className="fw-bold">{testResponseOfAI?.total_topics}</span></p>
                                        <h6 className=" mt-2">With the help of <span className="text-danger">AI</span> and the given <br /><span className="text-danger">inputs</span>, system generated the <br /> questions volume per topic.</h6>
                                        <p className="f14 mt-2">You can <span className="fw-bold">edit the question volume as<br /> per your choice.</span></p>
                                        <p className="f14 mb-0">Selected Chapters</p>
                                        <div className="border test_bx_overflow p-2 me-5">
                                            <ul className="ps-3">
                                                {testResponseOfAI?.data?.map((data: any, i: number) => (
                                                    <li className="mb-1 f14"> {data?.chapter_name}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 border col-md-8 question_view_height">
                                    <div className="row ">
                                        <div className="col-12 col-md-7">
                                            <h6 className="pt-1">Chapters and Topics</h6>
                                        </div>
                                        <div className="col-12 col-md-5">
                                            <div className="row text-center">
                                                <div className="col-3 ch_tp_easy_bg ">
                                                    <h6 className="pt-1 f14 text-white">Easy</h6>
                                                </div>
                                                <div className="col-3 ch_tp_medium_bg">
                                                    <h6 className="pt-1 f14 text-white">Medium</h6>
                                                </div>
                                                <div className="col-3 ch_tp_hard_bg">
                                                    <h6 className="pt-1 f14 text-white">Hard</h6>
                                                </div>
                                                <div className="col-3 ch_tp_total_bg">
                                                    <h6 className="pt-1 f14 text-white">Total</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {testResponseOfAI?.data?.map((data: any, index: number) => (
                                        <>
                                            <div className="row " key={index}>
                                                <div className="col-12 col-md-7 ch_bg">
                                                    <h6 className="pt-1 f14">{data?.chapter_name} </h6>
                                                </div>
                                                <div className="col-12 col-md-5 ">
                                                    <div className="row text-center">
                                                        <div className="col-3 ch_easy_bg border border-white">
                                                            <h6 className="pt-1 f14 text-danger">{data?.easy}</h6>
                                                        </div>
                                                        <div className="col-3 ch_medium_bg border border-white">
                                                            <h6 className="pt-1 f14 text-danger">{data?.medium}</h6>
                                                        </div>
                                                        <div className="col-3 ch_hard_bg border border-white">
                                                            <h6 className="pt-1 f14 text-danger">{data?.hard}</h6>
                                                        </div>
                                                        <div className="col-3 ch_total_bg border border-white">
                                                            <h6 className="pt-1 f14 text-danger">{getTotalValue(data?.easy, data?.medium, data?.hard)}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {data?.topics?.map((item: any, i: number) => (
                                                <div className="row">
                                                    <div className="col-12 col-md-7 tp_bg">
                                                        <p className="mb-0 pt-1 f14">{item?.topic_name}</p>
                                                    </div>
                                                    <div className="col-12 col-md-5 ">
                                                        <div className="row text-center">
                                                            <div className="col-3 tp_easy_bg border  border border-white px-0">
                                                                <h6 className="pt-1 f14">{item?.easy}</h6>
                                                            </div>
                                                            <div className="col-3 border tp_medium_bg border border-white">
                                                                <h6 className="pt-1 f14">{item?.medium}</h6>
                                                            </div>
                                                            <div className="col-3 border tp_hard_bg border border-white">
                                                                <h6 className="pt-1 f14">{item?.hard}</h6>
                                                            </div>
                                                            <div className="col-3 border ch_total_bg border border-white">
                                                                <h6 className="pt-1 f14">{getTotalValue(item?.easy, item?.medium, item?.hard)}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary f12 px-4 py-1 rounded-3" onClick={handleBackAI}>Back</button>
                            <button className="btn text-white f12 px-4 py-1 rounded-3 payment_user_btn border-0 mx-3" onClick={handleAIPreviewQuestion} disabled={questionsList?.length == 0}>Preview</button>
                            <button className="btn btn-primary f12 px-4 py-1 rounded-3" onClick={handleSubmitByAITest} disabled={questionsList?.length == 0}>Create Test</button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            {testByAI ? <h5 className="modal-title" id="exampleModalLabel">
                                Create Test by AI
                            </h5>
                                : <h5 className="modal-title" id="exampleModalLabel">
                                    {testUuid === "0" ?
                                        (<span>Create Test Manually</span>)
                                        : (<span> Update Test Manually</span>)
                                    }
                                </h5>}
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info ">
                            {formLoading && (
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!formLoading && (
                                <>
                                    <FormBuilder onUpdate={handleFormInput}>
                                        <form ref={medimForm}>
                                            <div className="row custom-form">
                                                <div className="col-md-4">
                                                    <div className='mb-4'>
                                                        <label>Board</label>
                                                        <select name="board_uuid" id="board_uuid" className="form-select ft-14 disabled_bg" disabled value={filterData?.board_name} >
                                                            <option value={filterData?.board_uuid}>{filterData?.board_name}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='mb-4 position-relative'>
                                                        <label>Medium</label>
                                                        <select name="medium_uuid" id="medium_uuid" className="form-select ft-14 disabled_bg" disabled value={filterData?.medium_name} >
                                                            <option value={filterData?.medium_uuid}>{filterData?.medium_name}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='mb-4  position-relative'>
                                                        <label>Standard</label>
                                                        <select name="standard_uuid" id="standard_uuid" className="form-select ft-14 disabled_bg" disabled value={filterData?.standard_name}>
                                                            <option value={filterData?.standard_uuid}>{filterData?.standard_name}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='mb-4  position-relative'>
                                                        <label>Subject</label>
                                                        <select name="subject_uuid" id="subject_uuid" className="form-select ft-14 disabled_bg" disabled value={filterData?.subject_name} >
                                                            <option value={filterData?.subject_uuid}>{filterData?.subject_name}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-0">Exam Type </label>
                                                        <select name="exam_type" id="exam_type" className="form-select ft-14 disabled_bg" disabled value={filterData?.exam_type} >
                                                            <option value={filterData?.exam_type}>{filterData?.exam_type && capitalizeFirstLetterOnly(filterData?.exam_type)}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className=' mb-4'>
                                                        <label>Chapter / Chapters</label>
                                                        {filterData?.exam_type === "CHAPTER_WISE_TEST" ?
                                                            <select name="chapter_uuid" id="subject_uuid" className="form-select ft-14 disabled_bg" disabled value={filterData?.chapter_name} >
                                                                <option value={filterData?.chapter_uuid}>{filterData?.chapter_name}</option>
                                                            </select> :
                                                            <div className="border test_bx_overflow p-2 disabled_bg">
                                                                {props?.chapterList?.map((data: any, i: number) => (
                                                                    <p className="mb-1 f14" key={i}>{i + 1}. {data?.name}</p>
                                                                ))}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Name </label>
                                                        <input className="form-control form-control-lg f16" type="text" name="name" defaultValue={currentFormData?.name} placeholder="Please enter  test name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Test Number</label>
                                                        <input className="form-select ft-14 disabled_bg" disabled type="text" name="number" defaultValue={currentFormData?.number} placeholder="Please enter  test number" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Total Questions<span className="f12 ms-1 fw-normal">(in test)</span><sup className="text-danger f16">*</sup></label>
                                                        <input className="form-control form-control-lg f16" type="text" name="questions" defaultValue={noQuestions} placeholder="Please enter no of questions in test  " onChange={(e) => { handleTestQuestion(e.target.value) }} />
                                                        {questionError && <p className="text-danger">{questionError}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Test Time<span className="f12 ms-1 fw-normal">(in minutes)</span><sup className="text-danger f16">*</sup></label>
                                                        <input className="form-control form-control-lg f16" type="text" name="time" defaultValue={testTime} placeholder="Please enter test time" onChange={(e) => { handleTestTime(e.target.value) }} />
                                                        {timeError && <p className="text-danger">{timeError}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Test - Set number <sup className="text-danger f14"></sup></label>
                                                        <input className="form-select ft-14 disabled_bg" disabled type="text" name="set_number" defaultValue={currentFormData?.set_number} placeholder="Please enter test set number" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </FormBuilder>
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            {testByAI ?
                                <a className="btn btn-primary btn-sm px-4 rounded-3 cursor-pointer py-1 f14" onClick={handleAINext}>Next </a>
                                : <div>
                                    {testUuid === "0" && < a className="btn btn-primary btn-sm px-4 rounded-3 cursor-pointer py-1 f14" onClick={() => createTest()}>Next </a>}
                                    {testUuid !== "0" && <a className="btn btn-primary btn-sm px-4 rounded-13 cursor-pointer py-1 f14" onClick={() => updateTest()}>Update</a>}
                                </div>}
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-4 rounded-3 cursor-pointer py-1 f14 "
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row  " style={{ marginBottom: '15px' }}>
                <div className="col-md-7"></div>
                <div className="col-md-5 pt-3 pt-md-0 text-end">
                    <div className='export_btn me-2 me-sm-4 rounded-12' style={{ position: 'relative', right: '75px', top: '-60px', height: '35px' }}>
                        <button
                            className="export_btn_select f14 rounded-12"
                            onClick={() => setTestOptions(!testOptions)}
                        >
                            <span>Create Test
                                <i className='bi bi-chevron-down ps-3'></i></span>
                        </button>
                        {testOptions && <ul className='export_btn_select_drop_list z_100'>
                            <li className='text-start export_btn-list-view ' onClick={createTestManually}>
                                <span>Manually</span></li>
                            <li className='export_btn-list-view my-1' onClick={handleByAI}>
                                <span>By AI/System</span></li>
                        </ul>}
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="mt-4">
                    <DataTable TableCols={ContentParikshaTestDataGridCols} tableData={testList}
                        onActions={(e) => onActions(e)}
                        onChange={viewQuestion}
                        editInfo={onEdit}
                        deleteInfo={onDelete}
                        pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>
                </div>}

        </div>
    )
}
