import React, { useEffect, useState } from "react";
import { GET_TIME_LOGS } from "../../../../app/service/time-logs/logout-sevices";
import { toast } from "react-toastify";

interface Props {
    filterData: any,
    userCode: any
}

export const KaryakartaSystemHours = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [hoursInfo, setHoursInfo] = useState<any>({});
    const [filterData, setFilterData] = useState<any>(props?.filterData);

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        setLoading(true);
        try {
            const payLoad = {
                user_uuid: props?.userCode,
                limit: 100000000,
                ...props?.filterData
            }
            const res: any = await GET_TIME_LOGS(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (res) {
                        setHoursInfo(res);
                    }
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);

        }
    }
    const formatDateToDDMMYYYY = (dateInput: string | Date): string => {
        const date = new Date(dateInput);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    const formatDuration = (seconds: number): string => {
        if (seconds < 60) {
            return `${seconds} sec`;
        } else if (seconds < 3600) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return remainingSeconds > 0
                ? `${minutes} min ${remainingSeconds} sec`
                : `${minutes} min`;
        } else {
            const hours = Math.floor(seconds / 3600);
            const remainingMinutes = Math.floor((seconds % 3600) / 60);
            return remainingMinutes > 0
                ? `${hours} hr ${remainingMinutes} min`
                : `${hours} hr`;
        }
    };
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="row justify-content-center mx-1 mt-3">
                    <div className="col-12 col-md-11 border-top border-2 pt-2 ">
                        <div className="d-flex justify-content-between mx-md-3">
                            <p className="f14 mb-0 text-start"><span className="fw-bold">Duration:</span>{filterData?.from_date && formatDateToDDMMYYYY(filterData?.from_date)}{filterData?.to_date && <span> to {formatDateToDDMMYYYY(filterData?.to_date)}</span>}</p>
                            <p className="f14 mb-0 text-end fw-bold">Total Hours Worked: {(hoursInfo?.total_time_seconds == 0 || hoursInfo?.total_time_seconds) && formatDuration(hoursInfo?.total_time_seconds)}</p>
                        </div>
                    </div>
                </div>}
        </div>
    )
}