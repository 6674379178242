import React, { useEffect, useState } from "react";

declare const window: any;

const AppParikshaExplanationView = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [testData, setTestData] = useState<any>([]);
    const [webviewInitialized, setWebviewInitialized] = useState<boolean>(false);


    useEffect(() => {
        const isIos = navigator.userAgent.includes('iPhone');
        if (isIos) {
            window.addEventListener("message", handleNativeMessage);
        } else {
            document.addEventListener("message", handleNativeMessage);
        }
        return () => {
            window.removeEventListener('message', handleNativeMessage);
        };
    }, []);


    useEffect(() => {
        if (!webviewInitialized) {
            setWebviewInitialized(true);
            sendMessageToApp({ action: 'init_webview', type: '', data: 'initialized' });
        }

    }, [webviewInitialized]);
    function handleNativeMessage(message: any) {
        let data = JSON.parse(message.data);
        setLoading(true);
        setTimeout(() => {
            if (data?.student_result?.length > 0) {
                let preData = data?.student_result?.filter((item: any, index: number) => {
                    item.isSelected = false;
                    return item;
                })
                setTestData(preData);
            }
            setLoading(false);
        }, 1000);
    }
    const sendMessageToApp = (data: any) => {
        if (window?.ReactNativeWebView) {
            try {
                window?.ReactNativeWebView.postMessage(JSON.stringify(data));
            } catch (error) {

            }
        }
    }
    function onViewQuestion(name: any) {
        return { __html: name };
    }
    const onClickExplanation = (item: any, index: number) => {
        let items = [...testData];
        items[index]['isSelected'] = !item.isSelected;
        setTestData([...items]);
    }
    const getOptions = (data: any, option: string) => {
        const preData = data.find((ele: any) => (ele?.option === option));
        if (preData?.value) {
            return <div className="mb_0 img_ck" dangerouslySetInnerHTML={{ __html: preData?.value }} />
        }
    }
    return (
        <div className='app_expl_src scroll_div_block'>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className=''>
                    <div className=" px-1 py-3">
                        {testData?.map((question: any, i: number) => (
                            <div className="mb-4" key={i}>
                                <div className="d-flex mb-2">
                                    <h6 className="pt-1">{i + 1}. </h6>
                                    <div className='fw-bold ms-2 mb_0 img_ck' dangerouslySetInnerHTML={onViewQuestion(question?.question)}></div>
                                </div>
                                <div className="ms-4 img_ck">
                                    <div className={`pb-1 d-flex justify-content-between ${question?.result === "wrong" ? "ex_pink" : "ex_blu"}  px-2 pt-1 rounded-3`}>
                                        <div className={`d-flex  f14 `}><p className="mb-0 ">Your Answer: </p> <div className="d-flex fw-bold ms-2"><p className="mb-0">{question?.answer}{question?.answer && <span className="fw-bold">)</span>}</p> {question?.answer && <p className="ms-2 mb-0">{getOptions(question?.options, question?.answer)}</p>}</div></div>
                                        <p className="mb-1  fw-bold">{question?.result === "wrong" ? <span className="text-inprogess"> &#10006;</span> : <span className="text-completed f18">&#10003;</span>}</p>
                                    </div>
                                    <div className="pb-1 d-flex mt-1 mb-3 px-2 py-1 ex_blu  rounded-3 f14 "><p className="mb-0 ">Correct Answer: </p> <div className="d-flex fw-bold ms-1"><p className="mb-0">{question?.correct_answer})</p> {question?.correct_answer && <p className="ms-2 mb-0">{getOptions(question?.options, question?.correct_answer)}</p>}</div></div>
                                    <p className="active f14 fw-bold mb-2 cursor-pointer position-relative " onClick={() => onClickExplanation(question, i)}><u>Explanation</u>  : <i className={`ms-2 f22 position-absolute  ${question.isSelected ? "fa fa-angle-down" : "fa fa-angle-right"}  `}></i></p>
                                    {question.isSelected &&
                                        <div className='f14 mb-2 mb-0 text-break mb_0 img_ck' dangerouslySetInnerHTML={onViewQuestion(question?.explanation)}></div>
                                    }
                                </div>
                            </div>
                        ))}
                        {testData?.length == 0 && <p className='ms-3 f16 my-2'>No Data Available</p>}
                    </div>
                </div>}
        </div>
    )

}
export default AppParikshaExplanationView;