import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { LOOK_UP_DATA } from "../../../../app/service/lookup.service";
import { GET_BULK_UPLOAD_STATUS } from "../../../../app/service/school.service";
import { BULK_UPLOAD_STUDENTS, DELETE_USER, DOWNLOAD_STUDENTS, DOWNLOAD_STUDENT_FAILED_EXIST, DOWNLOAD_STUDENT_TEMPLATE, GET_USER_BY_SCHOOL_CODE, GET_USER_Filter } from "../../../../app/service/users.service";
import { returnPaginationRange } from "../../../../app/utility/appUtils";
import FormBuilder from "../../../../components/form-builder";
import appConfig from "../../../../config/constant";
import { ManageSchoolStudentsDataGridCols } from './data-grid-cols'
import { DataTable } from "../../../../components/data-table";

export const StudentslistData = () => {
    const [activePage, setActivePage] = useState<any>();
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get("page");
    const [pageNumbers, setPageNumbers] = useState<any>([]);
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const [loading, setLoading] = useState(false);
    const [studentList, setStudentList] = useState<any>([]);
    const [searchData, setSearchData] = useState<any>({});
    const usersForm = useRef<any>({});
    const usersFormname = useRef<any>({});
    const [statesData, setStatesData] = useState<any>([]);
    const [districtData, setDistrictData] = useState<any>([]);
    const [talukaData, setTalukaData] = useState<any>([]);
    const history = useHistory();
    const [allStateBlock, setAllStateBlock] = useState<any>({});
    const [preparedSearchData, setPreparedSearchData] = useState<any>({});
    const studentForm = useRef<any>({});
    let totalPage = pageNumbers.length
    const userRole = localStorage.getItem("user_role");
    const [canShowDeletePopup, setCanShowDeletePopup] = useState(false);
    const [selectedData, setSelectedData] = useState<any>({});
    const [successList, setSuccessList] = useState<any>([]);
    const [failedList, setFailedList] = useState<any>([]);
    const [alredyExistList, setAlredyExistList] = useState<any>([]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [failedListPath, setFailedListPath] = useState(true);

    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        getStudents();
        LOOK_UP_DATA().then((res: any) => {
            if (res) {
                setStatesData(res.states);
            }
        });

    }, []);

    const arrayOfPages = returnPaginationRange(pageNumbers.length, activePage, 10, 1)

    function getStudents() {
        // const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        if (schoolsCode) {
            setLoading(true);
            GET_USER_BY_SCHOOL_CODE(schoolsCode, 'STUDENT', 0, {}).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                        const data = res.rows;
                        data.forEach((element: any) => {
                            element.school_student = element.name;
                            element.account_type = element?.account_type?.charAt(0).toUpperCase() + element?.account_type?.slice(1).toLowerCase();
                            element.subscription_status = element?.subscription_status?.charAt(0).toUpperCase() + element?.subscription_status?.slice(1).toLowerCase();
                            element.account_type = element?.account_type === "Paid" ? element?.subscription_status : element?.account_type;
                        });
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        // const strAscending = [...data].sort((a, b) =>
                        //     a.division > b.division ? 1 : -1,
                        // );
                        // setStudentList(strAscending);
                        setStudentList(data);

                        setLoading(false);
                    }
                }
            });
        }
    }

    function onClickPage(number: number) {
        let dots: any = "..."

        if (activePage !== 1 && number === dots) {
            setActivePage(1);
            onPageChange(1);

        } else if (activePage !== pageNumbers.length && number === dots) {
            setActivePage(totalPage - 1);
            onPageChange(totalPage - 1);
        }
        else {
            setActivePage(number);
            onPageChange(number);
        }

        // setActivePage(number);
        // onPageChange(number);
    }
    function onClickNextPage() {
        setActivePage(activePage + 1);
        onPageChange(activePage + 1);

    }
    function onClickPreviousPage() {
        setActivePage(activePage - 1);
        onPageChange(activePage - 1);

    }
    const onPageChange = (data: any) => {
        // setActivePage(data);
        if (data) {
            history.push(`/institute-dashboard/manage-school/list/info/${schoolId}/studentslist/${schoolCode}?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        const institutecCode = localStorage.getItem("institute_code");
        setLoading(true);
        GET_USER_BY_SCHOOL_CODE(schoolsCode, 'STUDENT', pageNumber, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.school_student = element.name;
                        element.account_type = element?.account_type?.charAt(0).toUpperCase() + element?.account_type?.slice(1).toLowerCase();
                        element.subscription_status = element?.subscription_status?.charAt(0).toUpperCase() + element?.subscription_status?.slice(1).toLowerCase();
                        element.account_type = element?.account_type === "Paid" ? element?.subscription_status : element?.account_type;
                    });
                    setStudentList(data);
                    setLoading(false);
                }
            }
        });

    }

    const handleInput = (data: any) => {
        setSearchData(data);
    };
    const handleCustomInput = (data: any) => {
        setSearchData(data);
    };

    const handleStateChange = (e: any) => {
        if (e.target.value) {
            const preparedData = statesData.filter((el: any) => el.state === e.target.value);
            // setCityData(preparedData[0].cities);
            setDistrictData(preparedData[0].districts);
            setAllStateBlock(preparedData[0]?.block);

        }
    }

    const handleDistrictChange = (e: any) => {
        setTalukaData([]);
        onLoadBlocks(e.target.value, allStateBlock);
    }

    const onLoadBlocks = (e: any, blocks: any) => {
        Object.keys(blocks).find(key => {
            if (key === e) {
                setTalukaData(blocks[key]);
                return;
            }
        });
    }
    function handleSearch() {
        const data = { ...searchData.value };

        if (data) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            delete data.searchFilter;
            GET_USER_Filter(schoolsCode, data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    history.push(`/institute-dashboard/manage-school/list/info/${schoolId}/studentslist/${schoolCode}?page=${1}`);
                    setActivePage(1);
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.school_student = element.name;
                        element.account_type = element?.account_type?.charAt(0).toUpperCase() + element?.account_type?.slice(1).toLowerCase();
                        element.subscription_status = element?.subscription_status?.charAt(0).toUpperCase() + element?.subscription_status?.slice(1).toLowerCase();
                        element.account_type = element?.account_type === "Paid" ? element?.subscription_status : element?.account_type;
                    });
                    setStudentList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }


            });
        }
    }
    function handleRefresh() {
        const data = searchData
        data.value = {};
        setSearchData({ ...data });
        setPreparedSearchData({});
        getStudents();
        usersForm.current.reset();
        usersFormname.current.reset();
    }
    const onClickDoubbleArrows = (arrow: any) => {
        if (arrow === "&raquo;") {
            setActivePage(totalPage);
        } else {
            setActivePage(1);
        }
    }
    const editSchool = (data: any) => {
        history.push(`/institute-dashboard/manage-school/list/info/${schoolId}/studentsform/${schoolsCode}/${data.id}`)
    }
    function onClickDelete(item: any) {
        setCanShowDeletePopup(true);
        setSelectedData(item);

    }
    const handleDeleteClose = () => {
        setCanShowDeletePopup(false);
    };
    const onDeleteStudent = (data: any) => {
        setLoading(true);
        const id = data.user_code;
        DELETE_USER(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Students Deleted Successfully");
                getStudents();
                setCanShowDeletePopup(false);

            }
        });

    }
    function handleDownloadTemplate() {
        setLoading(true);
        const data = '/templates/students_bulk_upload.xls';
        if (data) {
            const url = `${appConfig.FILE_UPLOAD_URL}${data.charAt(0) === "/" ? data.substring(1) : data}`;
            window.location.href = url;
            toast.success("Download Student Template Successfully");

        }
        setLoading(false);
    }
    function handleDownloadStudents() {
        setLoading(true);
        DOWNLOAD_STUDENTS(schoolsCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                const data = res;
                toast.success("Download Students Successfully");
                if (data.url) {
                    const url = `${appConfig.FILE_UPLOAD_URL}${data.url.charAt(0) === "/" ? data.url.substring(1) : data.url}`;
                    window.location.href = url;
                }
            }
        });
    }
    function handleUploadBulkUpload(e: any) {

        if (e.target.files && e.target.files[0]) {
            let formData = new FormData();
            formData.append('file', e.target.files[0], e.target.files[0].name);
            formData.append('school_code', schoolsCode);
            setLoading(true);
            BULK_UPLOAD_STUDENTS(formData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);

                } else {
                    setLoading(false);
                    toast.success("Bulk Upload Successfully");
                    getStudents();
                }
            });
        }

    }
    function handleUploadBulkUploadStatus() {
        setLoading(true);
        GET_BULK_UPLOAD_STATUS(schoolsCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res.rows.length > 0) {
                    toast.success(res.rows[0].status);
                    const data = res.rows[res.rows.length - 1];

                    if (data.result) {
                        setSuccessList(data.result.succesList);
                        setFailedList(data.result.failedList);
                        setAlredyExistList(data.result.alreadyExistList);
                    }
                    if (data.filepath === null || data.filepath === "") {
                        setFailedListPath(false)
                    }

                }
                setCanShowPopup(true);

                getStudents();
                setLoading(false);
            }
        });

    }
    const handleclose = () => {
        setCanShowPopup(false);
    }
    function handleDownloadFaildList() {
        setLoading(true);
        DOWNLOAD_STUDENT_FAILED_EXIST(schoolsCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                const data = res;
                if (data.url) {
                    const url = `${appConfig.FILE_UPLOAD_URL}${data.url.charAt(0) === "/" ? data.url.substring(1) : data.url}`;
                    window.location.href = url;
                }
                toast.success("Download Student List Successfully");

            }
        });
    }
    const handleViewReport = (data: any) => {
        history.push(`/institute-dashboard/manage-school/list/info/${schoolId}/maths-report/${schoolsCode}/${data?.user_code}`);
    }
    return (
        <div>
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog student-upload-dialog" role="document">
                    <div className="modal-content student-upload-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Student Upload Records</h5>
                            <button type="button" className="close popup-close" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className='row'>
                                    <div className='col-md-10'>
                                        <h4>Success List</h4>
                                    </div>
                                    <div className='col-md-2'>
                                        Count: {successList.length}
                                    </div>
                                </div>
                                <table className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Mobile Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {successList.map((item: any, i: number) => (
                                            <tr key={i}>
                                                <td>{item?.name}</td>
                                                <td>{item?.mobile}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {successList.length === 0 && <div className='text-center'>No records found.</div>}
                            </div>
                            <div className="mt-4">
                                <div className='row'>
                                    <div className='d-flex justify-content-between col-md-10'>
                                        <h4>Failed List</h4>
                                        {(failedListPath && failedList?.length > 0) && <p><a className="btn btn-primary sc-profile-viewbtn mb-0" onClick={handleDownloadFaildList}>Download </a></p>}
                                    </div>
                                    <div className='col-md-2'>
                                        Count: {failedList.length}
                                    </div>
                                </div>
                                <table className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Mobile Number</th>
                                            <th>Reason</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {failedList.map((item: any, i: number) => (
                                            <tr key={i}>
                                                <td>{item?.name}</td>
                                                <td>{item?.mobile}</td>
                                                <td>{item?.reason}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {failedList.length === 0 && <div className='text-center'>No records found.</div>}
                            </div>
                            <div className="mt-4">
                                <div className='row'>
                                    <div className='d-flex justify-content-between col-md-10'>
                                        <h4>Already Exist List</h4>
                                        {(failedListPath && alredyExistList?.length > 0) && <p><a className="btn btn-primary sc-profile-viewbtn mb-0" onClick={handleDownloadFaildList}>Download </a></p>}

                                    </div>
                                    <div className='col-md-2'>
                                        Count: {alredyExistList.length}
                                    </div>
                                </div>
                                <table className="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Mobile Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {alredyExistList.map((item: any, i: number) => (
                                            <tr key={i}>
                                                <td>{item?.name}</td>
                                                <td>{item?.mobile}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {alredyExistList.length === 0 && <div className='text-center'>No records found.</div>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal fade ${canShowDeletePopup ? "show" : "false"}`}
                style={{ display: canShowDeletePopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Delete
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleDeleteClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="row py-3">
                            <div className="col-md-12 text-center m">
                                <h5>
                                    Do you want to delete this{" "}
                                    {selectedData?.name ? selectedData?.name : ""} record?

                                </h5>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-primary fw-light cursor-pointer"
                                onClick={() => onDeleteStudent(selectedData)}
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer"
                                data-dismiss="modal"
                                onClick={handleDeleteClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion mb-3" id="accordionExample">
                <div className="accordion-item filter-section-fileds">
                    <div className='d-flex justify-content-between'>
                        <h2 className="accordion-header" id="headingOne">
                            <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                                <i className="filter-icon"></i> Filter
                            </button>
                        </h2>
                        {userRole === "INSTITUTE_ADMIN" && <Link to={`/institute-dashboard/manage-school/list/info/${schoolId}/studentsform/${schoolsCode}/0`} ><button className='me-2 btn btn-primary mt-2 mb-1'>
                            Add Student
                        </button></Link>}
                    </div>
                    <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <FormBuilder onUpdate={handleInput}>
                                <form ref={usersForm}>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-3 mb-3 text-start">
                                            <label className="form-label">Search Name</label>
                                            <input type="text" className="form-control" placeholder="Search by Name" name="name" id="name" />
                                        </div>
                                        <div className="col-md-6 col-lg-3 mb-3 text-start">
                                            <label className="form-label">State</label>
                                            <select name="state" id="state" className="form-select" onChange={(e) => { handleStateChange(e) }}>
                                                <option value="">Search by state</option>
                                                {statesData.map((name: any, i: number) => (
                                                    <option key={i} value={name.state}>
                                                        {name.state}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-6 col-lg-3 mb-3 text-start">
                                            <label className="form-label">District</label>
                                            <select name="district" id="district" className="form-select " onChange={(e) => { handleDistrictChange(e) }}>
                                                <option value="">Search by District</option>
                                                {districtData.map((name: any, i: number) => (
                                                    <option key={i} value={name}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="w-100"></div>
                                        <div className="col-md-6 col-lg-3 mb-3 text-start">
                                            <label className="form-label">Taluka</label>
                                            <select className="form-select" name="taluka" id="taluka" >
                                                <option value="">Search by Taluka</option>
                                                {talukaData.map((name: any, i: number) => (
                                                    <option key={i} value={name}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-6 col-lg-3 mb-3 align-self-end text-start">
                                            <button type="button" className="btn btn-primary mt-1" onClick={() => handleSearch()}>Search</button>
                                            <button type="button" className="btn btn-link" onClick={() => handleRefresh()}>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </FormBuilder>
                        </div>

                    </div>

                </div>
            </div>
            <div className="mb-3 filter-search">
                <FormBuilder onUpdate={handleCustomInput}>
                    <form ref={usersFormname}>
                        <input
                            type="text"
                            className="form-control" name="search"
                            placeholder="Custom Search / by taluka, district, state"
                        />
                    </form>
                </FormBuilder>
                <button className="btn btn-primary" type="button" onClick={() => handleSearch()}>Search</button>
            </div>
            <div className="text-end">
                {userRole === "INSTITUTE_ADMIN" && <div className="pb-3 pt-2">
                    <button className="btn btn-sm btn-primary px-4 rounded-12 cursor-pointer mb-2 mt-2" style={{ marginRight: '10px', color: 'white' }} onClick={() => handleDownloadTemplate()}>Student List Template Download
                    </button>
                    <div className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer  mb-2 mb-md-0" onClick={() => handleDownloadStudents()} style={{ marginRight: '10px' }}>Student Login Credentials Download
                    </div>
                    <div className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block  mb-2 mb-md-0" style={{ marginRight: '10px', cursor: 'pointer' }}>Student List Upload
                        <input type="file" style={{ cursor: 'pointer' }} onChange={(e) => handleUploadBulkUpload(e)}
                            onClick={(e: any) => {
                                e.target.value = null
                            }}
                        />
                    </div>
                    <button className="btn btn-sm btn-warning px-4 rounded-12 cursor-pointer " style={{ marginRight: '10px', color: 'white' }} onClick={() => handleUploadBulkUploadStatus()}>Students List Upload Status
                    </button>

                </div>}
            </div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <DataTable TableCols={ManageSchoolStudentsDataGridCols}
                onChange={handleViewReport} editInfo={editSchool} deleteInfo={onDeleteStudent}
                tableData={studentList} pageNumber={onPageChange} type={"classic"}
                activePageNumber={activePage} pageNumbers={pageNumbers}></DataTable>}

        </div>
    )
}