import React, { useEffect, useState } from "react";
import { GET_CONTENT_QUESTIONS } from "../../../app/service/content-module/question-service";
import { toast } from "react-toastify";
import { Breadcum } from "../../../components/breadcum";
import { table } from "console";


export const PromptMessage = () => {
    const [listData, setListData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Content',
            value: 'Content',
            routerLink: '/dashboard/content/view',
            isActive: false
        },
        {
            label: 'Pending list',
            value: 'Pending list',
            routerLink: '',
            isActive: true
        }
    ]);
    useEffect(() => {
        fethAllData();

    }, []);


    const fethAllData = async () => {
        setLoading(true);
        try {
            await getQuestionsList();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const getQuestionsList = async () => {
        try {
            const payLoad = {
                content_type: "QUESTION",
                sorting_type: "ASC",
                validation_status: "PENDING",
                table_view: true
            }
            const res: any = await GET_CONTENT_QUESTIONS(payLoad, 0, 1000000);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res?.hierarchy;
                    console.log(data, "data");

                    if (data && data?.length > 0) {
                        setListData(data);
                    } else {
                        setListData([]);
                    }
                }
            }
        } catch (error) {

        }

    }
    return (
        <div className="row border-top border-primary py-3">
            <Breadcum breadcumList={breadcumInfo} textLowerCase={true} />
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <div className={`mar-t-20 custom-scroll table-responsive ${listData?.length > 10 && "tableFixHead "}`}>
                        <table className="table fixed-table-header mb-0">
                            <thead className=''>
                                <tr>
                                    <th>S.No</th>
                                    <th>Board</th>
                                    <th>Medium</th>
                                    <th>Standard</th>
                                    <th>Subject</th>
                                    <th>Chapter</th>
                                    <th>Topic</th>
                                    <th className="text-center">Pending</th>
                                </tr>
                            </thead>
                            <tbody className=''>
                                {listData?.map((name: any, i: number) => (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{name?.board_name}</td>
                                        <td>{name?.medium_name}</td>
                                        <td>{name?.standard_name}</td>
                                        <td>{name?.subject_name}</td>
                                        <td>{name?.chapter_name}</td>
                                        <td>{name?.topic_name}</td>
                                        <td className="text-center">{name?.pending_questions}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    )
}