import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface SeriesData {
    name: string;
    type: 'line';
    data: number[];
}

interface LineChartProps {
    title: string;
    categories: string[];
    series: SeriesData[];
}

const ContentProgressBar: React.FC<LineChartProps> = ({ title, categories, series }) => {
    const options: Highcharts.Options = {
        chart: {
            type: 'line',
            backgroundColor: 'transparent',
            height: 300,
            width: 450,
        },
        title: {
            text: title,
            align: 'center',
            style: {
                color: 'black',
                fontSize: '16px',
                fontWeight: 'normal',
            },
        },
        xAxis: {
            categories: categories, // Dynamic categories
        },
        yAxis: {
            title: {
                text: '',
            },
        },
        legend: {
            symbolWidth: 20,
            symbolHeight: 0,
            symbolRadius: 0,
            symbolPadding: 5,
            y: -1,
            itemStyle: {
                cursor: 'default', // Change cursor to default to indicate non-interactivity
            },
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true,
                },
                enableMouseTracking: false,
            },
        },
        series: series.map((s) => ({
            ...s,
            marker: {
                radius: 1,
            },
            lineWidth: 2,
        })),
        credits: {
            enabled: false,
        },
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default ContentProgressBar;
