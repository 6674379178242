import React, { useEffect, useRef, useState } from "react";
import { GET_BOARD_FILTERS_LIST, GET_MEDIUM_FILTERS_LIST, GET_STANDARD_FILTERS_LIST, GET_SUBJECT_FILTERS_LIST } from "../../../../../app/service/abhyas-worksheet-filter.service";
import { toast } from "react-toastify";
import FormBuilder from "../../../../../components/form-builder";
import { AssessmentAddQuestions } from "./add-questions";

export const AssessmentTestList = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [boardsList, setBoardsList] = useState<any>([]);
    const [mediumList, setMediumList] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [subjectList, setSubjectList] = useState<any>([]);
    const [showQuestions, setshowQuestions] = useState<boolean>(false);
    const [selectedStandardList, setSelectStandardList] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>({});
    const [subjectName, setSubjectName] = useState<any>("");
    const [standardName, setStandardName] = useState<any>("");
    const filterForm = useRef<any>({});
    const [key, setKey] = useState<number>(0);


    useEffect(() => {
        getBoardData();

    }, [])

    const getBoardData = () => {
        setLoading(true);
        GET_BOARD_FILTERS_LIST().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    setBoardsList(data);
                    setLoading(false);
                }
            }
        });
    }
    const handleInput = (data: any) => {
        let preData = { ...data.value }
        setFilterData(preData);
        setshowQuestions(false);
    };
    const onChangeBoard = (e: any) => {
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        if (e) {
            GET_MEDIUM_FILTERS_LIST(e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        const data = res['rows'];
                        setMediumList(data);
                    }
                }
            });
        }
    }
    const onChangeMedium = (e: any) => {
        setStandardList([]);
        setSubjectList([]);
        if (e) {
            GET_STANDARD_FILTERS_LIST(filterData?.board_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                    } else {
                        const data = res['rows'];
                        setStandardList(data);
                    }
                }
            });
        }
    }
    const onChangeStandard = (e: any) => {
        setSubjectList([]);
        const standardName = standardList?.find((item: any) => item.uuid === e);
        if (standardName) {
            setStandardName(standardName?.standard || "");
            const items = getItemsBefore(standardName?.standard);
            setSelectStandardList(items);
        } else {
            setStandardName("");
        }
        if (e) {
            GET_SUBJECT_FILTERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                    } else {
                        const data = res['rows'];
                        setSubjectList(data);
                    }
                }
            });
        }
    }
    const onChangeSubject = (e: any) => {
        if (e) {
            const subjectName = subjectList?.find((item: any) => item.uuid === e);
            if (subjectName) {
                setSubjectName(subjectName?.name || "");
            } else {
                setSubjectName("");
            }
        }
    }
    const getItemsBefore = (selectedName: string) => {
        const sortedList = [...standardList]?.sort((a: any, b: any) => b.standard - a.standard);
        const preparedElement = sortedList?.filter((item: any) => {
            return item.standard <= selectedName;
        });

        return preparedElement.slice(1, 3);
    };
    const handleRefresh = () => {
        setFilterData({});
        setKey((prev) => prev + 1);
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        filterForm.current.reset();
    }
    const validationFilters = () => {
        if (filterData?.board_uuid && filterData?.medium_uuid && filterData?.standard_uuid && filterData?.subject_uuid) {
            return false;
        } else {
            return true;
        }
    }
    const getQuestionList = () => {
        setshowQuestions(true);
    }

    return (
        <div key={key}>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className='mb-3 mt-4'>
                    <FormBuilder onUpdate={handleInput}>
                        <form ref={filterForm}>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className='mb-4'>
                                        <label>Board</label>
                                        <select name="board_uuid" id="board_uuid" className="form-select ft-14 " onChange={(e) => { onChangeBoard(e.target.value) }}>
                                            <option value="">Select Board</option>
                                            {boardsList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4 position-relative'>
                                        <label>Medium</label>
                                        <select name="medium_uuid" id="medium_uuid" className="form-select ft-14 " onChange={(e) => { onChangeMedium(e.target.value) }}>
                                            <option value="">Select Medium</option>
                                            {mediumList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Standard</label>
                                        <select name="standard_uuid" id="standard_uuid" className="form-select ft-14 " onChange={(e) => { onChangeStandard(e.target.value) }}>
                                            <option value="">Select Standard</option>
                                            {standardList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.standard}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Subject</label>
                                        <select name="subject_uuid" id="subject_uuid" className="form-select ft-14 " onChange={(e) => { onChangeSubject(e.target.value) }}>
                                            <option value="">Select Subject</option>
                                            {subjectList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </FormBuilder>
                    <div className="text-end me-3">
                        {(!validationFilters() && showQuestions) ?
                            <button className="btn btn-primary cursor-pointer rounded-3 f14 py-1 px-4" type="button" onClick={() => handleRefresh()}>
                                Back
                            </button> :
                            <button className="btn btn-primary cursor-pointer rounded-3 f14 py-1 px-3" disabled={validationFilters()} type="button" onClick={() => getQuestionList()}>
                                Go Next
                            </button>
                        }
                    </div>
                    {/* // Question // */}
                    {(!validationFilters() && showQuestions) && <div>
                        <AssessmentAddQuestions filterData={filterData} standardName={standardName} subjectName={subjectName} standardsList={selectedStandardList} handledBack={handleRefresh} />
                    </div>}
                </div>
            }
        </div>
    )
}