import React, { useEffect, useState } from "react";
import { UI_BASE_URL } from "../../config/constant";
import { CREATE_CCAVENUE_PAYMENT } from "../../app/service/ccavenue.service";
import { toast } from "react-toastify";
declare const window: any;

export const CcavenusMobileView: React.FC = () => {
    const [webviewInitialized, setWebviewInitialized] = useState<boolean>(false);
    const [appData, setAppData] = useState<any>({});

    useEffect(() => {
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                let transcationId = localStorage.getItem("app_txn_id");
                if (transcationId && statusValue === "success") {
                    sendMessageToApp({ action: 'payment', type: 'payment_success', data: statusValue });
                } else {
                    sendMessageToApp({ action: 'payment', type: 'payment_fail', data: statusValue });
                }
                localStorage.removeItem("app_txn_id");
            } else {
                sendMessageToApp({ action: 'payment', type: 'payment_fail', data: 'Status parameter not found in the query string' });
            }
        }
    }, [])

    useEffect(() => {
        const isIos = navigator.userAgent.includes('iPhone');
        if (isIos) {
            window.addEventListener("message", handleNativeMessage);
        } else {
            document.addEventListener("message", handleNativeMessage);
        }
        return () => {
            window.removeEventListener('message', handleNativeMessage);
        };
    }, []);
    useEffect(() => {
        if (!webviewInitialized) {
            setWebviewInitialized(true);
            sendMessageToApp({ action: 'init_webview', type: '', data: 'initialized' });
        }

    }, [webviewInitialized]);
    function handleNativeMessage(message: any) {
        let data = JSON.parse(message.data);
        if (data?.key && data?.key === 'init') {
            setAppData(data);
            localStorage.setItem("token",
                data.token);
            localStorage.setItem("app_txn_id", data?.transaction_id);
            setTimeout(() => {
                createCcavenuePayment(data?.amount, data?.transaction_id);
            }, 100);
        } else {
        }
    }
    const sendMessageToApp = (data: any) => {
        if (window?.ReactNativeWebView) {
            try {
                window?.ReactNativeWebView.postMessage(JSON.stringify(data));
            } catch (error) {

            }
        }
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: `${UI_BASE_URL}/ccavenue/mobile-app/view`,
            cancel_url: `${UI_BASE_URL}/ccavenue/mobile-app/view`,

        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                // return
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    return (
        <div>

        </div>
    )

}