import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { GET_BOARD_FILTERS_LIST, GET_CHAPTER_FILTERS_LIST, GET_MEDIUM_FILTERS_LIST, GET_STANDARD_FILTERS_LIST, GET_SUBJECT_FILTERS_LIST, GET_TOPIC_FITERS_LIST } from '../../../../../app/service/abhyas-worksheet-filter.service';
import FormBuilder from '../../../../../components/form-builder';
import UploadsValidationStatusQuestionsList from './questions-list';




export const ContentQuestionValidationStatus = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [mediumLoading, setMediumLoading] = useState<boolean>(false);
    const [standardLoading, setStandardLoading] = useState<boolean>(false);
    const [subjectLoading, setSubjectLoading] = useState<boolean>(false);
    const [chapterLoading, setChapterLoading] = useState<boolean>(false);
    const [topicLoading, setTopicLoading] = useState<boolean>(false);
    const [boardsList, setBoardsList] = useState<any>([]);
    const [mediumList, setMediumList] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [subjectList, setSubjectList] = useState<any>([]);
    const [chapterList, setChapterList] = useState<any>([]);
    const [topicList, setTopicList] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>({});
    const [validatioStatus, setValidationStatus] = useState<string>("ALL");
    const [showQuestions, setshowQuestions] = useState<boolean>(false)
    const filterForm = useRef<any>({});
    const [key, setKey] = useState<number>(0);


    useEffect(() => {
        getBoardData();

    }, [])

    const getBoardData = () => {
        setLoading(true);
        GET_BOARD_FILTERS_LIST().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    setBoardsList(data);
                    setLoading(false);
                }
            }
        });
    }
    const handleInput = (data: any) => {
        let preData = { ...data.value }
        setFilterData(preData);
        setshowQuestions(false);
    };
    const onChangeBoard = (e: any) => {
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTopicList([]);
        if (e) {
            setMediumLoading(true);
            GET_MEDIUM_FILTERS_LIST(e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setMediumLoading(false);
                    } else {
                        const data = res['rows'];
                        setMediumList(data);
                        setMediumLoading(false);
                    }
                }
            });
        }
    }
    const onChangeMedium = (e: any) => {
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTopicList([]);
        if (e) {
            setStandardLoading(true)
            GET_STANDARD_FILTERS_LIST(filterData?.board_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setStandardLoading(false);
                    } else {
                        const data = res['rows'];
                        setStandardList(data);
                        setStandardLoading(false);
                    }
                }
            });
        }
    }
    const onChangeStandard = (e: any) => {
        setSubjectList([]);
        setChapterList([]);
        setTopicList([]);
        if (e) {
            setSubjectLoading(true);
            GET_SUBJECT_FILTERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setSubjectLoading(false);
                    } else {
                        const data = res['rows'];
                        setSubjectList(data);
                        setSubjectLoading(false);
                    }
                }
            });
        }
    }
    const onChangeSubject = (e: any) => {
        setChapterList([]);
        setTopicList([]);
        if (e) {
            setChapterLoading(true);
            GET_CHAPTER_FILTERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, filterData?.standard_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setChapterLoading(false);
                    } else {
                        const data = res['rows'];
                        setChapterList(data);
                        setChapterLoading(false);
                    }
                }
            });
        }
    }
    const onChangeChapter = (e: any) => {
        setTopicList([]);
        if (e) {
            setTopicLoading(true);
            GET_TOPIC_FITERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, filterData?.standard_uuid, filterData?.subject_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setTopicLoading(false);

                    } else {
                        const data = res['rows'];
                        setTopicList(data);
                        setTopicLoading(false);

                    }
                }
            });
        }
    }
    const onChangeTopic = (e: any, name?: string) => {
        filterData.topic_uuid = e;
    }

    const handleRefresh = () => {
        setFilterData({});
        setKey((prev) => prev + 1);
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTopicList([]);
        filterForm.current.reset();
    }
    const validationFilters = () => {
        if (filterData?.board_uuid && filterData?.medium_uuid && filterData?.standard_uuid && filterData?.subject_uuid && filterData?.chapter_uuid && filterData?.topic_uuid) {
            return false;
        } else {
            return true;
        }
    }
    const getQuestionList = () => {
        setshowQuestions(true);
    }

    return (
        <div key={key}>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className='my-3'>
                    <FormBuilder onUpdate={handleInput}>
                        <form ref={filterForm}>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className='mb-4'>
                                        <label>Board</label>
                                        <select name="board_uuid" id="board_uuid" className="form-select ft-14 " onChange={(e) => { onChangeBoard(e.target.value) }}>
                                            <option value="">Select Board</option>
                                            {boardsList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4 position-relative'>
                                        <label>Medium</label>
                                        <select name="medium_uuid" id="medium_uuid" className="form-select ft-14 " onChange={(e) => { onChangeMedium(e.target.value) }}>
                                            <option value="">Select Medium</option>
                                            {mediumList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Standard</label>
                                        <select name="standard_uuid" id="standard_uuid" className="form-select ft-14 " onChange={(e) => { onChangeStandard(e.target.value) }}>
                                            <option value="">Select Standard</option>
                                            {standardList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.standard}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Subject</label>
                                        <select name="subject_uuid" id="subject_uuid" className="form-select ft-14 " onChange={(e) => { onChangeSubject(e.target.value) }}>
                                            <option value="">Select Subject</option>
                                            {subjectList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Chapter</label>
                                        <select name="chapter_uuid" id="chapter_uuid" className="form-select ft-14 " onChange={(e) => { onChangeChapter(e.target.value) }}>
                                            <option value="">Select Chapter</option>
                                            {chapterList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Topic</label>
                                        <select name="topic_uuid" id="topic_uuid" className="form-select ft-14 " onChange={(e) => { onChangeTopic(e.target.value) }}>
                                            <option value="">Select Topic</option>
                                            {topicList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Validation status</label>
                                        <select name="status" id="status" className="form-select ft-14 " onChange={(e) => { setValidationStatus(e.target.value) }}>
                                            <option value="ALL">All</option>
                                            <option value="PENDING">Pending</option>
                                            <option value="VERIFIED">Verified</option>
                                            <option value="RE-PHRASE">Re-Phrase</option>
                                            <option value="HOLD">Hold</option>
                                            <option value="REJECT">Reject</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3 text-end">
                                    {(!validationFilters() && showQuestions) ?
                                        <button className="btn btn-secondary cursor-pointer mt-4 rounded-12 f14 py-1 px-4" type="button" onClick={() => handleRefresh()}>
                                            Clear
                                        </button> :
                                        <button className="btn btn-primary cursor-pointer mt-4 rounded-12 f14 py-1 px-3" disabled={validationFilters()} type="button" onClick={() => getQuestionList()}>
                                            Go to Question Validation
                                        </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </FormBuilder>
                    {/* // Question // */}
                    {(!validationFilters() && showQuestions) && <div>
                        <UploadsValidationStatusQuestionsList boardUuid={filterData?.board_uuid} mediumUuid={filterData?.medium_uuid} standardUuid={filterData?.standard_uuid} subjectUuid={filterData?.subject_uuid} chapterUuid={filterData?.chapter_uuid} topicUuid={filterData?.topic_uuid} validationStatus={validatioStatus}
                        />
                    </div>}
                </div>
            }
        </div>
    )
}

