import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { GET_MATHS_MODULE_REPORT_BY_STUDENT } from "../../../../app/service/mns-service";
import { MATHS_REPORT_DOWNLOAD } from "../../../../app/service/maths-reports";
import MathsReportData from "../../../../components/maths-reports/view-report";
import { MNSReport } from "../../../../components/maths-reports/mns";
import { MLATwoReport } from "../../../../components/maths-reports/mla-two";
import { MLAOneReport } from "../../../../components/maths-reports/mla-one";
export const SchoolStudentMathsReportView = () => {
    const [loading, setLoading] = useState(false);
    let { id, schoolCode, userCode } = useParams<{ id: string, schoolCode: string, userCode: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const usersCode = userCode;
    const [reportInfo, setReportInfo] = useState<any>({});
    const pdfRef = useRef<HTMLDivElement>(null);
    const currentUrl = window.location.href.split('#')[0];
    const SchoolNames = localStorage.getItem("name_of_school");
    const userSchoolName = localStorage.getItem('school_name');

    useEffect(() => {
        getReports();
    }, []);
    const getReports = async () => {
        setLoading(true);
        try {
            const res: any = await GET_MATHS_MODULE_REPORT_BY_STUDENT(userCode);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setReportInfo(res);
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }

    }

    const handleClick = async () => {
        let payLoad = {
            url: `${currentUrl}#/school-dashboard/profile/student/maths-report/${schoolId}/${schoolsCode}/${userCode}`,
            user_code: userCode,
            // token: localStorage.getItem("token")
        }
        setLoading(true);
        try {
            const res: any = await MATHS_REPORT_DOWNLOAD(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    if (res?.complete_url) {

                        handleDownload(res?.complete_url);
                    }
                }

            }
        } catch (error) {
            setLoading(false);
        }
    };



    const handleDownload = async (Url: any) => {
        try {
            const response = await axios.get(Url, {
                responseType: 'blob',
            });

            // Create a blob from the response data
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Create a link element
            const link = document.createElement('a');

            // Create a URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Set link attributes
            link.href = url;
            link.download = `${reportInfo?.user_name}_${reportInfo?.standard}th_MathReport.pdf`;  // You can set the file name here

            // Append link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean up
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the PDF', error);
        }

    };
    const handleDownloadlink = (url: string) => {
        // window.open(url, '_blank');
        const newTab = window.open(url, '_blank', 'noopener,noreferrer');

        // Check if the new tab was created successfully
        if (newTab === null) {
            toast.error('Please allow popups to open the file.');
        }
    };

    return (
        <>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to={`/school-dashboard/profile/students/list/${schoolId}/${schoolsCode}`}><span className='pe-2'>{userSchoolName ? userSchoolName : SchoolNames}</span></Link></li> /
                        <li className="breadcrumb-item "><span>Maths Report</span></li>
                    </ol>
                </nav>
                <div className="mt-3 text-end me-5">
                    <button className="btn btn-primary px-3 py-1" id="MATH_REPORT_DOWNLOAD" onClick={handleClick}>Download </button>
                </div>
                <div ref={pdfRef}
                    id="download-content"
                >
                    <div className="report_border mx-lg-5 mx-2 mt-3 mb-5 px-lg-4 px-1 py-3">
                        <div className="d-flex justify-content-end "><i className="report_ias_icon " style={{ width: "80px" }}></i></div>
                        <MathsReportData reportInfo={reportInfo} />
                        <div className="mx-lg-3">
                            <MNSReport mnsReportInfo={reportInfo?.mnsDetails} />
                            <MLAOneReport mlaOneReportInfo={reportInfo?.mlaOneDetails} />
                            <MLATwoReport mlaTwoReportInfo={reportInfo?.mlaTwoDetails} />
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}
