import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { CREATE_CONTENT_QUESTION, DELETE_CONTENT_QUESTION, GET_CONTENT_QUESTIONS, UPDATE_CONTENT_QUESTION } from "../../../../../app/service/content-module/question-service";
import { FILE_UPLOADS } from "../../../../../app/service/file-upload.service";
import DELETEICON from '../../../../../institute-dashboard-assets/images/question_Delete.svg';

interface Props {
    boardUuid: any;
    mediumUuid: any;
    standardUuid: any;
    subjectUuid: any;
    chapterUuid: any;
    topicUuid: any;
}

export const AddContentVideos: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [canShowDeletePopup, setCanShowDeletePopup] = useState<boolean>(false);
    const [currentFormData, setcurrentFormData] = useState<any>({});
    const [videoList, setVideoList] = useState<any>([]);
    const [name, setName] = useState<string>("");
    const [uploadResponse, setUploadResponse] = useState<any>({});
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);
    const [upload, setUpload] = useState<boolean>(false);
    const [contentType, setContentType] = useState<string>("VIDEO");
    const sourceForm = useRef<any>({});

    useEffect(() => {
        fetchData();
    }, [])
    const fetchData = async () => {
        setLoading(true);
        try {
            const payLoad = {
                board_uuid: props?.boardUuid,
                medium_uuid: props?.mediumUuid,
                standard_uuid: props?.standardUuid,
                subject_uuid: props?.subjectUuid,
                chapter_uuid: props?.chapterUuid,
                topic_uuid: props?.topicUuid,
                content_type: "VIDEO,AUDIO",
                limit: 100000,
                sorting_type: "ASC"
            }
            const res: any = await GET_CONTENT_QUESTIONS(payLoad, 0, 1000000);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.message);
                    setLoading(false);
                } else {
                    const data = res?.data;
                    setVideoList(data);
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }
    const editDeleteVideo = (item: any, type: string) => {
        setName(item?.question);
        setcurrentFormData(item);
        if (type === "delete") {
            setCanShowDeletePopup(true);
        } else {
            setCanShowPopup(true);
        }
    }
    const deleteSource = async (item: any) => {
        try {
            const res: any = await DELETE_CONTENT_QUESTION(item?.uuid);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.message);
                } else {
                    setCanShowDeletePopup(false);
                    fetchData();
                }
            }
        } catch (error) {
        }
    }
    const addNew = () => {
        setContentType("VIDEO")
        handleRefresh();
        setCanShowPopup(true);
    }
    const uploadAudio = () => {
        setContentType("AUDIO");
        handleRefresh();
        setCanShowPopup(true);
    }

    const handleSubmit = async () => {
        try {
            const payLoad = {
                "content_type": contentType,
                "board_uuid": props?.boardUuid,
                "medium_uuid": props?.mediumUuid,
                "standard_uuid": props?.standardUuid,
                "subject_uuid": props?.subjectUuid,
                "chapter_uuid": props?.chapterUuid,
                "topic_uuid": props?.topicUuid,
                "question": name,
                "question_info": `${uploadResponse?.base_url + uploadResponse?.url}`
            }
            const res: any = await CREATE_CONTENT_QUESTION(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.message);
                } else {
                    handleCancel();
                }
            }
        } catch (error) {
        }
    }
    const handleUpdate = async () => {
        try {
            currentFormData.question = name;
            currentFormData.question_info = `${uploadResponse?.base_urluploadResponse?.url}`;
            const res: any = await UPDATE_CONTENT_QUESTION(currentFormData?.uuid, currentFormData);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.message);
                } else {
                    handleCancel();
                }
            }
        } catch (error) {
        }
    }
    function handleCancel() {
        setName('');
        setcurrentFormData({});
        sourceForm.current.reset();
        setCanShowPopup(false);
        fetchData();
    }
    function handleRefresh() {
        setName('');
        setUploadResponse({});
        setcurrentFormData({});
        sourceForm.current.reset();
    }
    const handleNameChange = (value: any) => {
        setName(value);
    }
    function handleUpload(e: any) {
        if (e.target.files && e.target.files[0]) {
            let formData: any = new FormData();
            formData.append('file', e.target.files[0], e.target.files[0].name);
            setUploadLoading(true);
            setUploadResponse({});
            FILE_UPLOADS(formData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.error);
                    setUploadLoading(false);
                } else {
                    toast.success(res?.message);
                    setUploadResponse(res);
                    setUploadLoading(false);
                    if (!name) {
                        setName(e.target.files[0].name);
                    }
                }
            });
        }
    }
    const validForm = () => {
        console.log(uploadResponse, name, "name");

        if (name && uploadResponse?.base_url && uploadResponse?.url) {
            return false
        } else {
            return true
        }
    }
    return (
        <div>
            <div
                className={`modal fade ${canShowDeletePopup ? "show" : "false"}`}
                style={{ display: canShowDeletePopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog " role="document">
                    <div className="modal-content ">
                        <div className="modal-body  payment_user_popup_info position-relative">
                            <button type="button" className="close popup-close position-absolute" data-dismiss="modal" aria-label="Close" onClick={() => setCanShowDeletePopup(false)} style={{ right: "20px" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className='payment_user_popup_block '>
                                <div className='mt-1 d-flex justify-content-center'>
                                    <div className="text-start ">
                                        <h5 className='f18 payment_mem_text word_break'>{currentFormData?.question} </h5>
                                    </div>
                                </div>
                                <div className='start-test mt-2 justify-content-center'>
                                    <h6 className="text-center">Do you want to delete the {currentFormData?.content_type?.toLowerCase()} ? </h6>
                                </div>
                                <div className='mt-3'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        <button className='btn test-btn' onClick={() => deleteSource(currentFormData)}>Yes</button>
                                        <button className='btn test-btn' onClick={() => setCanShowDeletePopup(false)}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="payment_modal-dialog_popup " role="document">
                    <div className="modal-content pariksha-model-content" style={{ width: "500px" }}>
                        <div className="modal-body  payment_user_popup_info position-relative">
                            <button type="button" className="close popup-close position-absolute" data-dismiss="modal" aria-label="Close" onClick={handleCancel} style={{ right: "20px" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className='payment_user_popup_block '>
                                <div className='mt-1'>
                                    <h6 className="f20 border-bottom border-primary pb-3 text-center">{currentFormData?.question ? "Update" : "Add "} {contentType === "VIDEO" ? "Video" : "Audio"}</h6>
                                    <div className="row ">
                                        <div className="col-12  mt-3 mb-2">
                                            <form ref={sourceForm}>
                                                <label className="form-label">{contentType === "VIDEO" ? "Video" : "Audio"} Name <sup className="text-danger f16">*</sup></label>
                                                <input className="form-control form-control-lg f16 fw-normal" type="text" name="question" defaultValue={name} autoComplete="off" onChange={(e) => handleNameChange(e.target.value)} />
                                            </form>
                                            <div className="text-center">
                                                {uploadLoading &&
                                                    <div className="text-center mt-1 position-absolute" style={{ right: "109px", bottom: "108px" }}>
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only ">Loading...</span>
                                                        </div>
                                                    </div>
                                                }
                                                {contentType === "VIDEO" ? <div className="file btn  f14 questio_upload_btn text-white d-inline-block mt-4" style={{ marginRight: '10px', cursor: 'pointer' }}>Upload Video
                                                    <input type="file" accept="video/*" style={{ cursor: 'pointer' }} onChange={(e) => handleUpload(e)} onClick={(e: any) => {
                                                        e.target.value = null
                                                    }} />
                                                </div> :
                                                    <div className="file btn  f14 questio_upload_btn text-white d-inline-block mt-4" style={{ marginRight: '10px', cursor: 'pointer' }}>Upload Audio
                                                        <input type="file" accept="audio/*" style={{ cursor: 'pointer' }} onChange={(e) => handleUpload(e)} onClick={(e: any) => {
                                                            e.target.value = null
                                                        }} />
                                                    </div>}
                                                {(uploadResponse?.base_url && uploadResponse?.url) && <p className="text-success mt-1 mb-0 f14">Successfully uploaded file!</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        {currentFormData?.question ? <button className='btn question_source_btn  f18 px-4  payment_mem_text py-2' onClick={handleUpdate} disabled={validForm()}>Update</button>
                                            : <button className='btn question_source_btn  f18 px-4  payment_mem_text py-2' onClick={handleSubmit} disabled={validForm()}>Submit</button>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row border-top mt-2 pt-4">
                <div className="col-12">
                    <div className="d-flex justify-content-between">
                        <h6 className=" pb-3 f20">Question's Video & Audio</h6>
                        <div className='export_btn me-2 me-sm-4'>
                            <button
                                className="export_btn_select f14"
                                onClick={() => setUpload(!upload)}
                            >
                                <span>Add New
                                    <i className='bi bi-chevron-down ps-3'></i></span>
                            </button>
                            {upload && <ul className='export_btn_select_drop_list z_100'>
                                <li className='export_btn-list-view' onClick={() => addNew()}>
                                    <span> Video</span></li>
                                <li className='export_btn-list-view' onClick={uploadAudio}>
                                    <span> Audio</span></li>
                            </ul>}
                        </div>
                    </div>
                    <div>
                        {loading &&
                            <div className="text-center p-5">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!loading && <div className="row  mt-5">
                            {videoList?.map((data: any, i: number) => (
                                <div className="col-12 col-md-4 col-lg-3 " key={i}>
                                    <div className="card border border-2 mb-4">
                                        {data?.content_type === "VIDEO" ?
                                            <video src={data?.question_info} className="card-img-top" controls></video>
                                            :
                                            <audio src={data?.question_info} className="card-audio-top w_100_p" controls></audio>}
                                        <div className="card-body pb-1">
                                            <h6 className="card-title">
                                                <span className=" f14 fw-bold">{i + 1}. </span>
                                                {data?.question}</h6>
                                            <div className="text-end">
                                                <img src={DELETEICON} title="Delete" className="cursor-pointer p-1 "
                                                    onClick={() => editDeleteVideo(data, "delete")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>))}
                            {videoList?.length == 0 && <p className="f16 text-center mt-2">No data found</p>}
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}