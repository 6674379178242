import React, { useEffect, useState } from 'react'
import { GET_INSTITUTE_BY_CODE } from '../../../app/service/institute.service';
import { toast } from 'react-toastify';
import appConfig from '../../../config/constant';
import { GET_USERS_BY_INSTITUTES_CODE } from '../../../app/service/users.service';
import IMGLOGO from '../../../institute-dashboard-assets/images/bala-bavan-logo.svg'
import CHAIRMANLOGO from '../../../institute-dashboard-assets/images/chairman-icon.svg'
import GENERALSECRETARY from '../../../institute-dashboard-assets/images/generalsecretary-icon.svg'
import INSTITUTEADMIN from '../../../institute-dashboard-assets/images/institute-admin-icon.svg'
import { InstituteProfileAdminForm } from './admin/form';
import { InstituteProfileChairmanForm } from './chairman/form';
import { InstituteProfileGeneralSecretaryForm } from './generalsecratary/form';


export const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [instituteInfo, setInstituteInfo] = useState<any>({});
  const orgCode: any = localStorage.getItem('organization_code');
  const [chairmanInfo, setChairmanInfo] = useState<any>({});
  const [generalsecretaryInfo, setGeneralsecretaryInfo] = useState<any>({});
  const [adminInfo, setAdminInfo] = useState<any>({});
  const organizationName = localStorage.getItem("organization_name");
  const [adminView, setAdminView] = useState<boolean>(false);
  const [chairmanView, setChairmanView] = useState<boolean>(false);
  const [generalsecretaryView, setGeneralsecretaryView] = useState<boolean>(false);
  const userRole = localStorage.getItem("user_role");



  useEffect(() => {
    setLoading(true);
    GET_INSTITUTE_BY_CODE(orgCode).then((res: any) => {
      if (res.status === 'fail') {
        toast.error(res.message);
        setLoading(false);
      } else {
        if (res) {
          const data = res.rows[0];
          setInstituteInfo(data);
          setLoading(false);
        }
      }
    });

    getChairmans();
    getGeneralsecretary();
    getInstituteAdmin();
  }, [])

  function getChairmans() {
    setLoading(true);
    GET_USERS_BY_INSTITUTES_CODE(orgCode, 'CHAIRMAN', {}).then((res: any) => {
      if (res.status === 'fail') {
        toast.error(res.message);
        setLoading(false);
      } else {
        if (res) {
          const data = res.rows[0];
          setChairmanInfo(data);
          setLoading(false);
        }
      }
    });

  }

  function getGeneralsecretary() {
    setLoading(true);
    GET_USERS_BY_INSTITUTES_CODE(orgCode, 'GENERAL_SECRETARY', {}).then((res: any) => {
      if (res.status === 'fail') {
        toast.error(res.message);
        setLoading(false);
      } else {
        if (res) {
          const data = res.rows[0];
          setGeneralsecretaryInfo(data);
          setLoading(false);
        }
      }
    });
  }

  function getInstituteAdmin() {
    setLoading(true);
    GET_USERS_BY_INSTITUTES_CODE(orgCode, 'INSTITUTE_ADMIN', {}).then((res: any) => {
      if (res.status === 'fail') {
        toast.error(res.message);
        setLoading(false);
      } else {
        if (res) {
          const data = res.rows[0];
          setAdminInfo(data);
          setLoading(false);
        }
      }
    });
  }
  const openInstituteDetails = (show: any) => {
    if (show === "adminview") {
      setAdminView(true);
    }
    else if (show === "chairmanview") {
      setChairmanView(true);
    } else if (show === "generalsecretary") {
      setGeneralsecretaryView(true);
    }

  }
  function handleUpdateCancel() {
    if (adminView) {
      setAdminView(false);
      getInstituteAdmin();
    }
    else if (chairmanView) {
      setChairmanView(false);
      getChairmans();
    } else if (generalsecretaryView) {
      setGeneralsecretaryView(false);
      getGeneralsecretary();
    }

  }
  return (
    <div>
      {loading &&
        <div className="text-center p-5 mt-5">
          <div className="spinner-border mt-5" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
      {!loading &&
        <div>
          <div className="d-flex flex-wrap flex-md-nowrap justify-content-between">
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
              <li className="breadcrumb-item text-primary"><span className='pe-2'>{organizationName}</span></li> /
              <li className="breadcrumb-item">Profile</li>
            </ol>
          </nav>
          <div className="border-bottom pb-3">
            <div className="d-md-flex sc-profile-details">
              <div className="mb-3 mb-md-0">
                <div className="sc-profile-img py-0">
                  <img className='pr-logo' src={instituteInfo?.["logo_url"] ? `${appConfig.FILE_UPLOAD_URL}${instituteInfo?.["logo_url"].charAt(0) === "/" ? instituteInfo?.["logo_url"].substring(1) : instituteInfo?.["logo_url"]}` : IMGLOGO} alt="" />
                </div>
              </div>
              <div className="sc-profile-text ps-md-4">
                <h2 className="m-0">{instituteInfo?.["name_of_organization"]}</h2>
                <p className="m-0 sc-profile-contact"><span>{instituteInfo?.["contact_number"]}</span>{instituteInfo?.["email_id"] && <span>|</span>}  <span>{instituteInfo?.["email_id"]}</span>
                </p>
                <p className="m-0 sc-profile-addrs"><span>{instituteInfo?.["state"]}</span> | <span>{instituteInfo?.["district"]}</span>{instituteInfo?.["city_village"] && <span>|</span>}
                  <span>{instituteInfo?.["city_village"]}</span>  </p>
                <p className="mt-3">
                  <a className="btn btn-primary sc-profile-viewbtn collapsed" data-bs-toggle="collapse"
                    href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">View More
                    Information <i className="view-arrow"></i></a>
                </p>
              </div>
            </div>
            <div className="collapse ms-lg-5 ps-lg-5" id="collapseExample">
              <div className='ps-2   ms-lg-5'>
                <div className='row'>
                  <div className='col-md-4 col-sm-6  mb-4'>
                    <label className="f12 text-mgray">Display Name</label>
                    <p className="f18 mb-0">{instituteInfo?.["display_name"]}</p>
                  </div>
                  <div className='col-md-4 col-sm-6  mb-4'>
                    <label className="f12 text-mgray">Registration Number</label>
                    <p className="f18 mb-0">{instituteInfo?.["registration_number"]}</p>
                  </div>
                  <div className='col-md-4 col-sm-6  mb-4'>
                    <label className="f12 text-mgray">Date of Registration</label>
                    <p className="f18 mb-0">{instituteInfo?.["date_of_registration"]}</p>
                  </div>
                  <div className='col-md-4 col-sm-6  mb-4'>
                    <label className="f12 text-mgray">Contact Number</label>
                    <p className="f18 mb-0">{instituteInfo?.["contact_number"]}</p>
                  </div>
                  <div className='col-md-4 col-sm-6  mb-4'>
                    <label className="f12 text-mgray">Fax Number</label>
                    <p className="f18 mb-0">{instituteInfo?.["fax_number"]}</p>
                  </div>
                  <div className='col-md-4  col-sm-6  mb-4'>
                    <label className="f12 text-mgray">Office Timings</label>
                    <p className="f18 mb-0">{instituteInfo?.["timings"]}</p>
                  </div>
                </div>
                <label className="f12 text-mgray">Head Quarter Address</label>
                <p className="f18 mb-1">{instituteInfo?.address}</p>
                <div className='row mt-3'>
                  <div className='col-md-4  col-sm-6  mb-4'>
                    <label className="f12 text-mgray">State</label>
                    <p className="f18 mb-0">{instituteInfo?.["state"]}</p>
                  </div>
                  <div className='col-md-4  col-sm-6  mb-4'>
                    <label className="f12 text-mgray">District</label>
                    <p className="f18 mb-0">{instituteInfo?.["district"]}</p>
                  </div>
                  <div className='col-md-4  col-sm-6  mb-4'>
                    <label className="f12 text-mgray">Block</label>
                    <p className="f18 mb-0">{instituteInfo?.["area"]}</p>
                  </div>

                  <div className='col-md-4  col-sm-6  mb-4'>
                    <label className="f12 text-mgray">Area/Locality</label>
                    <p className="f18 mb-0">{instituteInfo?.["area"]}</p>
                  </div>
                  <div className='col-md-4   col-sm-6  mb-4'>
                    <label className="f12 text-mgray">City / Vilage</label>
                    <p className="f18 mb-0"> {instituteInfo?.["city_village"]}</p>
                  </div>
                  <div className='col-md-4  col-sm-6  mb-4'>
                    <label className="f12 text-mgray">Pincode</label>
                    <p className="f18 mb-0"> {instituteInfo?.["pin_code"]}</p>
                  </div>

                  <div className='col-md-4  col-sm-6  mb-4'>
                    <label className="f12 text-mgray">GST Number</label>
                    <p className="f18 mb-0"> {instituteInfo?.["gst_number"]}</p>
                  </div>
                  <div className='col-md-4  col-sm-6  mb-4'>
                    <label className="f12 text-mgray">PAN Number</label>
                    <p className="f18 mb-0"> {instituteInfo?.["pan_number"]}</p>
                  </div>
                  <div className='col-md-4  col-sm-6  mb-4'>
                    <label className="f12 text-mgray">website address</label>
                    <p className="f18 mb-0"> {instituteInfo?.["website_url"]}</p>
                  </div>

                  <div className='col-md-4  col-sm-6  mb-4'>
                    <label className="f12 text-mgray"> Number of Primary School (1 to 5)</label>
                    <p className="f18 mb-0">{instituteInfo?.["num_of_primary_school"]}</p>
                  </div>
                  <div className='col-md-4  col-sm-6  mb-4'>
                    <label className="f12 text-mgray"> Number of Secondary School(6 to 10)</label>
                    <p className="f18 mb-0">{instituteInfo?.["num_of_secondary_school"]}</p>
                  </div>
                  <div className='col-md-4  col-sm-6  mb-4'>
                    <label className="f12 text-mgray"> Number of Junior College(11, 12)</label>
                    <p className="f18 mb-0">{instituteInfo?.["num_of_junior_college"]}</p>
                  </div>
                  <div className='col-md-4  col-sm-6 mb-4 '>
                    <label className="f12 text-mgray"> Number of Senior College</label>
                    <p className="f18 mb-0">{instituteInfo?.["num_of_senior_college"]}</p>
                  </div>
                  <div className='col-md-4  col-sm-6 mb-4'>
                    <label className="f12 text-mgray"> Number of Professional Colleges</label>
                    <p className="f18 mb-0">{instituteInfo?.["num_of_professional_colleges"]}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <h3 className="my-4 section-hd section-hd">Institute Administration wing details</h3>
          <div className="d-flex sc-profile-details small mb-4">
            <div className="sc-profile-img pt-0">
              <img className='pr-image' src={chairmanInfo?.["profile_img"] ? `${appConfig.FILE_UPLOAD_URL}${chairmanInfo?.["profile_img"].charAt(0) === "/" ? chairmanInfo?.["profile_img"].substring(1) : chairmanInfo?.["profile_img"]}` : CHAIRMANLOGO} alt="" />
            </div>
            <div className="sc-profile-text ps-4">
              <p className="sc-profile-deg mb-1">Chairman {(userRole === "INSTITUTE_ADMIN" || userRole === "CHAIRMAN") && <span onClick={() => openInstituteDetails("chairmanview")}><i className='bi bi-pencil-square cursor-pointer edit-icon p-2'></i></span>}</p>
              <h2 className="m-0">{chairmanInfo?.['name']}</h2>
              <p className="m-0 sc-profile-contact"><span>{chairmanInfo?.['mobile_number']}</span> {chairmanInfo?.['email_id'] && <span> | </span>}  <span>{chairmanInfo?.['email_id']}</span></p>
              <p className="m-0 sc-profile-addrs"><span>{chairmanInfo?.['state']}</span> {chairmanInfo?.['district'] && <span>|</span>}<span>{chairmanInfo?.['district']}</span> </p>
            </div>
          </div>
          {chairmanView && <InstituteProfileChairmanForm userId={chairmanInfo?.id} instituteCode={instituteInfo?.organization_code} handleUpdateCancel={handleUpdateCancel} setChairmanView={setChairmanView} />}

          <div className="d-flex sc-profile-details small mb-4">
            <div className="sc-profile-img pt-0">
              <img className='pr-image' src={generalsecretaryInfo?.["profile_img"] ? `${appConfig.FILE_UPLOAD_URL}${generalsecretaryInfo?.["profile_img"].charAt(0) === "/" ? generalsecretaryInfo?.["profile_img"].substring(1) : generalsecretaryInfo?.["profile_img"]}` : GENERALSECRETARY} alt="" />
            </div>
            <div className="sc-profile-text ps-4">
              <p className="sc-profile-deg mb-1">General Secretary {(userRole === "INSTITUTE_ADMIN" || userRole === "GENERAL_SECRETARY") && <span onClick={() => openInstituteDetails("generalsecretary")}><i className='bi bi-pencil-square cursor-pointer edit-icon p-2'></i></span>}</p>
              <h2 className="m-0">{generalsecretaryInfo?.['name']}</h2>
              <p className="m-0 sc-profile-contact"><span>{generalsecretaryInfo?.['mobile_number']}</span> {generalsecretaryInfo?.['email_id'] && <span> | </span>} <span>{generalsecretaryInfo?.['email_id']}</span></p>
              <p className="m-0 sc-profile-addrs"><span>{generalsecretaryInfo?.['state']}</span>{generalsecretaryInfo?.['district'] && <span>|</span>} <span>{generalsecretaryInfo?.['district']}</span> </p>
            </div>
          </div>
          {generalsecretaryView && <InstituteProfileGeneralSecretaryForm userId={generalsecretaryInfo?.id} instituteCode={instituteInfo?.organization_code} handleUpdateCancel={handleUpdateCancel} setGeneralsecretaryView={setGeneralsecretaryView} />}

          <div className="d-flex sc-profile-details small mb-4">
            <div className="sc-profile-img pt-0">
              <img className='pr-image' src={adminInfo?.["profile_img"] ? `${appConfig.FILE_UPLOAD_URL}${adminInfo?.["profile_img"].charAt(0) === "/" ? adminInfo?.["profile_img"].substring(1) : adminInfo?.["profile_img"]}` : INSTITUTEADMIN} alt="" />
            </div>
            <div className="sc-profile-text ps-4">
              <p className="sc-profile-deg mb-1">Institute Admin {userRole === "INSTITUTE_ADMIN" && <span onClick={() => openInstituteDetails("adminview")}><i className='bi bi-pencil-square cursor-pointer edit-icon p-2'></i></span>}</p>
              <h2 className="m-0">{adminInfo?.['name']}</h2>
              <p className="m-0 sc-profile-contact"><span>{adminInfo?.['mobile_number']}</span>{adminInfo?.['email_id'] && <span> | </span>}<span>{adminInfo?.['email_id']}</span></p>
              <p className="m-0 sc-profile-addrs"><span>{adminInfo?.['state']}</span> {adminInfo?.['district'] && <span>|</span>} <span>{adminInfo?.['district']}</span> </p>
            </div>
            {adminView && <InstituteProfileAdminForm userId={adminInfo?.id} instituteCode={instituteInfo?.organization_code} handleUpdateCancel={handleUpdateCancel} setAdminView={setAdminView} />}

          </div>
        </div>}
    </div>
  )
}
