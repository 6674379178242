import React from 'react'
import { Route, Switch } from 'react-router'
import { ContentParikshaTestInfo } from '../../pages/dashboard/content/pariksha-test/test/list'
import { AssessmentViewList } from '../../pages/dashboard/content/pariksha-test/view-test/list'
import { AssessmentTestList } from '../../pages/dashboard/content/pariksha-test/assessment-test/list'


const NestedTests = () => {
    return (
        <Switch>
            <Route path="/dashboard/content/test/test-creation" component={ContentParikshaTestInfo}></Route>
            <Route path="/dashboard/content/test/assessment-view" component={AssessmentViewList}></Route>
            <Route path="/dashboard/content/test/assessment-test" component={AssessmentTestList}></Route>

        </Switch>
    )
}

export default NestedTests