import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GET_USER_USER_CODE } from '../../../../app/service/users.service';
import { GET_STANDARD_FILTER } from '../../../../app/service/abhyas-standard.service';
import { GET_BOARD_FILTER } from '../../../../app/service/abhyas-board.service';
import { GET_SUBJECT_FILTER_LIST } from '../../../../app/service/common-module.service';
import { GET_SCHOOL_BY_SCHOOL_CODE } from '../../../../app/service/school.service';
import { GET_MEDIUM_FILTER } from '../../../../app/service/abhyas-medium.service';
import IMPORTANCEOFEDUCATION from "../../../../institute-dashboard-assets/images/importance-of-education.png"
import { FETCH_ABHYAS_USER_QUESTION_INFO, FETCH_ABHYAS_USER_QUESTION_PAPER } from '../../../../app/service/abhyas-module-questions.service';


export const AbhyasTestLevelStatusView = () => {
    const [loading, setLoading] = useState(false);
    const [subjectList, setSubjectList] = useState<any>([]);
    const history = useHistory();
    const [canShowSubject, setCanShowSubject] = useState<Boolean>(false);
    const [canShowAssement, setCanShowAssement] = useState<Boolean>(false);
    const [currentCycleInfo, setCurrentCycleInfo] = useState<any>(null);
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [currentTestData, setCurentTestData] = useState<any>({});
    const [subjectData, setSubjectData] = useState<any>({});
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    const modules_uuid = moduleUUID;
    const user_code = userCode;
    const school_code = schoolCode;
    let standardNo: any = "";
    let subjectname: string = "";
    let mediumName: string = "";
    const [selectUuid, setSelectUuid] = useState<any>({
        school_code: school_code,
        user_code: user_code
    });
    useEffect(() => {
        getUserDetails();
    }, []);

    function getUserDetails() {
        setLoading(true);
        // window.scrollTo(0, 0);
        GET_USER_USER_CODE(user_code).then((response: any) => {
            if (response.status === 'fail') {
                const errorValue = Array.isArray(response?.err) ? response?.err[0] : response?.err;
                toast.error(errorValue);
                setLoading(false);
            } else {
                const data = response.rows[0];
                standardNo = data?.standard;
                mediumName = data?.medium;
                if (data?.indv_school_details?.school_board) {
                    getBoardUuid(data?.indv_school_details?.school_board);
                } else {
                    getSchoolData();
                }
                // getStandardData(data);
            }
        });
    }
    const getSchoolData = () => {
        GET_SCHOOL_BY_SCHOOL_CODE(school_code).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res?.rows[0];
                    getBoardUuid(data?.curriculum);
                }
            }
        });
    }
    const getBoardUuid = (boardName: string) => {
        let payLoad = {
            search: boardName
        }
        GET_BOARD_FILTER(payLoad).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    if (Array.isArray(res?.rows)) {
                        const data = res?.rows?.find((item: any) => item?.name?.trim().toLowerCase() === boardName?.trim().toLowerCase());
                        selectUuid.board_uuid = data?.uuid;
                        getMediumUuid(data?.uuid);
                    }
                }
            }
        });
    }
    const getMediumUuid = (bordUuid: any) => {
        let payLoad = {
            search: mediumName

        }
        GET_MEDIUM_FILTER(payLoad, bordUuid).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    if (Array.isArray(res?.rows)) {
                        const data = res?.rows?.find((item: any) => item?.name?.trim().toLowerCase() === mediumName?.trim().toLowerCase());
                        selectUuid.medium_uuid = data?.uuid;
                        getStandardUuid(bordUuid, data?.uuid);
                    }
                }
            }
        });
    }
    const getStandardUuid = (bordUuid: any, mediumUuid: any) => {

        let payLoad = {
            search: parseInt(standardNo)
        }
        GET_STANDARD_FILTER(payLoad, bordUuid, mediumUuid).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    if (Array.isArray(res?.rows)) {
                        const data = res?.rows?.find((item: any) => item?.standard === parseInt(standardNo));
                        selectUuid.standard_uuid = data?.uuid;
                        getSubjectList(data?.uuid);
                    }
                }
            }
        });
    }
    const getSubjectList = (standardUuid: any) => {

        GET_SUBJECT_FILTER_LIST(standardUuid).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res['rows'];
                    setSubjectList(data);
                    setLoading(false);

                }
            }
        });
    }
    const onChangeSubject = (subjectUuid: any) => {
        selectUuid.subject_uuid = subjectUuid;
        localStorage.setItem('user_uuids_Info', JSON.stringify(selectUuid));
        history.push(`/student-dashboard/list/abhyas/progress-view/${modules_uuid}/${user_code}/${school_code}/${selectUuid?.board_uuid}/${selectUuid?.medium_uuid}/${selectUuid.standard_uuid}/${subjectUuid}`)
    }

    const onClickSubject = () => {
        setCanShowSubject(!canShowSubject);
        setCanShowAssement(false);
    }
    const onClickAssessment = () => {
        setCanShowAssement(!canShowAssement);
        setCanShowSubject(false);
    }
    const onSelectTest = (subjectUuid: any, subjectData: any) => {
        selectUuid.subject_uuid = subjectUuid;
        subjectname = subjectData?.name;
        setSubjectData(subjectData);
        getAbhyasCompleteInfo(subjectUuid);
    }
    function getAbhyasCompleteInfo(subjectUuid: any) {
        let payload = {
            module_uuid: modules_uuid,
            school_code: school_code,
            user_code: user_code,
            cycle_no: '',
            board_uuid: selectUuid?.board_uuid,
            medium_uuid: selectUuid?.medium_uuid,
            standard_uuid: selectUuid?.standard_uuid,
            subject_uuid: subjectUuid
        }
        localStorage.setItem('user_uuids_Info', JSON.stringify(payload));
        localStorage.setItem('userSelectedSubjectInfo', JSON.stringify(payload));
        FETCH_ABHYAS_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    setCurrentCycleInfo(data);
                    let status = data?.tests?.every((x: any) => x.status === 'completed');
                    if (status) {
                        toast.success(`${subjectname?.toUpperCase()} Tests Completed `);
                        return;
                    }
                    else if (data.cycle_no > 1 && !status) {
                        getRequestedCycleData(data.cycle_no - 1);
                    } else {
                        let testStatus = data?.tests?.find((x: any) => x.status === 'pending');
                        if (testStatus?.status === 'pending' && testStatus?.question_paper_uuid) {
                            getAbhyaasQuestionsData(data, testStatus);
                            setCurentTestData(testStatus);
                        } else {
                            if (testStatus?.question_paper_uuid === null || testStatus?.question_paper_uuid === "" || testStatus?.question_paper_uuid === 'undefined') {
                                // toast.error(`No Questions available in This Test-${testStatus?.test_no}`);
                                toast.error(`There are no Assement Tests in this ${subjectname?.toUpperCase()}`);

                            }
                        }
                    }

                }
            }
        });
    }
    function getRequestedCycleData(cycle_no: any) {
        let payload = {
            "module_uuid": modules_uuid,
            "school_code": school_code,
            "user_code": user_code,
            "cycle_no": cycle_no ? cycle_no : '',
            "board_uuid": selectUuid?.board_uuid,
            "medium_uuid": selectUuid?.medium_uuid,
            "standard_uuid": selectUuid?.standard_uuid,
            "subject_uuid": selectUuid?.subject_uuid
        }
        FETCH_ABHYAS_USER_QUESTION_INFO(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
            } else {
                if (res) {
                    const data = res;
                    let testStatus = data?.tests?.find((x: any) => x.status === 'pending');
                    if (testStatus?.status === 'pending' && testStatus?.question_paper_uuid) {
                        getAbhyaasQuestionsData(data, testStatus);
                        setCurentTestData(testStatus);
                    }
                    else {
                        if (testStatus?.question_paper_uuid === null || testStatus?.question_paper_uuid === "" || testStatus?.question_paper_uuid === 'undefined') {
                            // toast.error(`No Questions available in This Test-${testStatus?.test_no}`);
                            toast.error(`There are no Assement Tests in this ${subjectname?.toUpperCase()}`);
                        }
                    }
                }
            }
        });
    }
    const getAbhyaasQuestionsData = (questionData: any, testInfo: any) => {
        testInfo.subject_name = subjectname?.charAt(0).toUpperCase() + subjectname?.slice(1).toLowerCase();
        localStorage.setItem("backword_key", "false");
        localStorage.setItem('abhyas_test_info', JSON.stringify(testInfo));
        localStorage.setItem('abhyas_type', 'TESTS');
        localStorage.setItem('abhyas_cycle_no', questionData.cycle_no);
        getAbhyaasQuestionsList(testInfo, "TESTS")
    }
    function getAbhyaasQuestionsList(testInfo: any, type: any) {
        let payload = {
            "type": type,
            "uuid": testInfo?.question_paper_uuid
        }
        FETCH_ABHYAS_USER_QUESTION_PAPER(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setLoading(false);
                } else {
                    if (res) {
                        if (res) {
                            if (Array.isArray(res)) {
                                setQuestionsData(res);
                                setCanShowPopup(true);
                            } else {
                                toast.error("Questions not found");

                            }
                        }
                    }
                }
            }
        });
    }
    const onClickSave = () => {
        history.push(`/student-dashboard/list/abhyas/questions/${modules_uuid}/${user_code}/${school_code}/${selectUuid.subject_uuid}`);
    }
    const onClickCancel = () => {
        setCanShowPopup(false);
    }
    return (
        <div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog pariksha-model-dialog" role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body pariksha-model-info">
                            <div className='pariksha-model-border ba bas-dashed '>
                                <div className='test-header '>
                                    <h5>Test Instructions</h5>
                                </div>
                                <ol className='ab_custom_list '>
                                    <li>Number of questions : {questionsData?.length} </li>
                                    <li>Time limit to solve test : 60 Minutes</li>
                                    <li>Result will be genarated immediately  after submission of the
                                        Assessment  Test -{currentCycleInfo?.tests[0]?.test_no}
                                        {currentCycleInfo?.tests[1]?.test_no && ` and Assessment Test -${currentCycleInfo?.tests[1]?.test_no}`}.
                                    </li>
                                </ol>

                                <div className='start-test mt-4 justify-content-center'>
                                    <h6>Do you want to start {subjectData?.name?.toUpperCase()}  Assessment Test -{currentTestData.test_no} ? </h6>
                                </div>
                                <div className='mt-4'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        <button className='btn test-btn' onClick={onClickSave}>YES</button>
                                        <button className='btn test-btn' onClick={onClickCancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&

                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><span>Abhyaas</span></li>
                        </ol>
                    </nav>
                    <h5 className="text-center mt-4 mb-0">Welcome to ABHYAAS</h5>
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-12 text-center text-lg-start">
                            <div>
                                <img src={IMPORTANCEOFEDUCATION} className="ed_img" alt="education" ></img>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 text-center text-lg-start">
                            <div>
                                <div className='mt-1 mt-md-5 ab_test_select'>
                                    <button className="ab_select mt-5" onClick={onClickAssessment}> Assessment
                                        {!canShowAssement && <i className="bi bi-chevron-right ps-3 ab_ass"></i>}
                                        {canShowAssement && <i className="bi bi-chevron-down ps-3 ab_ass"></i>}
                                    </button>
                                    {(canShowAssement && subjectList.length > 0) && <ul className='ab_select_drop_list z_100'>
                                        {subjectList?.map((item: any, i: number) => (
                                            <li className='subject-list-view cursor-pointer' key={i} onClick={() => onSelectTest(item?.uuid, item)}>{item?.name?.toUpperCase()}</li>
                                        ))}
                                    </ul>}
                                </div>
                            </div>
                            <div className='mt-5 ab_test_select'>
                                <button className="ab_select mt-5" onClick={onClickSubject}> Subject
                                    {!canShowSubject && <i className="bi bi-chevron-right ps-3 ab_icon"></i>}
                                    {canShowSubject && <i className="bi bi-chevron-down ps-3 ab_icon"></i>}
                                </button>
                                {(canShowSubject && subjectList.length > 0) && <ul className='ab_select_drop_list z_100'>
                                    {subjectList?.map((item: any, i: number) => (
                                        <li className='subject-list-view cursor-pointer' key={i} onClick={() => onChangeSubject(item?.uuid)}>{item?.name?.toUpperCase()}</li>
                                    ))}
                                </ul>}
                            </div>
                        </div>
                    </div>

                </div>
            }

        </div>
    )
}