import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import TimerInfo from '../dashboard/mns/timer/timerinfo';
import { FETCH_ABHYAS_USER_QUESTION_PAPER, UPDATE_ABHYAS_USER_QUESTION_PAPER } from '../../app/service/abhyas-module-questions.service';
import { UPDATE_PARIKSHA_USER_QUESTION_PAPER } from '../../app/service/pariksha-module-questions';

declare const window: any;


export const AbhyaasMobileQuestionsForm = () => {
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [canShowHeader, setShowHeader] = useState(false);
    const [startTime, setStartTime] = useState(new Date());
    const [alphabets, setAlphabets] = useState<any>(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    let { moduleUUID, userCode, schoolCode, subjectUuid } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string, subjectUuid: string }>();
    const [minutes, setMinutes] = useState(60);
    const [seconds, setSeconds] = useState(0);
    let completedTime: string = "";
    let time_remain_storage = '';
    let timeUp = false;
    const [isActive, setIsActive] = useState<boolean>(false);
    const questionRefs = useRef<(HTMLInputElement | null)[]>([]);
    // const type = sessionStorage.getItem("abhyas_type");
    // const testInfo: any = JSON.parse(sessionStorage.getItem("abhyas_test_info") || '{}');
    // const cycleNumber = sessionStorage.getItem("abhyas_cycle_no");
    // const worksheets: any = JSON.parse(sessionStorage.getItem('abhyas_worksheets') || '{}');
    const [appData, setAppData] = useState<any>({});
    const [timeRemainForApp, setTimeRemainForApp] = useState<string>('');
    const [webviewInitialized, setWebviewInitialized] = useState<boolean>(false);
    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [showQuestions, setShowQuestions] = useState<boolean>(true);
    const [viewQuestions, setViewQuestions] = useState<boolean>(false);
    const [attemptQuestionCount, setAttemptQuestionCount] = useState<number>(0);

    ////
    let preDataInfo = {
        "key": "init",
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7fSwiZXhwIjoxNzEzNjk0MjY2LCJpYXQiOjE3MTM2NzYyNjZ9.6ofJZExOxtTjoseNIRwiiKDz_UWCOJUYeKP-M-VlocE",
        "abhyas_test_start_date": "",
        "module_code": "ABHYAAS",
        "user_code": "USER_6bbf98b0-9aea-4a01-ae95-a21e4c5fdc34",
        "school_code": "sch_3e4b1fa7-3a22-473d-8396-a9b83a291290",
        "subject_uuid": "3be910a1-0c35-4b05-8bb1-7882db3af972",
        "cycle_no": 1,
        "type": "TESTS",
        "test_no": 1,
        "question_paper_uuid": "0b7b324e-f763-4206-b103-6b3b8dff2d9e",
        "question_paper_level": "",
        "priority": "",
        "topic_uuid": "",
        "worksheet_uuid": "",
        "topic_name": "",
        "name": "",
        "worksheets": [],
        "test_uuid": ""
    }

    useEffect(() => {
        const isIos = navigator.userAgent.includes('iPhone');
        if (isIos) {
            window.addEventListener("message", handleNativeMessage);
        } else {
            document.addEventListener("message", handleNativeMessage);
        }

        return () => {
            window.removeEventListener('message', handleNativeMessage);
        };
    }, []);


    useEffect(() => {
        if (!webviewInitialized) {
            setWebviewInitialized(true);
            sendMessageToApp({ action: 'init_webview', type: '', data: 'initialized' });
        }

    }, [webviewInitialized]);
    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isActive) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isActive]);

    function handleNativeMessage(message: any) {
        let data = JSON.parse(message.data);
        if (data?.key && data.key === 'init') {
            setAppData(data);
            getTimeDetails(data);
            localStorage.setItem("token",
                data.token);
            setTimeout(() => {
                getAbhyaasQuestionsList(data);
            }, 100);
        } else {

        }
    }

    function getTimeDetails(data: any) {
        // const testStartDate: any = sessionStorage.getItem("abhyas_test_start_date");

        // return testStartDate ? testStartDate : null;
        const testStartDate: any = data.abhyas_test_start_date;
        let time = getTime(testStartDate);
        let timeMillis = moment(time, 'YYYY-MM-DD hh:mm:ss').toDate().getTime();
        let reqTimeMillis = 2 * 24 * 60 * 60 * 1000;
        let actualTimeToComplete = timeMillis + reqTimeMillis;
        let x = new Date(actualTimeToComplete)

        let currentTime = new Date().getTime();
        let timerResult = x.getTime() - currentTime;
        let tSeconds = Math.floor(timerResult / 1000);
        let hours = Math.floor(tSeconds / 3600);
        let minutes = Math.floor((tSeconds - (hours * 3600)) / 60); // get minutes
        let seconds = tSeconds - (hours * 3600) - (minutes * 60); //  get seconds
        setTimeRemainForApp((hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds) + ' hrs');

    }

    function getTime(time: any) {
        if (time !== 'null') {
            return time;
        } else {
            return time ? time : startTime;
        }
    }


    function getAbhyaasQuestionsList(data: any) {
        let payload = {
            "type": data.type,
            "uuid": data?.question_paper_uuid ? data.question_paper_uuid : ''
        }
        setShowHeader(false);

        FETCH_ABHYAS_USER_QUESTION_PAPER(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setLoading(false);
                } else {
                    if (res) {
                        if (res) {
                            // if (res.hasOwnProperty("data") ) {
                            if (Array.isArray(res)) {
                                setViewQuestions(true);
                                setQuestionsData(res);
                                setShowHeader(true);
                                setStartTimer(true);
                                if (data.type === 'WORKSHEETS') {
                                    setIsActive(true);
                                }
                                setLoading(false);
                            } else {
                                toast.error("Questions not found");
                                setLoading(false);

                            }

                        } else {
                            setShowHeader(true);
                            setStartTimer(true);
                            setLoading(false);
                        }

                    }

                }
            }
        });
    }

    function handleQuestionOptions(item: any, index: number, j: number) {
        const data = questionsData;
        if (data?.length == currentQuestion + 1) {
            data[index].checked = true;
        }
        for (let i = 0; i < alphabets.length; i++) {
            if (j === i) {
                data[index].answer = alphabets[i];
            }
        }
        setQuestionsData([...data]);
        sessionStorage.setItem("questionsData", JSON.stringify(data));
    }

    const handleCompleteSubmit = () => {
        const attemptQuestionsLength = questionsData.filter((el: any) => el.checked);
        if (attemptQuestionsLength?.length > 0) {
            setAttemptQuestionCount(attemptQuestionsLength?.length);
            setCanShowPopup(true);
        } else {
            toast.error("Please attempt at least one question");
            setAttemptQuestionCount(0);
        }
    }
    const handleSubmitTest = () => {
        if (appData?.module_code === "PARIKSHA") {
            const payload = {
                "user_code": appData?.user_code,
                "school_code": appData?.school_code,
                "subject_uuid": appData?.subject_uuid,
                "type": appData?.type,
                "test_uuid": appData?.test_uuid,
                "topic_uuid": appData?.topic_uuid,
                "worksheet_uuid": appData?.worksheet_uuid,
                "question_paper_uuid": appData?.question_paper_uuid,
                "time_taken": appData?.type === "TESTS" ? (calculateTimeDifference() ? calculateTimeDifference() : sessionStorage.getItem("setTime")) : (getTimeTaken(seconds) ? getTimeTaken(seconds) : sessionStorage.getItem("setTime")),
                "questions": questionsData.length > 0 ? questionsData : JSON.parse(sessionStorage.getItem("questionsData") || '{}'),
                "test_time": `${minutes}.0`,
            }

            setLoading(true);
            UPDATE_PARIKSHA_USER_QUESTION_PAPER(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                        toast.error(errorValue);
                        setLoading(false);

                    } else {
                        setLoading(false);
                        const data = res;
                        if (appData?.type === 'WORKSHEETS') {
                            sendMessageToApp({ action: 'show_popup', type: 'worksheet_submitted', data: '', test_result: data });
                            return;
                        }
                        sendMessageToApp({ action: 'show_popup', type: 'test_submitted', timeRemain: timeRemainForApp, test_result: data });

                    }

                }
            });
        } else {
            const payload = {
                "user_code": appData?.user_code,
                "school_code": appData?.school_code,
                "subject_uuid": appData?.subject_uuid,
                "cycle_no": appData?.cycle_no ? parseInt(appData?.cycle_no) : 1,
                "type": appData?.type,
                "test_no": appData && appData?.test_no ? appData?.test_no : 1,
                "question_paper_uuid": appData?.type === 'TESTS' ? (appData?.question_paper_uuid ? appData?.question_paper_uuid : '') : (appData?.question_paper_uuid ? appData?.question_paper_uuid : ''),
                "question_paper_level": appData?.level ? appData?.level : '',
                "priority_no": appData?.priority ? appData?.priority : '',
                "time_taken": appData?.type === "TESTS" ? (calculateTimeDifference() ? calculateTimeDifference() : sessionStorage.getItem("setTime")) : (getTimeTaken(seconds) ? getTimeTaken(seconds) : sessionStorage.getItem("setTime")),
                "questions": questionsData?.length > 0 ? questionsData : JSON.parse(sessionStorage.getItem("questionsData") || '{}'),
                "test_time": `${minutes}.${seconds}`,
            }
            if (appData?.type === "WORKSHEETS") {
                let addPayLoad = {
                    "topic_uuid": appData?.topic_uuid,
                    "worksheet_uuid": appData?.worksheet_uuid,
                }
                Object.assign(payload, { ...addPayLoad })
            }

            // return
            setLoading(true);
            UPDATE_ABHYAS_USER_QUESTION_PAPER(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                        toast.error(errorValue);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        const data = res;
                        if (appData?.type === 'WORKSHEETS') {
                            sendMessageToApp({ action: 'show_popup', type: 'worksheet_submitted', data: '', test_result: data });
                            return;
                        }
                        sendMessageToApp({ action: 'show_popup', type: 'test_submitted', timeRemain: timeRemainForApp, test_result: data });
                    }

                }
            });
        }

    }

    const getCircleColor = (item: any) => {
        if (item?.status === "pending") {
            if (item?.question_paper_uuid === appData?.question_paper_uuid) {
                return '#FCEE21';
            } else {
                return '#FFFFFF';
            }
        } else if (item?.status === 'completed') {
            if (item?.result === "fail") {
                return '#f15a24';
            } else {
                return '#8CC63F';
            }
        } else {
            return '#FFFFFF';
        }
        // if (item?.status === "pending") {
        //     return '#FCEE21';
        // } else if (item?.status === 'completed') {
        //     if (item.result === "fail") {
        //         return '#f15a24';

        //     } else {
        //         return '#8CC63F';

        //     }
        // } else {
        //     return '#FFFFFF';
        // }
    }

    const sendMessageToApp = (data: any) => {
        if (window?.ReactNativeWebView) {
            try {
                window?.ReactNativeWebView.postMessage(JSON.stringify(data));
            } catch (error) {

            }
        }
    }
    const handleclose = () => {
        setCanShowPopup(false);
        // handleBack();
        // let prepredData = { ...selectUuids };
        // history.push(`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${prepredData?.school_code}/${prepredData?.board_uuid}/${prepredData?.medium_uuid}/${prepredData?.standard_uuid}/${prepredData?.subject_uuid}`)
    }

    const handleBack = () => {
        sendMessageToApp({ action: 'back_click' });
    }


    // Renderer callback with condition
    const renderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: {
        days: any;
        hours: any;
        minutes: any;
        seconds: any;
        completed: any;
    }) => {
        if (completed) {
            return <div></div>
            // Render a completed state
        } else {
            // Render a countdown
            return (
                <span style={{ fontSize: "1rem", paddingTop: "10px" }}>
                    {hours} Hr - {minutes} Min - {seconds} Sec
                </span>
            );
        }
    };
    const calculateTimeDifference = () => {
        const initialTime = `${"59"}:${"59"}`
        const [initialMinutes, initialSeconds] = initialTime.split(':').map(Number);
        const [finalMinutes, finalSeconds] = completedTime.split(':').map(Number);
        const initialTimeInSeconds = Number(initialMinutes) * 60 + Number(initialSeconds);
        const finalTimeInSeconds = Number(finalMinutes) * 60 + finalSeconds ? Number(finalSeconds) : 0;
        const differenceInSeconds = Number(initialTimeInSeconds * 100) - Number(finalTimeInSeconds * 100);
        if (differenceInSeconds >= 60) {
            var mins = moment.utc(moment(initialTime, "mm:ss").diff(moment(completedTime, "mm:ss"))).format("mm.ss")
            sessionStorage.setItem("setTime", mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0'));
            return mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0')
        } else {
            sessionStorage.setItem("setTime", `0.${differenceInSeconds}`);
            return `0.${differenceInSeconds}`;
        }

    };
    const getTimeTaken = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        let totalTime = `${minutes.toString().padStart(2, '0')}.${seconds.toString().padStart(2, '0')}`
        let finalTime = totalTime?.replace('00', '0')
        sessionStorage.setItem("setTime", `${Number(finalTime)}`);

        return Number(finalTime);
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, currentIndex: number) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent page reload
            const nextIndex = currentIndex + 1;
            if (nextIndex < questionRefs.current.length) {
                questionRefs.current[nextIndex]?.focus();
            }
        }
    };

    function onViewQuestion(name: any) {
        // let preElement = `<p class="mb-0">${name}</p>`
        return { __html: name };
    }
    const getDomElements = (htmlString: any, tag: string) => {
        const parsedContent = new DOMParser().parseFromString(htmlString, 'text/html');
        const pElements = parsedContent.querySelectorAll('p');
        const imgElement: any = parsedContent.querySelector('img');
        if (tag === "p") {
            const paragraphs: any[] = [];
            pElements.forEach((pElement) => {
                paragraphs.push(`<p class="mb-0">${pElement.innerHTML}</p>`);
            });
            return <div dangerouslySetInnerHTML={{ __html: paragraphs?.join('') }} />
        }
        if (tag === "img") {
            if (imgElement) {
                return imgElement ? imgElement.getAttribute('src') : ''
            } else {
                return "";
            }

        }
        return "";
    }
    const selectQuestion = (questioNo: any) => {
        let preElement = parseInt(questioNo);
        const data = questionsData;
        if (data[currentQuestion].answer && (data[currentQuestion]?.id === questionsData[questionsData.length - 1]?.id)) {
            data[currentQuestion].checked = true;
        } else if (!data[currentQuestion].checked) {
            data[currentQuestion].answer = "";
            data[currentQuestion].checked = false;
        }
        setQuestionsData([...data]);
        setCurrentQuestion(preElement);
    }
    const handlePrevious = () => {
        const data = questionsData;
        if (!data[currentQuestion].checked) {
            data[currentQuestion].answer = "";
            data[currentQuestion].checked = false;
        }
        setQuestionsData([...data]);
        setCurrentQuestion((prev) => prev - 1);
    };
    const handleClearSelection = () => {
        setCurrentQuestion(currentQuestion);
        const data = questionsData;
        data[currentQuestion].answer = "";
        data[currentQuestion].checked = false;
        setQuestionsData([...data]);
    };
    const handleNext = () => {
        const data = questionsData;
        if (data[currentQuestion].answer) {
            data[currentQuestion].checked = true;
        }
        setQuestionsData([...data]);
        setCurrentQuestion((prev) => prev + 1);
    };
    const getLevelMedium = (level: string) => {
        switch (level) {
            case "LOW":
                return "Easy";
            case "MEDIUM":
                return "Medium";
            case "HIGH":
                return "Hard";
            default:
                return "";

        }
    }
    return (
        <div className='app_bg_img scroll_div_block' >
            {viewQuestions && <div className="row  py-3 mb-3">
                <div
                    className={`modal fade ${canShowPopup ? "show" : "false"}`}
                    style={{ display: canShowPopup ? "block" : "none" }}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog pariksha-model-dialog" role="document">
                        <div className="modal-content pariksha-model-content">
                            <div className="modal-body parent-info pariksha-model-info">
                                <div className='mt-3'>
                                    <h5>You have attempted {attemptQuestionCount} out of {questionsData?.length} questions</h5>
                                </div>
                                <div className='mt-4 mb-3'>
                                    <h5>Do you want to submit the {appData?.type === 'WORKSHEETS' ? "worksheet" : "test"} ?</h5>
                                    <div className='mt-4'>
                                        <button className='btn test-btn' onClick={handleSubmitTest}>YES</button>
                                        <button className='btn test-btn' onClick={handleclose}>NO</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!loading && <div className='app_question_src'>

                    {canShowHeader &&
                        <div>
                            <div className='d-flex justify-content-between'>
                                <div><span className='ap_btns'>ABHYAAS</span></div>
                                <div>{appData?.type === 'TESTS' && <div className='me-3'>
                                    <p className="m-0 f14"> Time Limit :  {minutes} : {seconds < 9 ? `0${seconds}` : seconds}  mins</p>
                                    {appData?.type !== 'WORKSHEETS' &&
                                        <div className='d-flex ms-3 ps-3'> <p className='mb-0 me-2 f14'>Time : </p>
                                            <TimerInfo
                                                customeTime={true}
                                                minutes={minutes}
                                                seconds={seconds}
                                                startTimer={startTimer}
                                                time_remain={(value: any) => {
                                                    if (value === '') {
                                                        time_remain_storage = "5";
                                                    } else {
                                                        time_remain_storage = value;
                                                        completedTime = time_remain_storage;
                                                    }

                                                }}
                                                timeUp={() => {
                                                    if (appData?.type === "TESTS") {
                                                        handleCompleteSubmit();
                                                    }
                                                    timeUp = true;
                                                }} />

                                        </div>}
                                </div>}</div>
                            </div>
                            {appData?.type === 'TESTS' && <h2 className='text-center f18 mt-2'>Assessment Test - {appData?.test_no ? appData?.test_no : ''}</h2>}
                            {appData?.type === 'WORKSHEETS' && <div className='mt-2'>
                                {appData?.module_code !== "PARIKSHA" && <h2 className='text-center  f18'>Worksheet (Assessment Tests)</h2>}
                                {appData?.topic_name && <h2 className=" m-0 f16"> {appData?.topic_name} {appData?.name}</h2>}
                                {/* {appDa>ta?.name && <h3 className="text-center m-0 f14"> {appData?.name}</h3>} */}
                                <div className='d-flex text-center mb-1'>
                                    <h3 className="mt-1 f14 me-2"> Worksheet Level</h3>
                                    <div>
                                        {appData?.worksheets?.map((item: any, i: number) => (
                                            <div key={i} className="circle cursor-pointer f12" style={{ backgroundColor: getCircleColor(item) }}>
                                                {/* <p>{i + 1}</p> */}
                                                <p>{getLevelMedium(item?.level)?.charAt(0)}</p>

                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </div>}
                        </div>}
                    <div className='app_qestion_board ' style={{ borderRadius: showQuestions ? "15px" : "5px" }}>
                        <h3 className='text-center f14 text-danger '>Questions Board </h3>
                        <div className={`q_view ${!showQuestions && "hide_q_block"}`}>
                            <div className=' app_question_no_block d-block justify-content-center'>
                                <div className="question-pattern ms-2 mb-1 ">
                                    {questionsData.map((questionNo: any, i: number) => (
                                        <button key={i} className={`app_q_no cursor-pointer  ${questionNo.id == questionsData[currentQuestion]?.id ? "bg-secondary" : questionNo?.checked ? "q-primary" : "q-danger"}`} onClick={() => selectQuestion(i)}>
                                            {i + 1}
                                        </button>

                                    ))}
                                </div>
                            </div>

                            <div className='d-flex  mt-1 justify-content-center '>
                                <div className='d-flex border-top border-secondary border-1 pt-1'>
                                    <div className='app_square-answer'></div>
                                    <div className='app_answer f16 fw-bold'>Answered</div>
                                </div>
                                <div className='d-flex ps-2 border-top border-secondary border-1 pt-1'>
                                    <div className='app_square-not-answer'></div>
                                    <div className='app_not-answer f16 fw-bold'>Not Answered</div>
                                </div>
                            </div>
                        </div>
                        <p className='m-0  app_queston_dwn' onClick={() => setShowQuestions(!showQuestions)} style={{ top: showQuestions ? "99%" : "77%" }}>
                            {!showQuestions ? <i className="fa  ps-2">&#xf103;</i>
                                : <i className="fa  ps-2">&#xf102;</i>}
                        </p>
                    </div>
                    {questionsData?.length > 0 && <div className='px-4 pb-3 mt-3'>
                        <MathJaxContext>
                            <MathJax>
                                <div className="">
                                    <div className='d-flex'>
                                        <div>
                                            <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                Q{currentQuestion + 1}.
                                            </p>
                                        </div>
                                        <div className='ms-2'>
                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                <div className='img_ck' dangerouslySetInnerHTML={onViewQuestion(questionsData[currentQuestion]?.question)}></div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='ms-4 ps-3'>
                                        <form>
                                            {questionsData[currentQuestion]?.options?.map((options: any, j: number) => (
                                                <div className='d-flex pt-2 app_opt' key={j}>
                                                    <div >
                                                        <span className={`${getDomElements(options.value, "img") && "mt-5 "}`}>
                                                            <input className={`mb-0 cursor-pointer  ${getDomElements(options?.value, "img") && "mt-5 "}`} type="radio" name="option" checked={questionsData[currentQuestion]?.answer === alphabets[j] ? true : false} onChange={(e) => { handleQuestionOptions(options, currentQuestion, j) }}
                                                                onKeyDown={(e) => handleKeyDown(e, currentQuestion)}
                                                            // value={question.answer = question?.correct_answer}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="ms-3">
                                                        <span className='img_ck_otp' dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                    </div>
                                                </div>
                                            ))}
                                        </form>
                                    </div>
                                </div>
                            </MathJax>
                        </MathJaxContext>
                    </div>}
                    <footer className="mt-auto">
                        {questionsData?.length > 0 && <div className='mt-1 d-flex justify-content-around mx-2'>
                            <button className={`f12 btn  py-1 px-2  ms-2 ${currentQuestion === 0 ? "btn-light text-dark" : "btn-warning text-white"}`} type='button' onClick={handlePrevious} disabled={currentQuestion === 0}>Back</button>
                            <button className={`f12 btn  ${currentQuestion === questionsData.length - 1 ? "btn-light" : "btn-primary"}  py-1 px-2`} type='button' onClick={handleNext} disabled={currentQuestion === questionsData.length - 1}>Save and Next</button>
                            <button className="f12 btn btn-secondary py-1 px-2 ms-2" type='button' onClick={handleClearSelection}>Clear Selection</button>
                            <button className={`f12 btn btn-success ${appData?.type === 'TESTS' ? " py-2" : "py-1"} px-2 ms-2`} type='button' onClick={handleCompleteSubmit}>Submit {appData?.type === 'TESTS' ? "Test" : <p className='m-0'>Worksheet</p>}</button>
                        </div>}
                    </footer>
                </div>}
            </div >}
        </div>
    )
}
