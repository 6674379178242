import React, { useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { toast } from "react-toastify";
import appConfig from "../../config/constant";
import AVTARIMAGE from '../../institute-dashboard-assets/images/avatar-img_upload.png'
import AVTARLOGO from '../../institute-dashboard-assets/images/avatar-logo-upload.png'


interface ImageCropProps {
    uploadLogo: (formdata: any, type: string) => void;
    profileImg?: string | any;
    imageLoader: any;
    profileImageDelete: any;
    required?: boolean;
    errorMsg?: string;
    validImg?: boolean;
    uploadBtnText?: string;
    setImageLoader?: any;
}
const ProfileImageCrop: React.FC<ImageCropProps> = (props: ImageCropProps) => {
    const [srcImg, setSrcImg] = useState<any>(null);
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [crop, setCrop] = useState<any>({ aspect: 16 / 9 });
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [base64IMG, setBase64IMG] = useState<any>();
    const [showImage, setShowImage] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);




    const handleImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowImage(false);
        setCrop({ aspect: 16 / 9 });
        setSrcImg(null);
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.onload = () => {
                setBase64IMG(reader.result)
            }
            const fileType = event.target.files[0].name.split('.').pop()
            if (fileType == "jpeg" || fileType == "jpg" || fileType == "png") {
                setSrcImg(URL.createObjectURL(event.target.files[0]));
                setCanShowPopup(true);
            } else {
                toast.error("Valid file type .jpg, .jpeg, .png only");
            }
        }
    };


    const getCroppedImg = async () => {
        if (image && crop.width && crop.height) {
            try {
                const canvas = document.createElement("canvas");
                const scaleX = image.naturalWidth / image.width;
                const scaleY = image.naturalHeight / image.height;
                canvas.width = crop.width;
                canvas.height = crop.height;
                const ctx: any = canvas.getContext("2d");
                ctx.drawImage(
                    image,
                    crop.x * scaleX,
                    crop.y * scaleY,
                    crop.width * scaleX,
                    crop.height * scaleY,
                    0,
                    0,
                    crop.width,
                    crop.height
                );

                const base64Image = canvas.toDataURL("image/jpeg", 1);
                handleUploadImage(base64Image);
            } catch (e) {
                console.log(e)
                console.log("crop the image");
            }
        }
    };

    const cancelCrop = () => {
        setCrop({ aspect: 16 / 9 });
    };

    const removeImage = () => {
        setSrcImg(null);
        setCrop({ aspect: 16 / 9 });
        setCanShowPopup(false);

    };
    const saveImage = () => {
        if (srcImg && crop.width && crop.height) {
            getCroppedImg();
        } else if (srcImg) {
            handleUploadImage(base64IMG);
        }

    };
    const handleClose = () => {
        setSrcImg(null);
        setCrop({ aspect: 16 / 9 });
        setCanShowPopup(false);
        setShowImage(false);

    }

    const handleUploadImage = (base64Image: string) => {
        // const pngBase64 = base64Image.replace(/^data:image\/\w+;base64,/, "data:image/png;base64,"); // Force PNG format

        // const formData = new FormData();
        // formData.append("file", base64ToBlob(pngBase64, "image/png"), "image.png");

        // props?.uploadLogo(formData, "schooladmin");
        // setCanShowPopup(false);
        const formData = new FormData();
        formData.append('file', base64ToBlob(base64Image));
        props?.uploadLogo(formData, "schooladmin");
        setCanShowPopup(false);

    };

    const base64ToBlob = (base64Image: string) => {
        const byteString = atob(base64Image.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    };
    const viewImage = () => {
        setShowImage(true);
        setCanShowPopup(true);
    }
    const deleteImage = () => {
        props?.profileImageDelete(null);
        if (props?.setImageLoader) {
            props?.setImageLoader(true);
            setTimeout(() => {
                props?.setImageLoader(false);

            }, 500);
        }
    }
    const onEditImage = () => {
        setShowImage(false);
        setCanShowPopup(true);
        if (props?.profileImg) {
            let preSrc: any = `${appConfig.FILE_UPLOAD_URL}${props?.profileImg.charAt(0) === "/" ? props?.profileImg.substring(1) : props?.profileImg}`
            setImage(preSrc);
            setSrcImg(preSrc);
            createFileFromUrl(props?.profileImg)
                .then(file => {
                    if (file) {
                        const reader = new FileReader()
                        reader.readAsDataURL(file)
                        reader.onload = () => {
                            setBase64IMG(reader.result)
                        }
                        console.log("File object created:", file);
                    } else {
                        console.error("Failed to create File object.");
                    }
                });
        }



    }
    const createFileFromUrl = async (fileUrl: string): Promise<any> => {
        try {
            // Fetch the file content using the URL
            const response = await fetch(fileUrl);
            const blob = await response.blob();

            // Extract filename from the URL or provide a default name
            const filename = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

            // Create a File object with additional metadata
            const file = new File([blob], filename, {
                lastModified: new Date().getTime(), // Provide last modified timestamp if available
                type: blob.type // Set the MIME type of the file
            });

            return file;
        } catch (error) {
            console.error("Error creating File object from URL:", error);
            return null;
        }
    };
    const getValidImageUrl = (image: any) => {
        if (!image) return props?.uploadBtnText ? AVTARLOGO : AVTARIMAGE;
        return `${appConfig.FILE_UPLOAD_URL}${image?.startsWith("/") ? image?.substring(1) : image}`;
    };
    return (
        <div>
            <div>

                <div className="img_upload_blck">
                    {props?.imageLoader &&
                        <div className=" p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {(!props?.imageLoader) &&
                        <div>
                            {/* {console.log("props?.profileImg", props?.profileImg)} */}
                            {/* {console.log(`${appConfig.FILE_UPLOAD_URL}${props?.profileImg?.charAt(0) === "/" ? props?.profileImg?.substring(1) : props?.profileImg}`, "hghg")} */}
                            <img className="img_up_logo" src={props?.profileImg ? `${appConfig.FILE_UPLOAD_URL}${props?.profileImg?.charAt(0) === "/" ? props?.profileImg?.substring(1) : props?.profileImg}` : props?.uploadBtnText ? AVTARLOGO : AVTARIMAGE} />
                            {props?.profileImg && <div className="bg-white position-relative profie_img_bx" >
                                {/* <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={onEditImage}></i> */}
                                <i className="bi bi-eye-fill cursor-pointer  px-2 py-1" onClick={viewImage}></i>
                                <i className="bi bi-trash cursor-pointer text-danger ps-2 py-1" onClick={deleteImage}></i>
                            </div>}
                        </div>
                    }
                </div>
                <div className="file img_up_btn  d-inline-block f14 ms-1 mt-3">{props?.uploadBtnText ? props?.uploadBtnText : " Upload Photo"}
                    <input
                        key={srcImg || 'fileInput'}
                        type="file"
                        accept="image/*"
                        onChange={handleImage}
                    />
                </div>
                {props?.required && <sup className="ms-2 f18 text-inprogess">*</sup>}
                {props?.validImg && <p className="m-0 text-inprogess f12">File type .jpg, .jpeg, size max 5mb </p>}
                {props?.errorMsg && <p className="text-danger f14 m-0">{props?.errorMsg}</p>}
            </div>

            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body parent-info ">
                            <div className="text-end mb-3"> <button
                                type="button"
                                className="close popup-close cursor-pointer "
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button></div>
                            {!showImage && <div>
                                {srcImg && (
                                    <div className="text-center">
                                        <ReactCrop
                                            crop={crop}
                                            onChange={(newCrop) => setCrop(newCrop)}
                                        >
                                            <img
                                                src={srcImg}
                                                onLoad={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                    const target = e.target as HTMLImageElement;
                                                    if (target instanceof HTMLImageElement) {
                                                        setImage(() => target);
                                                    }
                                                }}
                                            />
                                        </ReactCrop>

                                    </div>)}
                                <div className=" mt-4 mb-2 text-end">
                                    {Object.keys(crop)?.length > 1 && <button className=" btn gd_bg px-3 py-1 text-white cursor-pointer" onClick={cancelCrop} type="button">Remove Crop</button>}
                                    <button className="ms-5 btn btn-primary px-3 py-1 cursor-pointer" onClick={saveImage} type="button">Save</button>
                                    <button className="ms-5 btn btn-secondary cursor-pointer px-3 py-1 text-white" onClick={removeImage} type="button">Cancel</button>
                                </div>
                            </div>}
                            {(showImage && props?.profileImg) && <div>
                                <img src={`${appConfig.FILE_UPLOAD_URL}${props?.profileImg.charAt(0) === "/" ? props?.profileImg.substring(1) : props?.profileImg}`} style={{ height: '350px', width: '400px' }} />
                                <div className="text-end">
                                    <button className="btn btn-secondary cursor-pointer mt-3 me-3" onClick={handleClose} type="button">Close</button>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileImageCrop;
