import API from "../../utility/axios";
const apiUrl = "/api/v2";

export const GET_CONTENT_DATA = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/content/dashboard/internal/statistics`, {
    params: data,
  });
};
export const GET_CONTENT_DATA_GRAPH = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/content/dashboard/graph`, { params: data });
};
