import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'
import FormBuilder from '../../components/form-builder'
import { FormControlError, FormField, FormValidators } from '../../components/form-builder/model/form-field'
import { FormValidator, GetControlIsValid } from '../../components/form-builder/validations'
import { ToastContainer, toast } from 'react-toastify';
import Logos from "../../assets/images/eliments.svg";
import Logo from "../../assets/images/iamsamarth-Logo-Final.svg";
import rocketLogo from "../../assets/images/rocket.png";
import '../../assets/css/org.css';
import { LOOK_UP_DATA } from '../../app/service/lookup.service';
import moment from 'moment';
import errorMessages from '../../components/error-messages';
import { CREATE_ORGANISATION_SIGN_UP, GET_PAN_NUMBER, GET_REQUEST_OTP, GET_RESEND_OTP, GET_VERIFY_OTP } from '../../app/service/organisation.signup.service';
import { GET_RUN_CRON_JOB } from '../../app/service/cron-job';
let intervalTimer: NodeJS.Timeout | null = null;

export const OrganisationSignupPage = () => {
    const [studentsData, setStudentsData] = useState<any>({});
    const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [currentStudentData, setCurrentStudentData] = useState<any>({});
    const [currentPANNumberData, setCurrentPANNumberData] = useState<any>({});
    const [OTPTypeData, setOTPTypeData] = useState<any>({});
    const [statesData, setStatesData] = useState<any>([]);
    const [districtData, setDistrictData] = useState<any>([]);
    const [acadamicData, setAcadamicData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(false);
    const [confirmMobileNumber, setConfirmMobileNumber] = useState(false);
    const [alternativeLandlineNumber, setAlternativeLandlineNumber] = useState(false);
    const [landlineNumber, setLandlineNumber] = useState(false);
    const [alternativeMobileNumber, setAlternativeMobileNumber] = useState(false);
    const [confirmAlternativeMobileNumber, setConfirmAlternativeMobileNumber] = useState(false);
    const [whatsappNumber, setWhatsappNumber] = useState(false);
    const [fatherwhatsappNumber, setFatherWhatsappNumber] = useState(false);
    const [pincodeNumber, setPincodeNumber] = useState(false);
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [canCheckMobileNumber, setCanCheckMobileNumber] = useState(false);
    const [canCheckFatherMobileNumber, setCanCheckFatherMobileNumber] = useState(false);
    const [canShowWhatsAppError, setCanShowWhatsAppError] = useState(false);
    const [canShowFatherWhatsAppError, setCanShowFatherWhatsAppError] = useState(false);
    const [checkOTPType, setCheckOTPType] = useState(true);
    const [verifyOTP, setVerifyOTP] = useState(false);
    const [OTPValue, setOTPValue] = useState('');
    const [email, setEmail] = useState(false);
    const [confirmEmail, confirmSetEmail] = useState(false);
    const [motherEmail, setMotherEmail] = useState(false);
    const [fatherEmail, setFatherEmail] = useState(false);
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const [startDate, setStartDate] = useState(null);
    const [createDate, setCreateDate] = useState(new Date());
    const [canShowDate, setCanShowDate] = useState<any>();
    const [canShowMonth, setCanShowMonth] = useState<any>();
    const [canShowYear, setCanShowYear] = useState<any>();
    const [panNumber, setPanNumber] = useState(false);
    const [sendOtpSuccess, setSendOtpSuccess] = useState(true);
    const [amountError, setAmountError] = useState('');
    let { id } = useParams<{ id: string }>();
    const usersId: number = parseInt(id);
    const [isDateError, setIsDateError] = useState<any>({});
    const [nameError, setNameError] = useState('');
    const [addressOne, setAddressOneError] = useState('');
    const [addressTwo, setAddressTwoError] = useState('');
    const [motherNameError, setMotherNameError] = useState('');
    const [motherOccupationError, setMotherOccupationError] = useState('');
    const [fatherNameError, setFatherNameError] = useState('');
    const [fatherOccupationError, setFatherOccupationError] = useState('');
    const [schoolNameError, setSchoolError] = useState('');
    const [schoolAddressError, setSchoolAddressError] = useState('');
    const [schoolAddressTwoError, setSchoolAddressTwoError] = useState('');
    const [userNameError, setUserNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [avalableUserNames, setAvalableUserNames] = useState([]);
    const [instituteNameError, setInstituteNameError] = useState('');
    const [faxNumber, setFaxNumber] = useState(false);
    const [emcfError, setEmcfError] = useState<string>("");
    const [cfMobileError, setCfMobileError] = useState<string>("");
    const [minutes, setMinutes] = useState<number>(5);
    const [seconds, setSeconds] = useState<number>(0);
    const [isActiveOTP, setIsActiveOTP] = useState<boolean>(false);
    const [showTimer, setShowTimer] = useState<boolean>(false);
    const [academicDetails, setAcadamicDetails] = useState([
        { label: "Class 1", value: 'class1', checked: false },
        { label: "Class 2", value: 'class2', checked: false },
        { label: "Class 3", value: 'class3', checked: false },
        { label: "Class 4", value: 'class4', checked: false },
        { label: "Class 5", value: 'class5', checked: false },
        { label: "Class 6", value: 'class6', checked: false },
        { label: "Class 7", value: 'class7', checked: false },
        { label: "Class 8", value: 'class8', checked: false },
        { label: "Class 9", value: 'class9', checked: false },
        { label: "Class 10", value: 'class10', checked: false },
        { label: "Class 11", value: 'class11', checked: false },
        { label: "Class 12", value: 'class12', checked: false },
        { label: "College", value: 'college', checked: false },
        { label: "Professional Institute", value: 'professionalinstitute', checked: false }
    ]);
    const history = useHistory();

    const formValidations = [
        new FormField('pan_number', []),
        new FormField('name_of_organization', [FormValidators.REQUIRED]),
        new FormField('organization_type', [FormValidators.REQUIRED]),
        new FormField('address', [FormValidators.REQUIRED]),
        new FormField('address_2', []),
        new FormField('state', [FormValidators.REQUIRED]),
        new FormField('district', [FormValidators.REQUIRED]),
        new FormField('pin_code', [FormValidators.REQUIRED]),
        new FormField('landline_no', []),
        new FormField('alt_landline_no', []),
        new FormField('fax_number', []),
        new FormField('academic_structure', []),
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('confirm_email_id', [FormValidators.REQUIRED]),
        new FormField('mobile_number', [FormValidators.REQUIRED]),
        new FormField('confirm_mobile_number', [FormValidators.REQUIRED])
    ];

    useEffect(() => {
        setLoading(true);
        LOOK_UP_DATA().then((data1: any) => {
            if (data1.status === 'fail') {
                toast.error(data1.message);
                setLoading(false);
            } else {
                setLoading(false);
                setStatesData(data1.states);
            }
        });
    }, []);

    const handleInput = (data: any) => {
        data.value = { ...currentStudentData, ...data.value };
        setStudentsData(data.value);
        const errors: any = FormValidator(formValidations, data.value);
        setValidationErrors(errors);
    };

    const getInputValid = (control: string) => {
        const value = GetControlIsValid(validationErrors, control);
        return value;
    }

    const handleStateChange = (e: any) => {
        const data = { ...studentsData.value };
        data.state = e.target.value;
        const preparedData = statesData.filter((el: any) => el.state === e.target.value);
        setDistrictData(preparedData[0].districts);
        if (currentStudentData) {
            currentStudentData.state = e.target.value;
        }
    }

    const handleDistrictChange = (e: any) => {
        const data = { ...studentsData.value };
        data.district = e.target.value;
        if (currentStudentData) {
            currentStudentData.district = e.target.value;
        }
    }

    const handleEmailChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.email_id = e.target.value.toLowerCase();
            setEmail(false);
            if ((e.target.value !== currentStudentData?.confirm_email_id) && currentStudentData?.confirm_email_id) {
                setEmcfError("Email not matched");
            } else {
                setEmcfError("");
            }
        } else {
            data.email_id = e.target.value.toLowerCase();
            setEmail(true);
            setEmcfError("");
        }

        if (currentStudentData) {
            currentStudentData.email_id = e.target.value.toLowerCase();
        }
    }

    const handleConfirmEmailChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            data.confirm_email_id = e.target.value.toLowerCase();
            confirmSetEmail(false);
            if (currentStudentData.email_id !== e.target.value) {
                setEmcfError("Email not matched");
            } else {
                setEmcfError("");
            }
        } else {
            data.confirm_email_id = e.target.value.toLowerCase();
            confirmSetEmail(true);
        }
        if (currentStudentData) {
            currentStudentData.confirm_email_id = e.target.value.toLowerCase();
        }
    }

    const handlePanChange = (e: any) => {
        const data = { ...studentsData.value };

        if (!(/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(e)) && e) {
            setPanNumber(true);
        } else {
            data.pan_number = e;
            setPanNumber(false);
            if (e) {
                getPanNumber(e);
            }
        }

        if (currentStudentData) {
            currentStudentData.pan_number = e;
        }

    }

    function getPanNumber(data: any) {
        setCurrentPANNumberData({});
        GET_PAN_NUMBER(data).then((res: any) => {
            if (res.status === 'fail' || res.errorCode === 400) {
                toast.error(res.message || res.errorMsg);
                setLoading(false);
            } else {
                if (res?.pan_number) {
                    setCurrentPANNumberData(res);
                    toast.error('Institution already exist with this PAN number');
                } else {
                    toast.success('Institution does not exist with this PAN number');
                }
                setLoading(false);
            }
        });
    }

    const onChangeInstituteName = (event: any) => {
        setInstituteNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setInstituteNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setInstituteNameError('Please enter institute name');
        }

    }

    const onChangeAddressOne = (event: any) => {

        setAddressOneError('');
        if (event) {
            if (event.trim().length <= 2) {
                setAddressOneError('Address line 1 minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setAddressOneError('Address line 1 maximum 256 characters');
            } else {
                if (currentStudentData) {
                    currentStudentData.address = event;
                }
                return true;
            }
        } else {
            setAddressOneError('Please enter address line 1');
        }

        if (currentStudentData) {
            currentStudentData.address = event;
        }

    }

    const onChangeAddressTwo = (event: any) => {
        setAddressTwoError('');
        if (event) {
            if (event.trim().length <= 2) {
                setAddressTwoError('Address line 2 minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setAddressTwoError('Address line 2 maximum 256 characters');
            } else {
                if (currentStudentData) {
                    currentStudentData.address_2 = event;
                }
                return true;
            }
        } else {
            setAddressTwoError('Please enter address line 2');
        }

        if (currentStudentData) {
            currentStudentData.address_2 = event;
        }
    }

    const handleMobileNumberChange = (e: any) => {
        const data = { ...studentsData.value };
        if (e.target.checked) {
            if (data.whatsapp_number) {
                data.whatsapp_number = data.mobile_number;
                currentStudentData.whatsapp_number = data.mobile_number;
                setCanCheckMobileNumber(true);
                setCanShowWhatsAppError(false);
            }
            if (data.mobile_number || currentStudentData.mobile_number) {
                setCanCheckMobileNumber(true);
                setCanShowWhatsAppError(false);
                currentStudentData.whatsapp_number = currentStudentData.mobile_number;
                setCurrentStudentData(currentStudentData);
            }
            currentStudentData.checkedMother = true;
        } else {
            setCanCheckMobileNumber(false);
            currentStudentData.checkedMother = false;
        }
    }

    const handleWhatsappChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /(6|7|8|9)\d{9}/;
        let preValue = e.target.value.replace(/\D+/g, '');

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.whatsapp_number = e.target.value;
            setWhatsappNumber(false);
            setCanShowWhatsAppError(false);
        } else {
            data.whatsapp_number = e.target.value;
            if (preValue.length > 0) {
                setWhatsappNumber(true);
            } else {
                setWhatsappNumber(false);
            }

        }

        if (currentStudentData) {
            currentStudentData.whatsapp_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleMobileChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.mobile_number = e.target.value;
            setMobileNumber(false);
            if (e.target.value !== currentStudentData?.confirm_mobile_number && currentStudentData?.confirm_mobile_number) {
                setCfMobileError("Number not matched");
            } else {
                setCfMobileError("");
            }
        } else {
            data.mobile_number = e.target.value;
            setMobileNumber(true);
            setCfMobileError("");

        }

        if (currentStudentData) {
            currentStudentData.mobile_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleLandlineNUmberChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^[\d]{3,4}[\-\s]*[\d]{6,7}$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 11) {
            data.landline_no = e.target.value;
            setLandlineNumber(false);
        } else {
            data.landline_no = e.target.value;
            setLandlineNumber(true);
        }

        if (currentStudentData) {
            currentStudentData.landline_no = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleAlternativeLandlineChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^[\d]{3,4}[\-\s]*[\d]{6,7}$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 11) {
            data.alt_landline_no = e.target.value;
            setAlternativeLandlineNumber(false);
        } else {
            data.alt_landline_no = e.target.value;
            setAlternativeLandlineNumber(true);
        }

        if (currentStudentData) {
            currentStudentData.alt_landline_no = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleConfirmMobileChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /(6|7|8|9)\d{9}/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.confirm_mobile_number = e.target.value;
            setConfirmMobileNumber(false);
            if (currentStudentData?.mobile_number !== e.target.value) {
                setCfMobileError("Number not matched");
            } else {
                setCfMobileError("");
            }
        } else {
            data.confirm_mobile_number = e.target.value;
            setConfirmMobileNumber(true);
        }

        if (currentStudentData) {
            currentStudentData.confirm_mobile_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleAlternativeMobileChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /(6|7|8|9)\d{9}/;
        let preValue = e.target.value.replace(/\D+/g, '');

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.alternative_mobile_number = e.target.value;
            setAlternativeMobileNumber(false);
        } else {
            data.alternative_mobile_number = e.target.value;
            if (preValue.length > 0) {
                setAlternativeMobileNumber(true);
            } else {
                setAlternativeMobileNumber(false);
            }
        }

        if (currentStudentData) {
            currentStudentData.alternative_mobile_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleConfirmAlternativeMobileChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /(6|7|8|9)\d{9}/;
        let preValue = e.target.value.replace(/\D+/g, '');

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.confirm_alternative_mobile_number = e.target.value;
            setConfirmAlternativeMobileNumber(false);
        } else {
            data.confirm_alternative_mobile_number = e.target.value;
            if (preValue.length > 0) {
                setConfirmAlternativeMobileNumber(true);
            } else {
                setConfirmAlternativeMobileNumber(false);
            }
        }

        if (currentStudentData) {
            currentStudentData.confirm_alternative_mobile_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Enter only alphabets');
            } else {
                if (currentStudentData) {
                    currentStudentData.name = event;
                }
                return true;
            }
        } else {
            setNameError('Please enter name');
        }

        if (currentStudentData) {
            currentStudentData.name = event;
        }
    }

    const handleFaxNumberChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^[0-9\b]+$/;
        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.fax_number = e.target.value;
            setFaxNumber(false);
        } else {
            data.fax_number = e.target.value;
            setFaxNumber(true);
        }

        if (currentStudentData) {
            currentStudentData.fax_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handlePinCodeChange = (e: any) => {
        const data = { ...studentsData.value };
        const re = /^[0-9\b]+$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 6) {
            data.pin_code = e.target.value;
            setPincodeNumber(false);
        } else {
            data.pin_code = e.target.value;
            setPincodeNumber(true);
        }

        if (currentStudentData) {
            currentStudentData.pin_code = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleAcadamicChange = (e: any, item: any, i: number) => {
        const preparedData = [];
        const data: any = academicDetails;
        if (e.target.checked) {
            preparedData.push(item);
            data[i].checked = e.target.checked;
            setAcadamicDetails(data);

        } else {
            data[i].checked = e.target.checked;
            setAcadamicDetails(data);

        }
    }

    const handleGenerateOTP = () => {
        const data = { ...studentsData };
        if (validForm()) {
            const payload = {
                user_code: "",
                otp_type: "ORG_USER_REGISTRATION_OTP",
                template_name: "ORG_USER_REGISTRATION_OTP",
                mobile_number: data.mobile_number,
                email_id: '',
                data: {
                    org_name: data.name_of_organization,
                    name: data.name
                }
            }
            GET_REQUEST_OTP(payload).then((res: any) => {
                if (res.status === 'fail' || res.errorCode === 400) {
                    toast.error(res.message || res.errorMsg);
                    setLoading(false);
                } else {
                    if (res) {
                        handleStartTimer(5, 0);
                        setOTPTypeData(res);
                        setCheckOTPType(false);
                        setVerifyOTP(true);
                        toast.success("OTP sent successfully");
                        setSendOtpSuccess(false);
                    }
                    setLoading(false);
                }
            });
        } else {
            toast.error("Please fill in all mandatory fields.");
        }
    }

    const handleResendOTP = () => {
        const data = { ...studentsData };
        const payload = {
            otp_type: "ORG_USER_REGISTRATION_OTP",
            ref_number: OTPTypeData.ref_number,
            template_name: "ORG_USER_REGISTRATION_OTP",
            mobile_number: data.mobile_number,
            email_id: '',
            data: {
                org_name: data.name_of_organization,
                name: data.name
            }
        }
        GET_RESEND_OTP(payload).then((res: any) => {
            if (res.status === 'fail' || res.errorCode === 400) {
                const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                toast.error(errorValue);
                setLoading(false);
            } else {
                if (res) {
                    setOTPTypeData(res);
                    toast.success("OTP sent successfully");
                    clearTimerInterval();
                    setMinutes(5);
                    setSeconds(0);
                    handleStartTimer(5, 0);
                }
                setLoading(false);
            }
        });
    }


    const handleOTPChange = (e: any) => {
        const { name, value } = e.target;
        const numericRegex = /^[0-9]+$/;
        if (e.target.value === '' || numericRegex.test(e.target.value)) {
            setOTPValue(e.target.value);
        }
    }

    const handleInstituteChange = (e: any) => {
        const data = { ...studentsData.value };
        data.organization_type = e.target.value;
        if (currentStudentData) {
            currentStudentData.organization_type = e.target.value;
        }
    }

    const handleSubmit = () => {
        setIsFormSubmitted(true);
        const data = { ...studentsData };
        const errors: FormControlError[] = FormValidator(formValidations, data);
        setValidationErrors(errors);
        data.template_name = "org_user_login";
        data.academic_structure = academicDetails.filter((el: any) => el.checked);
        data._user = {
            name: data.name,
            designation: data.designation,
            email_id: data.email_id,
            mobile_number: data.mobile_number,
            contact_number: data.alternative_mobile_number,
            whatsapp_number: data.whatsapp_number,
        }
        data.date_of_registration = moment().format('YYYY-MM-DD');
        delete data.sameMobileNumber;
        delete data.checkedMother;
        delete data.checkedFather;
        delete data.checked;
        delete data.academic_info;

        if ((data.email_id == data.confirm_email_id) && (data.mobile_number == data.confirm_mobile_number) && (data.alternative_mobile_number == data.confirm_alternative_mobile_number)) {
            if (errors.length < 1 && !email && !mobileNumber && !pincodeNumber && !alternativeMobileNumber && !confirmAlternativeMobileNumber
                && !faxNumber && !alternativeLandlineNumber && !landlineNumber && onChangeName(data.name)
                && onChangeAddressOne(data.address)
            ) {
                data.whatsapp_number = data.whatsapp_number ? data.whatsapp_number : data.mobile_number;
                setLoading(true);
                delete data.confirm_email_id;
                delete data.confirm_mobile_number;
                delete data.confirm_alternative_mobile_number;
                delete data.alternative_mobile_number;
                delete data.name;
                delete data.designation;
                delete data.mobile_number;
                delete data.email_id;
                delete data.whatsapp_number;
                handleVerifyOTP(data);

            }

            else {
                if (!nameError && !onChangeName(data.name)) {
                    setNameError('Please enter student name');
                }
                if (!addressOne && !onChangeAddressOne(data.address)) {
                    setAddressOneError('Please enter address line 1');
                }

                if (!instituteNameError && !onChangeInstituteName(data.name_of_organization)) {
                    setInstituteNameError('Please enter institute name');
                }
            }
        } else {
            setCurrentStudentData(data);
            toast.error("confirm email and mobile number as well as passwords not matching");
        }
    }

    const handleVerifyOTP = (data: any) => {
        if (OTPValue) {
            const payload = {
                otp_type: "ORG_USER_REGISTRATION_OTP",
                ref_number: OTPTypeData.ref_number,
                // otp_value: "0981"
                otp_value: OTPValue
            }

            GET_VERIFY_OTP(payload).then((res: any) => {
                if (res.status === 'fail' || res.errorCode === 400) {
                    toast.error(res.message || res.errorMsg);
                    setLoading(false);
                } else {
                    if (res) {
                        createOrganisation(data);
                    }
                    setLoading(false);
                }
            });
        }
    }

    const createOrganisation = (data: any) => {

        if (currentPANNumberData?.pan_number) {
            toast.error("Institution already exist with this pan.number!");
            setCurrentPANNumberData({});
        } else {
            CREATE_ORGANISATION_SIGN_UP(data).then((res: any) => {
                if (res.status === 'fail' || res.errorCode === 400) {
                    toast.error(res.message || res.errorMsg);
                    setLoading(false);
                    data.name = data._user.name;
                    data.designation = data._user.designation;
                    data.mobile_number = data._user.mobile_number;
                    data.contact_number = data._user.contact_number;
                    data.whatsapp_number = data._user.whatsapp_number;
                    setCurrentStudentData(data);
                } else {
                    cronJob();
                    toast.success("Institution Created Successfully");
                    setLoading(false);
                    history.push('/organization-signup-success');
                }
            });
            setCurrentPANNumberData({});
        }
    }
    const cronJob = async () => {
        const res: any = await GET_RUN_CRON_JOB();
        if (res) {
            if (res?.status === 'fail') {

            } else {

            }
        }
    }
    const getAcademicData = () => {
        let preData = academicDetails?.filter((item) => item?.checked === true)
        if (preData?.length > 0) {
            return true;
        } else {
            return false;

        }
    }
    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        // Prevent pasting
    };
    const validForm = () => {
        if (
            !panNumber && studentsData?.name_of_organization && !instituteNameError && currentStudentData.organization_type &&
            currentStudentData?.address && !addressOne && currentStudentData?.state && currentStudentData?.district && currentStudentData?.pin_code && !pincodeNumber &&
            currentStudentData.name && !nameError && currentStudentData.email_id && !confirmEmail && currentStudentData.email_id === currentStudentData.confirm_email_id && !confirmEmail &&
            currentStudentData.mobile_number && !mobileNumber && currentStudentData.mobile_number === currentStudentData.confirm_mobile_number && !confirmMobileNumber &&
            getAcademicData() && !whatsappNumber && !alternativeMobileNumber && !confirmAlternativeMobileNumber
        ) {
            return true;
        } else {
            return false;
        }
    }
    const clearTimerInterval = () => {
        if (intervalTimer) {
            clearInterval(intervalTimer);
            intervalTimer = null;
        }
    }
    const handleStartTimer = (minutes: number, seconds: number) => {
        setIsActiveOTP(true);
        setShowTimer(false);
        let totalSeconds = minutes * 60 + seconds;
        intervalTimer = setInterval(() => {
            if (totalSeconds > 0) {
                totalSeconds--;
                let updatedMinutes = Math.floor(totalSeconds / 60);
                let updatedSeconds = totalSeconds % 60;
                if (updatedMinutes == (minutes - 2)) {
                    setIsActiveOTP(false);
                    setShowTimer(true);
                }
                setMinutes(updatedMinutes);
                setSeconds(updatedSeconds);
            } else {
                setSeconds(seconds);
                setMinutes(minutes);
                clearTimerInterval();
                setIsActiveOTP(false);
                setShowTimer(false);
            }
        }, 1000);
    }
    return (
        <div style={{ overflowY: "auto", height: "100vh" }}>
            <section className="mb-4 mt-4 px-3 px-md-0">
                <div className="bg-white border border-gray rounded-16 p-3 p-lg-5 container signup-block">
                    <div className="mb-4 d-flex align-items-center">
                        <div> <img src={Logo} alt="" className="login-logo" /></div>
                        <h2 className="login-hd mb-0 fw-light ms-3 ps-3 border-start border-dark">Institution <br />
                            Sign up </h2>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-4 mb-md-0">
                            <div className="signup-quotblock  pe-lg-5">
                                <h3 className="signup-quot fw-lighter"> Education is<br />
                                    not the learning of<br />
                                    facts but the<br />
                                    training of the<br />
                                    mind to think</h3>
                                <img src={rocketLogo} className="rocket-img" alt="" /> </div>
                        </div>
                        <div className="col-md-8 ps-lg-5">
                            <FormBuilder onUpdate={handleInput}>
                                <form>
                                    <h5 className="mb-2 fw-normal">Institution </h5>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">PAN Number <i className="required-star"></i> <small className="f12">(Institution PAN number)</small></label>
                                            <input className="form-control" type="text" placeholder="Enter PAN number" name="pan_number" maxLength={10} value={currentStudentData?.pan_number} onChange={(e) => { handlePanChange(e.target.value) }} />
                                            {panNumber && <p className="error-msg">{errorMessages.pancard}</p>}
                                            {/* {isFormSubmitted && !getInputValid('pan_number') && <p className="error-msg">Please enter pan number</p>} */}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Name of Institution <i className="required-star">*</i> <small className="f12">(as on PAN card)</small></label>
                                            <input className="form-control" type="text" placeholder="Enter name" onChange={(e) => onChangeInstituteName(e.target.value)} name="name_of_organization" value={currentStudentData?.name_of_organization} />
                                            {instituteNameError && <p className="error-msg">{instituteNameError}</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Type of Institution <i className="required-star">*</i></label>
                                            <select className="form-select form-select-lg" name="organization_type" value={currentStudentData.organization_type} onChange={(e) => { handleInstituteChange(e) }}>
                                                <option value="">Select category</option>
                                                <option value="Government">Government</option>
                                                <option value="Private">Private</option>
                                                <option value="Aided">Aided</option>
                                                <option value="Non-aided">Non-aided</option>
                                            </select>
                                            {isFormSubmitted && !getInputValid('organization_type') && <p className="error-msg">Please select Institution</p>}
                                        </div>

                                    </div>
                                    <h5 className="mb-2 fw-normal mt-3">Institution Address</h5>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Address 1 <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter address" onChange={(e) => onChangeAddressOne(e.target.value)} value={currentStudentData?.address} name="address" />
                                            {addressOne && <p className="error-msg">{addressOne}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Address 2</label>
                                            <input className="form-control" type="text" placeholder="Enter address" onChange={(e) => onChangeAddressTwo(e.target.value)} value={currentStudentData?.address_2} name="address_2" />
                                            {addressTwo && <p className="error-msg">{addressTwo}</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">State <i className="required-star">*</i></label>
                                            <select name="state" className="form-select form-select-lg" value={currentStudentData?.state} onChange={(e) => { handleStateChange(e) }}>
                                                <option value="">Select state</option>
                                                {statesData.map((name: any, i: number) => (
                                                    <option key={i} value={name.state}>
                                                        {name.state}
                                                    </option>
                                                ))}
                                            </select>
                                            {isFormSubmitted && !getInputValid('state') && <p className="error-msg">Please select state</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">District <i className="required-star">*</i></label>
                                            <select name="district" className="form-select form-select-lg" value={currentStudentData?.district} onChange={(e) => { handleDistrictChange(e) }}>
                                                <option value="">Select district</option>
                                                {districtData.map((name: any, i: number) => (
                                                    <option key={i} value={name}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </select>
                                            {isFormSubmitted && !getInputValid('district') && <p className="error-msg">Please select district</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">PIN Code <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter PIN code" name="pin_code" maxLength={6} onChange={(e) => { handlePinCodeChange(e) }} value={currentStudentData?.pin_code} />
                                            {pincodeNumber && <p className="error-msg">{errorMessages.pincode}</p>}
                                            {isFormSubmitted && !getInputValid('pin_code') && <p className="error-msg">Please enter pin code</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Landline Number with STD code</label>
                                            <input className="form-control" type="text" placeholder="Enter number" name="landline_no" maxLength={11} onChange={(e) => { handleLandlineNUmberChange(e) }} value={currentStudentData.landline_no} />
                                            {landlineNumber && <p className="error-msg">{errorMessages.landline_number}</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Alternative Landline Number</label>
                                            <input className="form-control" type="text" placeholder="Enter number" name='alt_landline_no' maxLength={11} onChange={(e) => { handleAlternativeLandlineChange(e) }} value={currentStudentData.alt_landline_no} />
                                            {alternativeLandlineNumber && <p className="error-msg">{errorMessages.landline_number}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Fax Number </label>
                                            <input className="form-control" type="text" placeholder="Enter number" name="fax_number" maxLength={10} value={currentStudentData?.fax_number} onChange={(e) => { handleFaxNumberChange(e) }} />
                                            {faxNumber && <p className="error-msg">The length of the FAX number is 10 digits, Enter a proper value</p>}
                                        </div>
                                    </div>
                                    <h5 className="mb-2 fw-normal mt-3">Choose your Academic Structure</h5>
                                    <label className="form-label mb-0 fw-light">Type of Academic Structure <i className="required-star">*</i></label>
                                    <div className="col-md-6 mb-3 my-2 form-check-inline-custom">
                                        {academicDetails.map((name: any, i: number) => (
                                            <div className="form-check form-check-inline me-0" style={{ width: name.label !== 'Professional Institute' ? "31%" : "" }} key={i}>
                                                <input className="form-check-input" type="checkbox" name="academic_info" checked={name.checked} onChange={(e) => { handleAcadamicChange(e, name, i) }} />
                                                <label className={`${(i === 9 || i === 10) ? 'form-check-label fw-light academic-checkbox' : 'form-check-label fw-light'}`}>{name.label}</label>
                                            </div>
                                        ))}
                                    </div>
                                    <hr className="my-4" />
                                    <h5 className="mb-2 fw-normal mt-3">Contact Person Details </h5>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Contact Person Name <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Enter name" name="name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentStudentData.name} />
                                            {nameError && <p className="error-msg">{nameError}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Designation</label>
                                            <input className="form-control" type="text" placeholder="Enter designation" name="designation" defaultValue={currentStudentData.designation} />
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Email ID <i className="required-star">*</i></label>
                                            <input className="form-control" type="email" placeholder="Enter email " name="email_id" value={currentStudentData.email_id} onChange={(e) => { handleEmailChange(e) }} />
                                            {email && <p className="error-msg">{errorMessages.email}</p>}
                                            {isFormSubmitted && !getInputValid('email_id') && <p className="error-msg">Please enter email id</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Confirm Email ID <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Confirm email" name="confirm_email_id" value={currentStudentData.confirm_email_id} onChange={(e) => { handleConfirmEmailChange(e) }} onPaste={handlePaste} />
                                            {confirmEmail && <p className="error-msg">{errorMessages.email}</p>}
                                            {isFormSubmitted && !getInputValid('confirm_email_id') && <p className="error-msg">Please enter confirm email id</p>}
                                            {(!confirmEmail && !isFormSubmitted && emcfError) && <p className="error-msg">{emcfError}</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Mobile Number <i className="required-star">*</i> <small className="f12">(OTP will be sent to this mobile number) </small></label>
                                            <input className="form-control" type="text" placeholder="Enter number" name="mobile_number" maxLength={10} onChange={(e) => { handleMobileChange(e) }} value={currentStudentData.mobile_number} />
                                            {mobileNumber && <p className="error-msg">{errorMessages.mobile_number}</p>}
                                            {isFormSubmitted && !getInputValid('mobile_number') && <p className="error-msg">Please enter mobile number</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Confirm Mobile Number <i className="required-star">*</i></label>
                                            <input className="form-control" type="text" placeholder="Confirm number" name="confirm_mobile_number" maxLength={10} onChange={(e) => { handleConfirmMobileChange(e) }} value={currentStudentData.confirm_mobile_number} onPaste={handlePaste} />
                                            {confirmMobileNumber && <p className="error-msg">{errorMessages.mobile_number}</p>}
                                            {isFormSubmitted && !getInputValid('confirm_mobile_number') && <p className="error-msg">Please enter confirm mobile number</p>}
                                            {(!confirmMobileNumber && !isFormSubmitted && cfMobileError) && <p className="error-msg">{cfMobileError}</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">WhatsApp Number <i className="required-star"></i></label>
                                            <input className="form-control" disabled={currentStudentData.checkedMother} type="text" name="whatsapp_number" maxLength={10} value={currentStudentData.whatsapp_number} onChange={(e) => { handleWhatsappChange(e) }} placeholder="Enter number" />
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" name="sameMobileNumber" checked={currentStudentData.checkedMother} onChange={(e) => { handleMobileNumberChange(e) }} />
                                                <label className="form-check-label fw-light f10" >Please click here to change your WhatsApp number</label>
                                            </div>
                                            {(whatsappNumber) && <p className="error-msg">{errorMessages.whatsapp_number}</p>}
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Alternative Mobile Number</label>
                                            <input className="form-control" type="text" placeholder="Enter number" name="alternative_mobile_number" maxLength={10} onChange={(e) => { handleAlternativeMobileChange(e) }} value={currentStudentData.alternative_mobile_number} />
                                            {alternativeMobileNumber && <p className="error-msg">{errorMessages.mobile_number}</p>}
                                        </div>
                                    </div>
                                    <div className="row custom-form org-custom-form">
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label mb-0 fw-light">Confirm Alternative Mobile Number</label>
                                            <input className="form-control" type="text" placeholder="Enter number" name="confirm_alternative_mobile_number" maxLength={10} onChange={(e) => { handleConfirmAlternativeMobileChange(e) }} value={currentStudentData.confirm_alternative_mobile_number} onPaste={handlePaste} />
                                            {confirmAlternativeMobileNumber && <p className="error-msg">{errorMessages.mobile_number}</p>}
                                        </div>
                                    </div>
                                    <hr className="my-4" />
                                </form>
                            </FormBuilder>
                            <h5 className="mb-2 fw-normal mt-3">Verification</h5>
                            <div className="mb-4  position-relative">
                                {!sendOtpSuccess && <button type="submit" disabled={!sendOtpSuccess} className="btn btn-primary btn-sm px-3 rounded-12 rounded-org-12" onClick={handleGenerateOTP}>Send OTP</button>}&nbsp;
                                {sendOtpSuccess && <button type="submit" className="btn btn-primary btn-sm px-3 rounded-12 rounded-org-12" onClick={handleGenerateOTP}>Send OTP</button>}&nbsp;
                                <a className={`${(checkOTPType || isActiveOTP) ? 'text-decoration-none ms-2 rounded-org-12 disabled-link text-secondary f14' : 'text-primary text-decoration-none ms-2 rounded-org-12'}`} onClick={handleResendOTP} style={{ cursor: 'pointer' }} >
                                    Resend OTP
                                </a>
                                {(isActiveOTP || showTimer) && <p className='mb-0 text-inprogess f12 position-absolute mt-1'>OTP is valid for {minutes < 10 ? `0${minutes}` : minutes} : {seconds < 10 ? `0${seconds}` : seconds}</p>}
                            </div>
                            <div className="row custom-form org-custom-form">
                                <div className="col-md-6 mb-3 mt-1">
                                    <label className="form-label mb-0 fw-light">Please Enter OTP <i className="required-star">*</i></label>
                                    <input className="form-control" type="text" placeholder="_ _ _ _" name="otp_value" value={OTPValue} onChange={(e) => { handleOTPChange(e) }} maxLength={4} />
                                </div>
                            </div>
                            <button type="submit" disabled={!verifyOTP} className="btn btn-primary btn-sm px-3 rounded-12 rounded-org-12" onClick={handleSubmit}>Verify OTP and Submit</button>
                        </div>
                    </div>
                </div>
                <div className="login-elements text-center">
                    <img src={Logos} alt="" className="login-elements-icons" />
                </div>
            </section >
            <footer className="text-center f12 text-dgray mb-3">
                <div className="container">
                    &copy; 2023-24  <a href="http://iamsamarth.com" className="text-dgray text-decoration-none">iamsamarth.com</a>. All Rights Reserved | <a href="http://iamsamarth.com/privacy-policy/" className="text-dgray text-decoration-none">Terms and Conditions</a> |
                    <a href="http://iamsamarth.com/privacy-policy/" className="text-dgray text-decoration-none ms-1">Privacy Policy</a>
                </div>
                {/* <div className="container">&copy; 2020 iamsamarth.com. All Rights Reserved | <a href="#" className="text-dgray text-decoration-none">Terms of Use</a> | <a href="#" className="text-dgray text-decoration-none">Privacy Policy</a></div> */}
            </footer>
        </div >
    )
}