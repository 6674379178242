import React, { useEffect, useState } from "react";
import { FETCH_CONTENT_ASSESSMENT_TESTS } from "../../../../../app/service/content-module/test-service";
import { toast } from "react-toastify";
import { ViewTestData } from "../preview-test";
import { GET_ABHYAS_TEST_BY_UUID } from "../../../../../app/service/anhyas-test.service";

export interface FilterData {
    board_uuid?: any;
    medium_uuid?: any;
    standard_uuid?: any;
    subject_uuid?: any;
}

interface Props {
    filterData: FilterData;
}

export const AssessmentTestView: React.FC<Props> = (props: Props) => {
    const [testList, setTestList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<any>({});
    const [showPopUop, setShowPopUp] = useState<boolean>(false);
    const [questionLoading, setQuestionLoading] = useState<boolean>(false);
    const [questionList, setQuestionList] = useState<any>([]);
    const [testNo, setTestNo] = useState<number>(1);
    useEffect(() => {
        setFilterData(props?.filterData);
        fetchData(props?.filterData);
    }, [])
    const fetchData = async (filterData: any) => {
        const payLoad = {
            board_uuid: filterData?.board_uuid,
            medium_uuid: filterData?.medium_uuid,
            standard_uuid: filterData?.standard_uuid,
            subject_uuid: filterData?.subject_uuid,
            page: 0,
            limit: 1000000
        }
        setLoading(true);
        try {
            const res: any = await FETCH_CONTENT_ASSESSMENT_TESTS(payLoad);
            if (res?.status === "fail") {
                toast.error(res?.err);
            } else if (res?.rows?.length > 0) {
                setTestList(res?.rows);
            } else {
                toast.error(res?.err);
                setLoading(false);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }
    const handleQuestions = async (testUuid: any, testNo: number) => {
        setTestNo(testNo)
        setShowPopUp(true);
        setQuestionList([]);
        setQuestionLoading(true);
        try {
            const res: any = await GET_ABHYAS_TEST_BY_UUID(testUuid);
            if (res?.status === "fail") {
                toast.error(res?.err);
            } else if (res?.rows?.length > 0) {
                let preData: any[] = JSON.parse(res?.rows[0]?.data);
                setQuestionList(preData);
            } else {
                setQuestionList([]);
                toast.error(res?.err);
            }
            setQuestionLoading(false);
        } catch (error) {
            setQuestionLoading(false);
        }
    }
    return (
        <div>
            <div
                className={`modal fade ${showPopUop ? "show" : "false"}`}
                style={{ display: showPopUop ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
            >
                <div className="modal-dialog question_preview_dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body px-5 mx-2">
                            <ViewTestData questionsList={questionList} titleName={`Assessment Test-${testNo} Preview`} questionLoading={questionLoading} />
                        </div>
                        <div className="modal-footer border-0 text-center d-block">
                            <button
                                type="button"
                                className="btn btn-primary  px-5 rounded-12  py-1"
                                data-dismiss="modal"
                                onClick={() => setShowPopUp(false)}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="border-top border-2 mt-3 question_view_height">
                    {testList?.length > 0 && testList?.map((data: any, i: number) => (
                        <div className=" my-3" key={i}>
                            <h5 className="f18 mt-4 ms-4">Set - {data?.set_no}</h5>
                            <div className="border-top border-1 mt-2">
                                {data?.test1_uuid && <h6 className="f16 mt-3 ms-4 text-primary cursor-pointer"><u onClick={() => handleQuestions(data?.test1_uuid, 1)}>Assessment Test - 1</u></h6>}
                                {data?.test2_uuid && <h6 className="f16 mt-4 ms-4 text-primary cursor-pointer"><u onClick={() => handleQuestions(data?.test2_uuid, 2)}>Assessment Test - 2</u></h6>}
                            </div>
                        </div>
                    ))}
                    {testList?.length == 0 && <p className="f16 text-center mt-4"> No data found</p>}
                </div>}
        </div>
    )
}