import React, { useEffect, useRef, useState } from "react";
import MathsReportData from "../../components/maths-reports/view-report";
import { MNSReport } from "../../components/maths-reports/mns";
import { MLAOneReport } from "../../components/maths-reports/mla-one";
import { MLATwoReport } from "../../components/maths-reports/mla-two";
import { GET_MATHS_MODULE_REPORT_BY_STUDENT } from "../../app/service/mns-service";
import { toast } from "react-toastify";

declare const window: any;

export const MathsMobileReport = () => {
    const [loading, setLoading] = useState(false);
    const [reportInfo, setReportInfo] = useState<any>({});
    const pdfRef = useRef<HTMLDivElement>(null);
    const [webviewInitialized, setWebviewInitialized] = useState<boolean>(false);

    useEffect(() => {
        const isIos = navigator.userAgent.includes('iPhone');
        if (isIos) {
            window.addEventListener("message", handleNativeMessage);
        } else {
            document.addEventListener("message", handleNativeMessage);
        }
        return () => {
            window.removeEventListener('message', handleNativeMessage);
        };

    }, []);


    useEffect(() => {
        if (!webviewInitialized) {
            setWebviewInitialized(true);
            sendMessageToApp({ action: 'init_webview', type: '', data: 'initialized' });
        }

    }, [webviewInitialized]);


    function handleNativeMessage(message: any) {
        let data = JSON.parse(message.data);
        if (data?.key && data.key === 'init') {
            localStorage.setItem("token",
                data.token);
            setTimeout(() => {
                getReports(data);
            }, 100);
        } else {

        }
    }
    const getReports = (data: any) => {
        setLoading(true);
        GET_MATHS_MODULE_REPORT_BY_STUDENT(data?.user_code).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setReportInfo(res);
                    setLoading(false);
                }

            }
        })
    }
    const sendMessageToApp = (data: any) => {
        if (window?.ReactNativeWebView) {
            try {
                window?.ReactNativeWebView.postMessage(JSON.stringify(data));
            } catch (error) {

            }
        }
    }
    return (
        <div className="scroll_div_block">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {
                !loading && <div>
                    <div ref={pdfRef} id="download-content">
                        <div className="report_border mx-lg-5 mx-2 mt-3 mb-5 px-lg-4 px-1 py-3">
                            <div className="d-flex justify-content-end "><i className="report_ias_icon " style={{ width: "80px" }}></i></div>
                            <MathsReportData reportInfo={reportInfo} />
                            <div className="mx-lg-3">
                                <MNSReport mnsReportInfo={reportInfo?.mnsDetails} />
                                <MLAOneReport mlaOneReportInfo={reportInfo?.mlaOneDetails} />
                                <MLATwoReport mlaTwoReportInfo={reportInfo?.mlaTwoDetails} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}