import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts3D from 'highcharts/highcharts-3d'; // Import Highcharts 3D module

// Initialize Highcharts 3D module
Highcharts3D(Highcharts);


interface PieChartProps {
    title: string;
    correctAnswers: number;
    wrongAnswers: number;
}

const ReportTestPieChart: React.FC<PieChartProps> = ({ title, correctAnswers, wrongAnswers }) => {
    const colors = ['#296E8C', '#E87B43']; // Define an array of colors
    // let preData = [correctAnswers, wrongAnswers]
    const options: Highcharts.Options = {
        chart: {
            type: 'pie',
            marginTop: -25,
            height: 230,
            backgroundColor: "transparent",
            options3d: {
                enabled: true,
                alpha: 45,
                beta: 0,

            },
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            events: {
                load() {
                    const chart: any = this;
                    const centerX = chart.series[0].center[0];
                    const rad = chart.series[0].center[2] / 3;

                    Highcharts.each(chart.series[0].data, function (p: any) {
                        const angle = p.angle;
                        const x = centerX + rad * Math.cos(angle);
                        p?.dataLabel?.attr({
                            x: x,
                            'text-anchor': 'right',
                        });
                    });
                },
                redraw() {
                    const chart: any = this;
                    const centerX = chart.series[0].center[0];
                    const rad = chart.series[0].center[2] / 3;

                    Highcharts.each(chart.series[0].data, function (p: any) {
                        const angle = p.angle;
                        const x = centerX + rad * Math.cos(angle);
                        p?.dataLabel?.attr({
                            x: x,
                            'text-anchor': 'right',
                        });
                    });
                },
            },

        },
        title: {
            text: title,
            align: 'center',
            y: 19,
            style: {
                color: 'black',
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        plotOptions: {
            series: {
                events: {
                    legendItemClick: function (e) {
                        e.preventDefault();
                    }
                },
                borderWidth: 1,
                borderColor: 'white',
            },
            pie: {
                depth: 30,
                size: '75%',
                dataLabels: {
                    enabled: true,
                    distance: -20,
                    style: {
                        fontWeight: 'normal',
                        fontSize: '16px',
                    },
                    formatter: function () {
                        return `<div style="background-color: white; border: 1px solid black; border-radius: 5px; padding: 5px">${this?.y?.toFixed()}</div>`;
                    },
                },
                showInLegend: true,
                point: {
                    events: {
                        legendItemClick: function () {
                            return false; // <== returning false will cancel the default action
                        }
                    }
                },
                allowPointSelect: false,
            },
        },
        legend: {
            symbolWidth: 10,
            symbolHeight: 10,
            symbolRadius: 0,
            symbolPadding: 5,
            y: 5,
            itemStyle: {
                cursor: 'default', // Change cursor to default to indicate non-interactivity
            },
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.point.name}</b>: ${this.point.y}`;
            },
        },
        series: [{
            type: 'pie',
            data: [
                { name: 'Correct Answers', y: correctAnswers, color: colors[0] }, // Assign colors dynamically
                { name: 'Wrong Answers', y: wrongAnswers, color: colors[1] }, // Assign colors dynamically
            ],

            // data: [
            //     { name: 'Correct Answers', y: correctAnswers },
            //     { name: 'Wrong Answers', y: wrongAnswers },
            // ],
        }],

        credits: {
            enabled: false,
        },
    };

    return (
        <div style={{ width: "275px", height: "275px" }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    )

};

export default ReportTestPieChart;
