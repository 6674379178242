import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface ContentInfo {
    content_validations?: {
        pending?: number;
        verified?: number;
        rephrase?: number;
        hold?: number;
        reject?: number;
    };
}

interface PieChartProps {
    contentInfo: ContentInfo;
}

const ContentPieChart: React.FC<PieChartProps> = ({ contentInfo }) => {
    const colors = ['#145F83', '#DC773C', '#1F6D2F', '#2496C1', '#9E308B'];

    const options: Highcharts.Options = {
        chart: {
            type: 'pie',
            marginTop: -35,
            height: 230,
            backgroundColor: "transparent",
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
        },
        title: {
            text: "",
            align: 'center',
            y: 1,
            style: {
                color: 'black',
                fontSize: '13px',
                fontWeight: 'normal',
            },
        },
        plotOptions: {
            pie: {
                size: '50%',
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
                point: {
                    events: {
                        legendItemClick: function () {
                            return false; // Prevent legend item click
                        },
                    },
                },
                allowPointSelect: false,
            },
        },
        legend: {
            symbolWidth: 6,
            symbolHeight: 6,
            symbolRadius: 0,
            symbolPadding: 3,
            y: -2,
            itemStyle: {
                cursor: 'default',
                fontSize: '10px',
            },
            x: 0,
            align: 'center',
            verticalAlign: 'top',
            layout: 'horizontal',
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.point.name}</b>: ${this.point.y}`;
            },
        },
        series: [
            {
                type: 'pie',
                data: [
                    {
                        name: 'Pending',
                        y: contentInfo?.content_validations?.pending || 0,
                        color: colors[0],
                    },
                    {
                        name: 'Verified',
                        y: contentInfo?.content_validations?.verified || 0,
                        color: colors[1],
                    },
                    {
                        name: 'Re-phrase',
                        y: contentInfo?.content_validations?.rephrase || 0,
                        color: colors[2],
                    },
                    {
                        name: 'On-hold',
                        y: contentInfo?.content_validations?.hold || 0,
                        color: colors[3],
                    },
                    {
                        name: 'Reject',
                        y: contentInfo?.content_validations?.reject || 0,
                        color: colors[4],
                    },
                ],
            },
        ],
        credits: {
            enabled: false,
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ContentPieChart;
